import Collapsible from '@oracle-cx-commerce/react-components/collapsible';
import React, {useContext, useState, useEffect, useCallback} from 'react';
import {ProductContext, StoreContext} from '@oracle-cx-commerce/react-ui/contexts';
import {getData} from '../quick-view-button/utils';
import Styled from '@oracle-cx-commerce/react-components/styled';
import {isMobile} from '@oracle-cx-commerce/commerce-utils/selector';
import {useSelector} from '@oracle-cx-commerce/react-components/provider';
import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';
import {Box, Text, Center} from '../../../../../../ltd-ui';
import css from './styles.css';

const SPLIT_IMAGE_SIZE_GUIDE = '_sc.';
export default function LTDProductLongDescription(props) {
  const {product} = useContext(ProductContext);
  const {action} = useContext(StoreContext);
  const {id, longDescription, ltd_specs, mediumImageURLs = [], ltd_gardenZone = ''} = product;

  const [currentProduct, setCurrentProduct] = useState({});

  useEffect(() => {
    if (!longDescription && id) {
      getData(action, id, response => {
        if (response) {
          const {getProductResponse = {}} = response;
          const {delta = {}} = getProductResponse;
          const {catalogRepository = {}} = delta;
          const {products} = catalogRepository;

          setCurrentProduct(products[id] || {});
        }
      });
    }
  }, [id, setCurrentProduct]);

  const formattedGardenZonePath = ltd_gardenZone ? `/ccstore/v1/images/?source=/file/general/${ltd_gardenZone}` : '';

  const {
    actionDescriptionViewProductInfo,
    actionDescriptionViewProductInfoSize,
    actionDescriptionViewProductInfoSpecs,
    actionDescriptionViewProductInfoZone,
    labelCollapse,
    labelExpand
  } = props;

  const [textSize, setTextSize] = useState('14px');
  const [expandedCollapsibles, setExpandedCollapsibles] = useState([true, false, false, false]);
  const [content, setContent] = useState([]);

  const handleCollapsible = index => {
    const expandeds = expandedCollapsibles.map((item, position) => {
      if (index === position) {
        return !item;
      }

      return item;
    });

    setExpandedCollapsibles(expandeds);
  };

  const getImageSizeGuide = useCallback(() => {
    if (mediumImageURLs.length) {
      const url = mediumImageURLs.find(url => url.split(SPLIT_IMAGE_SIZE_GUIDE).length === 2);

      if (url) {
        return url;
      }

      return null;
    }
  }, [mediumImageURLs]);

  const handleImages = useCallback(() => {
    let url = null;
    if (mediumImageURLs.length && content.length) {
      url = getImageSizeGuide();
    }

    const contentArray = [
      {
        title: actionDescriptionViewProductInfo,
        contentDescription: longDescription ? longDescription : currentProduct?.longDescription || '',
        isImage: false
      },
      {
        title: actionDescriptionViewProductInfoSpecs,
        contentDescription: ltd_specs,
        isImage: false
      },
      {
        title: actionDescriptionViewProductInfoSize,
        contentDescription: url,
        isImage: true
      },
      {
        title: actionDescriptionViewProductInfoZone,
        contentDescription: formattedGardenZonePath,
        isImage: true
      }
    ];

    setContent(contentArray);
  }, [
    actionDescriptionViewProductInfo,
    actionDescriptionViewProductInfoSize,
    actionDescriptionViewProductInfoSpecs,
    actionDescriptionViewProductInfoZone,
    formattedGardenZonePath,
    getImageSizeGuide,
    longDescription,
    id,
    currentProduct,
    ltd_gardenZone,
    ltd_specs,
    mediumImageURLs.length
  ]);

  useEffect(() => {
    if (!isEmptyObject(product) && (longDescription || !isEmptyObject(currentProduct) ) && !content.length) {
      handleImages();
    }
  }, [content.length, handleImages, product, currentProduct]);

  const mobile = useSelector(isMobile);

  const renderImage = item => {
    if (mobile) return <img width={300} alt={item.title} src={item.contentDescription} />;

    return <img width={500} alt={item.title} src={item.contentDescription} />;
  };

  return (
    <Styled id="LTDProductLongDescription" css={css}>
      <Box className="ltd-ui-box LTDProductLongDescription_TextWrapper">
        <Text className="LTDProductLongDescription__Text LTDProductLongDescription__Text--12" onClick={() => setTextSize('12px')}>
          A
        </Text>
        <Text className="LTDProductLongDescription__Text LTDProductLongDescription__Text--14" onClick={() => setTextSize('14px')}>
          A
        </Text>
        <Text className="LTDProductLongDescription__Text LTDProductLongDescription__Text--16" onClick={() => setTextSize('16px')}>
          A
        </Text>
      </Box>
      <div className="LTDProductLongDescription">
        {content.map((item, position) => (
          <div key={item.title}>
            {item.contentDescription && (
              <Collapsible
                handleCollapsible={() => handleCollapsible(position)}
                expanded={expandedCollapsibles[position]}
                title={item.title}
                labelCollapse={labelCollapse}
                labelExpand={labelExpand}
              >
                {item.isImage ? (
                  <Center className="ltd-ui-center">{renderImage(item)}</Center>
                ) : (
                  <div>
                    <Box
                      style={{fontSize: textSize}}
                      className="ltd-ui-box LTDProductLongDescription__Html"
                      dangerouslySetInnerHTML={{__html: item.contentDescription}}
                    ></Box>
                  </div>
                )}
              </Collapsible>
            )}
          </div>
        ))}
      </div>
    </Styled>
  );
}
