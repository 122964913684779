import React from 'react';

const Text = ({
  children,
  as,
  className,
  ...props
}) => {
  switch (as) {
    case 'h1':
      return (
        <h1 className={`ltd-ui-text ${className ? className : ''}`} {...props}>
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2 className={`ltd-ui-text ${className ? className : ''}`} {...props}>
          {children}
        </h2>
      );
    case 'h3':
      return (
        <h3 className={`ltd-ui-text ${className ? className : ''}`} {...props}>
          {children}
        </h3>
      );
    case 'label':
      return (
        <label className={`ltd-ui-text ${className ? className : ''}`} {...props}>
          {children}
        </label>
      );
    case 'span':
      return (
        <span className={`ltd-ui-text ${className ? className : ''}`} {...props}>
          {children}
        </span>
      );
    case 'p':
      return (
        <p className={`ltd-ui-text ${className ? className : ''}`} {...props}>
          {children}
        </p>
      );
    default:
      return (
        <p className={`ltd-ui-text ${className ? className : ''}`} {...props}>
          {children}
        </p>
      );
  }
};

export { Text };