import {useEffect} from 'react';
import {getCurrentProduct, getCurrentProductId} from '@oracle-cx-commerce/commerce-utils/selector';
import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';
import {importScript} from '@oracle-cx-commerce/utils/browser';
import {fetchCurrentProduct} from './fetcher';

function isScriptLoaded(src) {
  return Boolean(document.querySelector(`script[src="${src}"]`));
}

export const useCurrentProductFetcher = store => {
  const productId = getCurrentProductId(store.getState());

  useEffect(() => {
    if (isEmptyObject(getCurrentProduct(store.getState()))) {
      fetchCurrentProduct(store, productId);
    }
  }, [store, productId]);
};

export const useImportScript = src => {
  return useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!isScriptLoaded(src)) {
        importScript(src);
      }
    }
  }, [src]);
};
