import React, {useCallback, useEffect, useState, useContext} from 'react';
import {StoreContext, ContainerContext} from '@oracle-cx-commerce/react-ui/contexts';
import Modal from '@oracle-cx-commerce/react-components/modal';
import {isMobile, getSku} from '@oracle-cx-commerce/commerce-utils/selector';
import Styled from '@oracle-cx-commerce/react-components/styled';
import {noop, isEmptyObject} from '@oracle-cx-commerce/utils/generic';
import {StickyContainer} from '../../../../../../ltd-ui';
import {getImageDisplay} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/utils/functions/validation-img';
import ProductImagePanel from '../product-image-panel';
import css from './styles.css';

import {useComponentData} from './selectors';

const LTDProductImageViewer = props => {
  const {
    primaryImageTitle,
    activeImages,
    listingVariantValues,
    thumbImages,
    variantToSkuLookup,
    selectedSkuImage,
    ltd_skuVideo,
    ltd_productVideo,
    childSKUsDetails
  } = useComponentData();
  const mobile = isMobile(useContext(StoreContext).getState());
  const {getState} = useContext(StoreContext);
  const {imagePersonalizationQV, setImagePersonalizationQV} = useContext(ContainerContext);

  const {closeLinkAltText, cssOverride, urlImageVideo} = props; // modal props
  const [portalRendered, setPortalRendered] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndexModal, setCurrentIndexModal] = useState(0);
  const [media] = useState(urlImageVideo);
  const [portalOpenedOnce, setPortalOpenedOnce] = useState(false);
  const [formattedThumbnailsImages, setFormattedThumbnailsImages] = useState({});
  const [isVideoProductSelected, setVideoProductSelected] = useState(false);
  const [isVideoSKUSelected, setVideoSKUSelected] = useState(false);
  const [isShowVideoModal, setShowVideoModal] = useState(false);
  const [viewportSize, setViewportSize] = useState(null);

  portalRendered && !portalOpenedOnce && setPortalOpenedOnce(true);

  const skuImageVideo = ltd_skuVideo && ltd_skuVideo !== '' ? `${urlImageVideo}-skuImageVideo` : '';
  const productImageVideo = ltd_productVideo && ltd_productVideo !== '' ? `${urlImageVideo}-productImageVideo` : '';
  const closePortal = useCallback(() => {
    setPortalRendered(false);
  }, []);

  const filterNoImage = (images, ignoreLengthValidation) => {
    const noImage = '/img/no-image.jpg';
    const filteredImages = images.filter(image => image !== noImage);

    return filteredImages.length || ignoreLengthValidation ? filteredImages : [noImage];
  };

  /*
   * Manipulate thumbnails data
   */
  const filterInStockSKU = useCallback(() => {
    const variantsInStock = [];

    for (let variant in variantToSkuLookup) {
      const sku = variantToSkuLookup[variant];
      const fullSku = getSku(getState(), {skuId: sku});
      const isInStock = fullSku.ltd_ltdStockStatus !== '11';

      if (isInStock) {
        variant = variant.replace(';', '');
        variantsInStock.push(variant);
      }
    }

    return variantsInStock;
  }, [getState, variantToSkuLookup]);

  const addImageVideo = (images, urlVideo) => {
    const result = [];
    let coutPosition = 0;
    if (ltd_productVideo && ltd_productVideo !== '' && ltd_skuVideo && ltd_skuVideo !== '') {
      for (const image of images) {
        coutPosition = coutPosition + 1;
        if (coutPosition === 1) {
          result.push(image, `${urlVideo}-productImageVideo`, `${urlVideo}-skuImageVideo`);
        } else {
          result.push(image);
        }
      }

      return result;
    }

    if (ltd_productVideo && ltd_productVideo !== '' && (!ltd_skuVideo || ltd_skuVideo === '')) {
      for (const image of images) {
        coutPosition = coutPosition + 1;
        if (coutPosition === 1) {
          result.push(image, `${urlVideo}-productImageVideo`);
        } else {
          result.push(image);
        }
      }

      return result;
    }

    if ((!ltd_productVideo || ltd_productVideo === '') && ltd_skuVideo && ltd_skuVideo !== '') {
      for (const image of images) {
        coutPosition = coutPosition + 1;
        if (coutPosition === 1) {
          result.push(image, `${urlVideo}-skuImageVideo`);
        } else {
          result.push(image);
        }
      }

      return result;
    }

    return images;
  };

  const getThumbnailsSKUsLinks = useCallback(
    object => {
      let result = [...thumbImages];
      if (object && Object.keys(object).length) {
        for (const img of Object.keys(object)) {
          const {thumbnailUrl = []} = object[img];
          const images = thumbnailUrl.filter(url => url.split(SPLIT_IMAGE_SIZE_GUIDE).length === 1);
          result.push(images);
        }
      }
      if (result.length) {
        result = result.flat();
        result = [...Array.from(new Set(result))];
      }

      return filterNoImage(result);
    },
    [thumbImages]
  );

  const filterInStockSKUImages = useCallback(() => {
    const variantsInStock = filterInStockSKU();
    const listingVariantValuesInStock = {};

    for (const listingVariant in listingVariantValues) {
      if (variantsInStock?.includes(listingVariant)) {
        listingVariantValuesInStock[listingVariant] = listingVariantValues[listingVariant];
      }
    }

    setFormattedThumbnailsImages(listingVariantValuesInStock);
  }, [filterInStockSKU, listingVariantValues]);

  useEffect(() => {
    if (!isEmptyObject(listingVariantValues) && isEmptyObject(formattedThumbnailsImages)) {
      filterInStockSKUImages();
    }
  }, [filterInStockSKUImages, formattedThumbnailsImages, listingVariantValues]);

  const getMediumImageSKUsLinks = object => {
    let result = [...activeImages];
    if (!isEmptyObject(object)) {
      if (object && Object.keys(object).length) {
        for (const img of Object.keys(object)) {
          const {mediumImageURLs = []} = object[img];
          const images = mediumImageURLs.filter(url => getImageDisplay(url));

          result.push(images);
        }
      }
      if (result.length) {
        result = result.flat();
        result = [...Array.from(new Set(result))];
      }
    }

    return filterNoImage(result);
  };

  const detectViewportChange = useCallback(() => {
    const viewport = window?.innerWidth;
    setViewportSize(viewport);
  }, []);

  useEffect(() => {
    window?.addEventListener("resize", detectViewportChange);
    detectViewportChange();

    return () => {
      window?.removeEventListener("resize", detectViewportChange);
    }
    
  }, []);

  useEffect(() => {
    const thumbs = getThumbnailsSKUsLinks(formattedThumbnailsImages);
    if (!selectedSkuImage) {
      setCurrentIndex(0);
    } else {
      const skuImageIndex = thumbs.findIndex(thumb => thumb === selectedSkuImage);
      setCurrentIndex(skuImageIndex >= 0 ? skuImageIndex : 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSkuImage]);

  const getUniqueImageList = images => {
    let result = [...images];
    if (result.length) {
      result = result.flat();
      result = [...Array.from(new Set(result))];
    }

    return result;
  };

  const resultThumbImages = addImageVideo(getUniqueImageList(thumbImages), urlImageVideo);
  const resultActiveImages = addImageVideo(getUniqueImageList(activeImages), urlImageVideo);

  useEffect(() => {
    if (!selectedSkuImage) {
      setCurrentIndex(0);
    } else {
      const skuImageIndex = resultThumbImages.findIndex(thumb => thumb === selectedSkuImage);
      setCurrentIndex(skuImageIndex >= 0 ? skuImageIndex : 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSkuImage, ltd_skuVideo]);

  useEffect(() => {
    if (portalRendered) {
      setShowVideoModal(true);
    } else {
      setShowVideoModal(false);
      if (!isVideoProductSelected && !isVideoSKUSelected) {
        setCurrentIndexModal(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalRendered]);

  const imagePersonalizationIndex = resultActiveImages?.findIndex(image => image?.includes('_pm'));

  const videoData = {
    isVideoProductSelected,
    setVideoProductSelected,
    isVideoSKUSelected,
    setVideoSKUSelected,
    urlImageVideo,
    media,
    ltd_productVideo,
    ltd_skuVideo,
    isShowVideoModal,
    skuImageVideo,
    productImageVideo
  };

  return (
    <Styled id="ProductImageViewer" css={css}>
      <StickyContainer className="ProductImageViewer__StickyContainer">
        <div className="ProductImageViewer">
          <ProductImagePanel
            primaryImageTitle={primaryImageTitle}
            images={resultActiveImages}
            thumbs={resultThumbImages}
            portalRendered={portalRendered}
            setPortalRenderedCallback={setPortalRendered}
            showExpand={true}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            imagePersonalizationIndex={imagePersonalizationQV ? imagePersonalizationIndex : -1}
            setImagePersonalizationQV={setImagePersonalizationQV}
            mobile={mobile}
            childSKUsDetails={childSKUsDetails}
            viewportSize={viewportSize}
            {...props}
            {...videoData}
          />
          <Modal
            cssOverride={cssOverride}
            className="ProductImageViewer__Modal"
            show={portalRendered}
            onClose={closePortal}
            closeIconTitle={closeLinkAltText}
            closeArialLabel={closeLinkAltText}
          >
            {portalOpenedOnce && (
              <ProductImagePanel
                primaryImageTitle={primaryImageTitle}
                images={resultActiveImages}
                thumbs={resultThumbImages}
                portalRendered={portalRendered}
                setPortalRenderedCallback={noop}
                showExpand={false}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                mobile={mobile}
                imagePersonalizationIndex={imagePersonalizationQV ? imagePersonalizationIndex : -1}
                setImagePersonalizationQV={setImagePersonalizationQV}
                viewportSize={viewportSize}
                {...props}
                {...videoData}
                childSKUsDetails={childSKUsDetails}
              />
            )}
          </Modal>
        </div>
      </StickyContainer>
    </Styled>
  );
};

export default LTDProductImageViewer;
