import React, {useContext, useEffect, useCallback, useState} from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';

import {useNumberFormatter} from '@oracle-cx-commerce/react-components/utils/hooks';
import {StoreContext} from '@oracle-cx-commerce/react-ui/contexts';
import {useComponentData} from './selectors';
import css from './styles.css';
import {t} from '@oracle-cx-commerce/utils/generic';

const OPTIONS = {style: 'currency'};

const LTDProductPrice = props => {
  const {listPriceOnSaleBeforeText, salePriceBeforeText} = props;

  const {
    ltd_unitOfMeasure,
    skuId,
    productId,
    skuListPrice,
    skuSalePrice,
    productListPrice,
    productSalePrice,
    originalPriceRange,
    salePriceRange,
    isPriceLoaded
  } = useComponentData();

  const formatCurrency = useNumberFormatter(OPTIONS);
  const {action} = useContext(StoreContext);

  const [currentProductId, setCurrentProductId] = useState(null);
  const [currentSkuId, setCurrentSkuId] = useState(null);

  const handleGetPricesSku = useCallback(() => {
    setCurrentSkuId(skuId);
    action('getSkusPrices', {skuIds: [skuId]}).catch(() => {});
  }, [action, skuId]);

  const handleGetPricesProduct = useCallback(() => {
    setCurrentProductId(productId);
    action('getProductsPrices', {productIds: [productId]}).catch(() => {});
  }, [action, productId]);

  useEffect(() => {
    if (productId && currentProductId !== productId) {
      handleGetPricesProduct();
    }
  }, [currentProductId, handleGetPricesProduct, productId]);

  useEffect(() => {
    if (skuId && currentSkuId !== skuId) {
      handleGetPricesSku();
    }
  }, [currentSkuId, handleGetPricesSku, skuId]);

  /**
   * Formats price and displays alternate text if null price
   * @param  {Number} price Price
   * @param  {String} altPriceMsg Alternate text when price is unavailable
   * @return {String} formattedPrice Formatted price based on locale
   */
  const formatPrice = ({price, ltd_unitOfMeasure}) => {
    return (
      typeof price === 'number' && (
        <>
          {formatCurrency(price)}
          <span className="QVLTDProductPrice__Price--PriceTag">{ltd_unitOfMeasure}</span>
        </>
      )
    );
  };

  const formatPriceWithUnitOfMeasure = price => {
    return formatPrice({
      price,
      ltd_unitOfMeasure
    });
  };

  const getPercentage = (discountValue, totalValue) => {
    const percentage = Math.round((100 * discountValue) / totalValue);
    const resultPercentage = 100 - percentage;

    return resultPercentage;
  };

  /**
   * List price
   */
  const listPrice = () => {
    return skuId ? skuListPrice : productListPrice;
  };

  /**
   * Sale price
   */
  const salePrice = () => {
    return skuId ? skuSalePrice : productSalePrice;
  };

  const DiscountPercentage = () => {
    return <p className="LTDProductPrice__DiscountPercentage">SAVE {getPercentage(salePrice(), listPrice())}%</p>;
  };

  return (
    <Styled id="QVLTDProductPrice" css={css}>
      {!isPriceLoaded ? (
        ''
      ) : (
        <div className="QVLTDProductPrice">
          {!skuId && originalPriceRange && originalPriceRange !== '$∞ - -$∞' && !salePriceRange && (
            <div>
              <span className="QVLTDProductPrice__Price--Sale">{originalPriceRange}</span>
            </div>
          )}
          {!skuId && !originalPriceRange && !salePriceRange && (
            <div>
              <span className="QVLTDProductPrice__Price--Sale">{formatPriceWithUnitOfMeasure(listPrice())}</span>
            </div>
          )}
          {!skuId &&
            originalPriceRange &&
            originalPriceRange !== '$∞ - -$∞' &&
            salePriceRange &&
            salePriceRange !== '$∞ - -$∞' && (
              <div>
                <span className="QVLTDProductPrice__QV-ListPrice">
                  {listPriceOnSaleBeforeText && <span className="ltd-price-prefix">{t(listPriceOnSaleBeforeText)}</span>}
                  {originalPriceRange}
                </span>
                <span className="QVLTDProductPrice__Price--Sale">
                  {salePriceBeforeText && <span className="ltd-price-prefix">{t(salePriceBeforeText)}</span>}
                  {salePriceRange}
                </span>
              </div>
            )}
          {skuId && salePrice() && (
            <div>
              <span className="QVLTDProductPrice__QV-ListPrice">
                {listPriceOnSaleBeforeText && <span className="ltd-price-prefix">{t(listPriceOnSaleBeforeText)}</span>}
                {formatPriceWithUnitOfMeasure(listPrice())}
              </span>
              <span className="QVLTDProductPrice__Price--Sale">
                {salePriceBeforeText && <span className="ltd-price-prefix">{t(salePriceBeforeText)}</span>}
                {formatPriceWithUnitOfMeasure(salePrice())}
              </span>
              <DiscountPercentage />
            </div>
          )}
          {skuId && !salePrice() && (
            <div>
              <span className="QVLTDProductPrice__Price--Sale">{formatPriceWithUnitOfMeasure(listPrice())}</span>
            </div>
          )}
        </div>
      )}
    </Styled>
  );
};

export default LTDProductPrice;
