export const HEADER_PREFIX = 'header-';

export const FOOTER_PREFIX = 'footer-';

export const NAVBAR_PREFIX = 'topnav-';
export const NAVBAR_PREFIX_MORE = 'topnav-MORE-';
export const NAVBAR_ID = 'topnav';

export const TOP_NAV = 'top--nav-';
export const CATEGORY_FILTER = 'js-sr__cat--id';
export const PRICE_FILTER = 'js-sr__pri--id';
export const RATING_FILTER = 'js-sr__rev--id';
export const COLOR_FILTER = 'js-sr__col--id';
export const SIZE_FILTER = 'js-sr__siz--id';
export const MORE_FILTERS_FILTER = 'js-sr__mws--id';
export const SORT_RESULTS = 'js-sr-sort__pnl--id';
export const DEPARTMENT_FILTER = 'js-sr__dep--id';
export const STOCK_STATUS_FILTER = 'js-sr__sto--id';
export const SHOW_ALL_CLICKED = 'js-sortrefine--id';

export const LEFTNAV_PREFIX = 'leftnav-';
export const LEFTNAV_ID = 'leftnav';

export const PRODUCT_RECOMMENDATIONS_PREFIX = 'productrecommendations-';

export const PRODUCT_LISTING_PREFIX = 'productlisting-';
