import React from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';

import {Text, Icon, Center} from '../../../../ltd-ui';

import css from './styles.css';

function LTDHeaderIcon(props) {
  const {headerText, iconName, showBorder = true} = props;

  return (
    <Styled id="LTDHeaderIcon" css={css}>
      <div className="LTDHeaderIcon container">
        <Center className={`ltd-ui-center ${showBorder ? 'LTDHeaderIcon__Center border' : 'LTDHeaderIcon__Center'}`}>
          <Icon className={`${iconName} LTDHeaderIcon__Icon`}></Icon>
          <Text className="LTDHeaderIcon__Text ttSerif2">
            {headerText}
          </Text>
        </Center>
      </div>
    </Styled>
  );
}

LTDHeaderIcon.propTypes = {};

LTDHeaderIcon.defaultProps = {};

export default LTDHeaderIcon;
