export const validateFields = ({invalidEmailErrorText = 'Invalid email, please check out'}) => {
  return {
    email: {
      required: {
        value: true,
        message: invalidEmailErrorText
      },
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: invalidEmailErrorText
      }
    }
  };
};
