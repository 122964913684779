import React, {useState, useId} from 'react';
import {Center, Box, Select} from '../../../../../../ltd-ui';
import {isEmptyObject, noop} from '@oracle-cx-commerce/utils/generic';
import InputMask from 'react-input-mask';

const InputTypes = props => {
  // Ordenates by lineNumber
  const {values, input, disable, onBlur = noop} = props;
  const inputTypes = !isEmptyObject(input) ? input.sort((a, b) => a.lineNumber - b.lineNumber) : [];
  const [showError, setShowError] = useState([]);
  const uniqueId = `-acessibility-${useId()}`;

  // inputs handlers
  const handleFocus = e => {
    const errors = [];
    inputTypes.map((inputType, index) => {
      errors[index] = false;
    });
    setShowError(errors);
    if (e.target.value) {
      e.target.classList.add('filled');
    }
  };

  const handleBlur = e => {
    const errors = [];
    inputTypes.map((inputType, index) => {
      if (inputType.error === 'error') {
        errors[index] = true;
      } else {
        errors[index] = false;
      }
    });

    setShowError(errors);

    if (!e.target.value && !e.target.placeholder) {
      e.target.classList.remove('filled');
    }
  };

  const handleMask = inputType => {
    switch (inputType.dateFormatId) {
      case 'MM-DD-YYYY':
        return '99-99-9999';
      case 'YYYY':
        return '9999';
      case 'YYYY-YYYY':
        return '9999-9999';
      case 'MM/DD/YYYY':
        return '99/99/9999';
      case 'MM.DD.YYYY':
        return '99.99.9999';
      case 'MM-DD-YY':
        return '99-99-99';
      case 'MM/DD/YY':
        return '99/99/99';
      case 'MM.DD.YY':
        return '99.99.99';
      default:
    }
  };

  return inputTypes.map((inputType, index) =>
    inputType.fieldTypeId === 'Lookup' ? (
      <div key={inputType.lineNumber} className="InputTypes">
        <Box className="ltd-ui-box select-wrapper">
          <Select
            id={uniqueId}
            triggerOption={value => onBlur(value, index, inputType.lineRequired)}
            label={`* ${inputType.lineHeader}`}
            placeholder="Select"
            data={inputType.lookupItems}
            defaultValue={values[index]}
            disabled={disable}
            name={inputType.lineHeader}
          />
          <span className={`validationMessage validationMessage-${inputType.lineNumber}`}></span>
        </Box>
      </div>
    ) : (
      <div key={inputType.lineNumber} className="InputTypes">
        <Center className="ltd-ui-center form-group InputTypes__Select">
          <InputMask
            onBlur={event => {
              onBlur(event.target.value, index, inputType.lineRequired);
              handleBlur(event);
            }}
            onFocus={e => {
              handleFocus(e);
            }}
            id={inputType.lineHeader}
            className={`${values[index] || inputType.fieldTypeId === 'Date' ? 'form-control filled' : 'form-control'} 
                      ${showError[index] && values[index] !== '' ? 'error' : ''} 
                      ${disable ? 'inputType--disabled' : ''}`}
            maxLength={inputType.fieldTypeId === 'Date' ? undefined : inputType.lineLength}
            mask={handleMask(inputType)}
            maskChar={null}
            placeholder={inputType.dateFormatId}
            disabled={disable}
            name={inputType.lineHeader}
          />
          <label htmlFor={inputType.lineHeader} className={`form-label ${disable ? 'inputType--disabled' : ''}`}>
            {inputType.lineRequired && '*'} {inputType.lineHeader}
          </label>
          <span className={`validationMessage validationMessage-${inputType.lineNumber}`}></span>
        </Center>
      </div>
    )
  );
};

export default InputTypes;
