import React, { useEffect, useRef, useState } from "react";

const Textarea = ({
  children,
  validation,
  label,
  id,
  type,
  maxLength,
  defaultValue,
  disabled,
  ...props
}) => {
  const [characterCount, setCharacterCount] = useState(0);

  const inputRef = useRef(null);

  const handleFocus = (e) => {
    inputRef.current.classList.add("filled");

    if (characterCount > maxLength) {
      inputRef.current.classList.add("invalid");
    } else {
      inputRef.current.classList.remove("invalid");
    }
  };

  const handleBlur = (e) => {
    if (!inputRef.current.value) {
      inputRef.current.classList.remove("filled");
    }

    if (characterCount >= maxLength) {
      inputRef.current.classList.add("invalid");
    } else {
      inputRef.current.classList.remove("invalid");
    }
  };

  useEffect(() => {
    if (inputRef.current.value) {
      inputRef.current.classList.add("filled");
    }
    setCharacterCount(inputRef.current.value.length);
  }, []);

  const handleOnKeyUpCapture = (e) => {
    setCharacterCount(e.target.value.length);

    if (characterCount >= maxLength) {
      inputRef.current.classList.add("invalid");
    } else {
      inputRef.current.classList.remove("invalid");
    }
  };

  return (
    <div className="ltd-ui-textarea" hidden={type === "hidden" ? true : false}>
      <textarea
        {...props}
        ref={inputRef}
        onBlur={handleBlur}
        onFocus={handleFocus}
        id={`input-${id}`}
        aria-label={label}
        type={type}
        maxLength={maxLength}
        defaultValue={defaultValue}
        disabled={disabled}
        onKeyUpCapture={(e) => handleOnKeyUpCapture(e)}
      />
      {maxLength ? (
        <>
          <label
            hidden={type === "hidden" ? true : false}
            htmlFor={`input-${id}`}
          >
            {label} - up to <>{maxLength - characterCount}</> characters
          </label>
          {!disabled ? (
            <div className="ltd-ui-counter">
              {characterCount}/{maxLength}
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          <label
            hidden={type === "hidden" ? true : false}
            htmlFor={`input-${id}`}
          >
            {label}
          </label>
        </>
      )}
      <div hidden={type === "hidden" ? true : false}>
        {validation}
      </div>
    </div>
  );
};

export { Textarea };
