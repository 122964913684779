import React, {useContext, useCallback} from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';
import GenericLink from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/common/generic-link';
import {LEFTNAV_PREFIX} from '@oracle-cx-commerce/ltd-store/src/plugins/constants/analytics';
import {normalizeText} from '@oracle-cx-commerce/ltd-store/src/plugins/utils/analytics';
import {MenuMobileContext} from '@oracle-cx-commerce/react-widgets/common/menu-mobile/context';

import css from './styles.css';

const LinksListCustom = props => {
  const {LinksTitle, LinkWithIcons, displayAndURL = '[]', linkBehavior = '_self'} = props;

  const links = JSON.parse(displayAndURL.replace(/'/g, '"'));

  const {toggleMenu} = useContext(MenuMobileContext);

  const onNavigateToCategory = useCallback(() => {
    toggleMenu(false);
  }, [toggleMenu]);

  return (
    <Styled id="LinksListCustom" css={css}>
      <div className="LinksListCustom__Container">
        <span className="LinksListCustom__Title">{LinksTitle}</span>

        <ul className="LinksListCustom__LinkList">
          {links.map(item => (
            <li
              key={item['0']}
              className="LinksListCustom__LinkItem"
              id={`${LEFTNAV_PREFIX}${normalizeText(props[item['0']] || item['0'])}`}
            >
              {!LinkWithIcons && <GenericLink href={item['1']} onNavigate={onNavigateToCategory} target={linkBehavior} title={props[item['0']] || item['0']}>{props[item['0']] || item['0']}</GenericLink>}
              {LinkWithIcons && (
                <GenericLink href={item['1']} onNavigate={onNavigateToCategory} target={linkBehavior} title={props[item['0']] || item['0']}>
                  <span className={props[item['0']] || item['0']}></span>
                </GenericLink>
              )}
            </li>
          ))}
        </ul>
      </div>
    </Styled>
  );
};

export default LinksListCustom;
