import React from "react";

import { Spinner } from '../../index'

const Button = ({ isLoading = false, children, ...props }) => {
  return (
    <button {...props}>
      {isLoading ? <Spinner className="spinner" /> : children}
    </button>
  );
};

export { Button };
