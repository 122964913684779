/* eslint-disable max-lines */
/* eslint-disable camelcase */
import React, {useEffect, useState, useContext, useCallback} from 'react';
import {StoreContext, OrderContext} from '@oracle-cx-commerce/react-ui/contexts';
import {connect} from '@oracle-cx-commerce/react-components/provider';
import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';
import {
  getProduct,
  getSku,
  getCategories,
  getCurrentOrder,
  getProfileShippingAddresses,
  getContactInfos,
  getDefaultShippingAddressId,
  getPage,
  getSiteSettings,
  getSite,
  getDefaultPriceListGroup
} from '@oracle-cx-commerce/commerce-utils/selector';

import {sanitizeDataToDataLayer} from '@oracle-cx-commerce/ltd-store/src/plugins/utils/analytics';
import {getCommonDatalayerProperties, validateDatalayerPayload, getPreviousPageName, sha256Encode} from '../utag/utils';
import {getDatalayerPayload} from '../utag';
import {fetchMenuCategories, fetchSkus} from './fetcher';
import {useMenuCategoriesFetcher, useGetSkusFetcher} from './hook';

import {PAYMENT_TYPE_NET90, PAYMENT_TYPE_NET30, PAYMENT_TYPE_PLC} from '../../commerce-utils/constants/payments';

export const fetchers = [fetchMenuCategories, fetchSkus];

export const toFixed = (number, n) => {
  const og = 10 ** n;

  return Math.floor(number * og) / og;
};

export const checkEmailAddressSteps = (customerType, pageType) => {
  if (customerType === 'Guest') {
    if (['checkout-payment', 'checkout-review-order', 'checkout-order-confirmation']?.includes(pageType)) {
      return true;
    }
  }

  return false;
};

export const handleEmailAddress = (isLoggedIn, utagData, pageType, currentOrder, currentProfile) => {
  if (isLoggedIn) {
    utagData = {
      ...utagData,
      email_address: currentProfile.email || ''
    };
  } else {
    const isToGetEmailAddress = checkEmailAddressSteps('Guest', pageType);

    const {shippingGroups = {}} = currentOrder;
    const [currentShippingData] = Object.values(shippingGroups) || [];
    const {shippingAddress = {}} = currentShippingData || {};

    utagData = {
      ...utagData,
      ...(isToGetEmailAddress && {email_address: shippingAddress?.email})
    };
  }

  return utagData;
};

export const getGTMCheckoutDetails = (state, currentOrder = {}, event = '', extraData) => {
  const {
    commerceItems = {},
    priceInfo = {},
    discountInfo = {},
    shippingGroups = {},
    dynamicProperties = [],
    paymentGroups = {}
  } = currentOrder;
  let loadedSkus = true;

  const site = getSite(state);
  const {currency: {currencyCode = 'USD'} = {}} = getDefaultPriceListGroup(state);

  if (isEmptyObject(commerceItems)) {
    return {};
  }

  const items = [];
  let index = 0;

  const discountCouponCode = [];
  Object.keys(discountInfo.claimedCouponMultiPromotions).forEach(coupon => {
    discountCouponCode.push(coupon);
  });

  const coupons = discountCouponCode.join(',');

  for (const item in commerceItems) {
    const {catRefId, productId, displayName, quantity, brand = ''} = commerceItems[item];
    const skuDetails = getSku(state, {skuId: catRefId});
    const productDetails = getProduct(state, {productId});

    const {listPrice, salePrice} = skuDetails;

    const allCat = getCategories(state);

    if (isEmptyObject(skuDetails) || isEmptyObject(allCat)) {
      loadedSkus = false;
      break;
    }
    const categories =
      productDetails && productDetails?.parentCategoryIdPath ? productDetails?.parentCategoryIdPath.split('>') : [];
    const prodCat = categories[1] || '';
    const prodCatName = prodCat && allCat[prodCat] ? allCat[prodCat]?.displayName : '';
    const subCatId = categories[2] || '';
    const subSubCat = categories[3] || '';
    const subName = subCatId && allCat[subCatId] ? allCat[subCatId]?.displayName : '';
    const subSubName = subSubCat && allCat[subSubCat] ? allCat[subSubCat]?.displayName : '';

    const discount = salePrice > 0 ? listPrice - salePrice : 0;

    let discountShare = 0;
    let orderDiscount = 0;
    commerceItems[item].detailedItemPriceInfo.forEach(prod => {
      orderDiscount += prod.orderDiscountShare;
      discountShare += commerceItems[item].discountAmount * -1;
    });

    if (discountShare && discountShare > 0 && commerceItems && commerceItems[item]?.quantity) {
      discountShare /= commerceItems[item].quantity;
    }

    if (orderDiscount && orderDiscount > 0) {
      discountShare += (orderDiscount/commerceItems[item].quantity);
    }

    const selectedPickerValues = `${skuDetails?.ltd_pickerOneValue ? skuDetails?.ltd_pickerOneValue : ''} ${
      skuDetails?.ltd_pickerTwoValue ? skuDetails?.ltd_pickerTwoValue : ''
    }`.trim();
    const productDisplayNameWithVariant = displayName.includes(selectedPickerValues)
      ? displayName
      : `${displayName} ${selectedPickerValues}`.trim();

    const productItem = {
      item_id: skuDetails.ltd_articleNumber ? skuDetails.ltd_articleNumber.toString() : '',
      item_name: productDisplayNameWithVariant || '',
      affiliation: site?.name || '',
      discount: parseFloat((Math.abs(discount) + discountShare).toFixed(2)),
      index,
      item_brand: brand,
      item_category: prodCatName || '',
      item_category2: subName || '',
      item_category3: subSubName || '',
      item_variant: skuDetails?.x_skuConcat || '',
      price: parseFloat(listPrice.toFixed(2)),
      quantity: parseInt(quantity),
      item_list_id: subSubCat || subCatId || prodCat || '',
      item_list_name: subSubName || subName || prodCatName || '',
      coupon: coupons
    };

    items.push(productItem);
    index++;
  }

  const checkoutGTMEccomerceData = {
    event,
    ecommerce: {
      currency: currencyCode,
      value: parseFloat((priceInfo?.amount || priceInfo?.subTotal).toFixed(2)),
      coupon: coupons,
      items
    }
  };

  if (event === 'add_shipping_info') {
    let shippingType = 'Standard';
    if (shippingGroups && Object.keys(shippingGroups).length > 0) {
      shippingType = shippingGroups[Object.keys(shippingGroups)[0]]?.shippingMethod?.shippingMethodDescription;
    }

    checkoutGTMEccomerceData.ecommerce.shipping_tier = shippingType;
  } else if (event === 'add_payment_info') {
    let paymentType = 'card';
    if (extraData && Array.isArray(extraData) && extraData.length > 0) {
      const paymentData = extraData[0];
      try {
        paymentType = paymentData?.type && paymentData?.type;
        if (paymentType === 'generic') {
          paymentType = paymentData?.customProperties?.paymentType;
          if (paymentType === 'IMS') {
            const imsExtraData = paymentData?.customProperties ? paymentData?.customProperties?.imsCustomerType : null;
            switch (imsExtraData) {
              case '9':
                paymentType += `_${PAYMENT_TYPE_NET90}`;
                break;
              case '3':
                paymentType += `_${PAYMENT_TYPE_NET30}`;
                break;
              case '':
                paymentType += `_${PAYMENT_TYPE_PLC}`;
                break;
              default:
                break;
            }
          }
        }
      } catch (err) {}
    } else if (paymentGroups && Object.keys(paymentGroups).length > 0) {
      const paymentGroupType = paymentGroups[Object.keys(paymentGroups)[0]];
      if (paymentGroupType?.paymentMethod === 'creditCard') {
        paymentType = 'card';
      } else if (paymentGroupType?.paymentMethod === 'onlinePaymentGroup' || paymentGroupType?.type === 'generic') {
        const customPaymentData = dynamicProperties?.find(item => item.id === 'ltd_paymentData');
        try {
          const parsedLtdPaymentData = JSON.parse(customPaymentData.value);
          paymentType = parsedLtdPaymentData?.paymentType
            ? parsedLtdPaymentData?.paymentType
            : parsedLtdPaymentData?.customProperties?.paymentType
            ? parsedLtdPaymentData?.customProperties?.paymentType
            : 'IMS';
          if (paymentType === 'IMS') {
            const imsExtraData = parsedLtdPaymentData?.imsCustomerType
              ? parsedLtdPaymentData?.imsCustomerType
              : parsedLtdPaymentData?.customProperties?.imsCustomerType
              ? parsedLtdPaymentData?.customProperties?.imsCustomerType
              : null;
            switch (imsExtraData) {
              case '9':
                paymentType += `_${PAYMENT_TYPE_NET90}`;
                break;
              case '3':
                paymentType += `_${PAYMENT_TYPE_NET30}`;
                break;
              case '':
                paymentType += `_${PAYMENT_TYPE_PLC}`;
                break;
              default:
                break;
            }
          }
        } catch (err) {}
      }
    }

    checkoutGTMEccomerceData.ecommerce.payment_type = paymentType;
  }

  return loadedSkus ? checkoutGTMEccomerceData : {};
};

export const handleTealiumCheckout = async store => {
  const state = store.getState();
  const currentOrder = getCurrentOrder(state);
  const allCat = getCategories(state);

  const productCategory = [],
    productCategoryId = [],
    productCategoryIdStr = [],
    productChildSku = [],
    productChildSkuId = [],
    productParentSku = [],
    productSubSubCategory = [],
    productSubSubCategoryId = [],
    productSubCategory = [],
    productSubCategoryId = [],
    productIds = [],
    productName = [],
    productPrices = [],
    productQtys = [],
    skuArticleId = [],
    skuCodes = [],
    skuAvailability = [],
    skuAttribute = [];

  const products = currentOrder?.commerceItems && Object.values(currentOrder.commerceItems);

  products.forEach(product => {
    const {
      productId = '',
      catRefId: skuId = '',
      quantity = '',
      listPrice = '',
      salePrice = '',
      displayName = ''
    } = product;

    const {parentCategoryIdPath, parentCategory, repositoryId} = getProduct(state, {productId});
    const {
      ltd_articleNumber = '',
      ltd_skuCode = '',
      ltd_ltdStockDescription = '',
      x_skuConcat = '',
      ltd_defaultPinCodeSku = ''
    } = getSku(state, {skuId});

    const categories = parentCategoryIdPath ? parentCategoryIdPath.split('>') : [];

    const prodCat = parentCategory ? parentCategory.repositoryId : '';
    const prodCatName = prodCat && allCat[prodCat] ? allCat[prodCat]?.displayName : '';
    const subCatId = categories[1] || '';
    const subSubCat = categories[2] || '';
    const subName = subCatId && allCat[subCatId] ? allCat[subCatId]?.displayName : '';
    const subSubName = subSubCat && allCat[subSubCat] ? allCat[subSubCat]?.displayName : '';

    productName.push(displayName);
    productPrices.push(salePrice ? salePrice : listPrice);
    productQtys.push(quantity);
    skuArticleId.push(ltd_articleNumber);
    skuCodes.push(ltd_skuCode);
    skuAvailability.push(ltd_ltdStockDescription);
    skuAttribute.push(x_skuConcat);
    productCategory.push(prodCatName);
    productCategoryIdStr.push(prodCatName);
    productCategoryId.push(prodCat);
    productSubSubCategory.push(subSubName);
    productSubSubCategoryId.push(subSubCat);
    productSubCategory.push(subName);
    productSubCategoryId.push(subCatId);
    productChildSkuId.push(skuId);
    productParentSku.push(ltd_defaultPinCodeSku);
    productChildSku.push(ltd_defaultPinCodeSku);
    productIds.push(repositoryId);
  });

  const productsObject = {
    product_id: productIds,
    product_category: productCategory,
    product_category_id: productCategoryId,
    product_category_id_str: productCategoryIdStr,
    product_child_sku: productChildSku,
    product_child_skuid: productChildSkuId,
    product_parent_sku: productParentSku,
    product_sub_subcategory: productSubSubCategory,
    product_sub_subcategory_id: productSubSubCategoryId,
    product_subcategory: productSubCategory,
    product_subcategory_id: productSubCategoryId,
    product_name: productName,
    product_price: productPrices,
    product_qty: productQtys,
    sku_article_id: skuArticleId,
    sku_code: skuCodes,
    sku_availability: skuAvailability,
    sku_attribute: skuAttribute,
    e_checkout: 't',
    tw_event: 'checkout'
  };
  const utagData = {...productsObject};

  if (window?.dataLayer) {
    const gtmStartCheckout = getGTMCheckoutDetails(state, currentOrder, 'begin_checkout');
    const sanitizedEvent = sanitizeDataToDataLayer(gtmStartCheckout);
    window.dataLayer.push({ecommerce: null});
    window.dataLayer.push({...sanitizedEvent});
  }

  if (validateDatalayerPayload(utagData) && typeof window !== 'undefined') {
    if (window?.dataLayer) {
      window.dataLayer.push({...utagData, event: 'checkout'});
    }
  }
};

export const handleGtmCheckoutSteps = async (store, step, extraData) => {
  const state = store.getState();
  const currentOrder = getCurrentOrder(state);
  step = step !== 'payment' ? 'add_shipping_info' : 'add_payment_info';

  if (window?.dataLayer) {
    const gtmStartCheckout = getGTMCheckoutDetails(state, currentOrder, step, extraData);
    const sanitizedEvent = sanitizeDataToDataLayer(gtmStartCheckout);
    window.dataLayer.push({ecommerce: null});
    window.dataLayer.push({...sanitizedEvent});
  }
};

const getCartDetails = (state, commerceItems = {}) => {
  let loadedSkus = true;

  const allCat = getCategories(state);
  const cartDetails = {
    parentSku: [],
    skuUpc: [],
    articleId: [],
    productPrice: [],
    discount: [],
    productId: [],
    productName: [],
    subCatId: [],
    subCatName: [],
    subSubCatId: [],
    subSubCatName: [],
    childSKUs: [],
    productCategory: [],
    productCategoryId: [],
    productCatId: [],
    quantity: [],
    price: [],
    name: [],
    discountShare: [],
    childSKUSDepartment: []
  };

  if (isEmptyObject(commerceItems)) {
    return {};
  }

  for (const item in commerceItems) {
    const {catRefId, productId, displayName, listPrice, salePrice} = commerceItems[item];
    const skuDetails = getSku(state, {skuId: catRefId});

    if (isEmptyObject(skuDetails) || isEmptyObject(allCat)) {
      loadedSkus = false;
      break;
    }

    const productDetails = getProduct(state, {productId});

    const categories =
      productDetails && productDetails?.parentCategoryIdPath ? productDetails?.parentCategoryIdPath.split('>') : [];
    const prodCat = productDetails && productDetails.parentCategory ? productDetails.parentCategory.repositoryId : '';
    const prodCatName = prodCat && allCat[prodCat] ? allCat[prodCat]?.displayName : '';
    const subCatId = categories[1] || '';
    const subSubCat = categories[2] || '';
    const subName = subCatId && allCat[subCatId] ? allCat[subCatId]?.displayName : '';
    const subSubName = subSubCat && allCat[subSubCat] ? allCat[subSubCat]?.displayName : '';

    const discount = salePrice > 0 ? listPrice - salePrice : 0;
    let discountShare = 0;
    let orderDiscount = 0;
    commerceItems[item].detailedItemPriceInfo.forEach(prod => {
      orderDiscount += prod.orderDiscountShare;
      discountShare += commerceItems[item].discountAmount * -1;
    });

    if (discountShare && discountShare > 0 && commerceItems && commerceItems[item]?.quantity) {
      discountShare /= commerceItems[item].quantity;
    }

    if (orderDiscount && orderDiscount > 0) {
      discountShare += orderDiscount;
    }

    cartDetails.discountShare.push(toFixed(discountShare, 2).toString());
    cartDetails.name.push(commerceItems[item]?.displayName);
    cartDetails.price.push(salePrice > 0 ? String(salePrice) : String(listPrice));
    cartDetails.quantity.push(commerceItems[item].quantity.toString());
    cartDetails.productCategory.push(prodCatName);
    cartDetails.productCategoryId.push(prodCat || '');
    cartDetails.productCatId.push(prodCat);
    cartDetails.skuUpc.push(skuDetails.ltd_upc || '');
    cartDetails.articleId.push(skuDetails.ltd_articleNumber ? skuDetails.ltd_articleNumber.toString() : '');
    cartDetails.productPrice.push(skuDetails.listPrice);
    cartDetails.discount.push(toFixed(discount, 2).toString());
    cartDetails.productName.push(displayName);
    cartDetails.productId.push(productId);
    cartDetails.parentSku.push(skuDetails.ltd_defaultPinCodeSku || '');
    cartDetails.childSKUSDepartment.push(skuDetails.ltd_defaultPinCodeSku || '');
    cartDetails.subCatId.push(subCatId);
    cartDetails.subCatName.push(subName);
    cartDetails.subSubCatId.push(subSubCat);
    cartDetails.subSubCatName.push(subSubName);
    cartDetails.childSKUs.push(productDetails && productDetails.childSKUs ? productDetails.childSKUs[0] : '');
  }

  return loadedSkus ? cartDetails : {};
};

const getPayerNumber = currentProfile => {
  if (!isEmptyObject(currentProfile)) {
    const {dynamicProperties = []} = currentProfile;
    const property = dynamicProperties.find(property => property.id === 'ltd_payerNumber');

    const {value = ''} = property || {};

    const payerNumber = value;

    return payerNumber;
  }

  return '';
};

const getCheckoutInformation = (pageType, isExpressCheckoutActive) => {
  let checkout_flow_type = 'standard-checkout';
  if (isExpressCheckoutActive) checkout_flow_type = 'express-checkout';
  let checkoutInformation = {
    checkout_flow_type
  };

  switch (pageType) {
    case 'checkout-sign-in':
      checkoutInformation = {
        ...checkoutInformation,
        e_checkout_sign_in: 't',
        checkout_step: 'checkout-sign-in'
      };
      break;
    case 'checkout-payment':
      checkoutInformation = {
        ...checkoutInformation,
        e_payment_page: 't',
        checkout_step: 'payment'
      };
      break;
    case 'checkout-review-order':
      checkoutInformation = {
        ...checkoutInformation,
        checkout_step: 'review-order',
        e_review_order_page: 't',
        e_sel_shipping_option: 'Standard' // change here to verify if Expedited when implemented on LTDCX-1069
      };
      break;
    case 'checkout-billing-shipping':
      checkoutInformation = {
        ...checkoutInformation,
        checkout_step: 'billing-shipping',
        e_billing_shipping_page: 't'
      };
      break;
    default:
  }

  return checkoutInformation;
};

const fillCheckoutData = (
  utagPayload,
  cartDetails,
  defaultAddress,
  currentProfile,
  priceInfo,
  isLoggedIn,
  paymentSel,
  isExpressCheckoutActive,
  pageType,
  currentOrder = {},
  shippingConfig = {}
) => {
  const utagDataPreFormatted = getCommonDatalayerProperties(
    utagPayload,
    cartDetails,
    defaultAddress,
    currentProfile,
    priceInfo,
    currentOrder,
    shippingConfig
  );

  const checkoutInformation = getCheckoutInformation(utagPayload.page_type, isExpressCheckoutActive);

  let utagData = {
    ...utagDataPreFormatted,
    ...utagPayload,
    ...checkoutInformation
  };

  utagData = handleEmailAddress(isLoggedIn, utagData, pageType, currentOrder, currentProfile);

  if (pageType === 'checkout-payment') {
    utagData = {
      ...utagData,
      e_email_address: utagData?.email_address || ''
    };
  }

  if (paymentSel) {
    utagData = {
      ...utagData,
      payment_sel: paymentSel
    };
  }

  return utagData;
};

const getDefaultAddress = (contactInfos, defaultShippingAddressId, shippingAddresses) => {
  let defaultAddress;
  if (contactInfos) {
    if (defaultShippingAddressId) {
      defaultAddress = contactInfos[defaultShippingAddressId];
    } else if (shippingAddresses && shippingAddresses.length > 0) {
      const firstShippingAddress = shippingAddresses[0];
      defaultAddress = contactInfos[firstShippingAddress];
    }
  }

  return defaultAddress;
};

const getPaymentSelection = orderContext => {
  let paymentSel = '';
  for (const payment in orderContext.paymentGroups) {
    if (orderContext.paymentGroups[payment].paymentMethod === 'creditCard') {
      paymentSel = orderContext.paymentGroups[payment].cardType;
    } else {
      paymentSel = orderContext.paymentGroups[payment].paymentMethod;
    }
  }

  return paymentSel;
};

const LTDDataTaggingCheckout = props => {
  const {utagPayload, currentProfile, isLoggedIn, shippingAddressEmail} = props;

  const store = useContext(StoreContext);
  const state = store.getState();
  const orderContext = useContext(OrderContext);
  const {subscribeDispatch} = store;

  useMenuCategoriesFetcher(store, props);

  const [alreadySent, setAlreadySent] = useState(false);
  const [changeLogStatus, setChangeLogStatus] = useState(false);
  const [prevLogStatus, setPrevLogStatus] = useState(isLoggedIn);

  const {pageType} = getPage(state);
  const currentOrder = getCurrentOrder(state);
  const {commerceItems, priceInfo} = currentOrder;

  useGetSkusFetcher(store, commerceItems);

  const contactInfos = getContactInfos(state);
  const defaultShippingAddressId = getDefaultShippingAddressId(state);
  const shippingAddresses = getProfileShippingAddresses(state);
  const defaultAddress = getDefaultAddress(contactInfos, defaultShippingAddressId, shippingAddresses);
  const {shippingConfig = {}} = getSiteSettings(state);

  const {dynamicProperties} = currentProfile;

  const expressCheckoutPropertyFromCurrentProfile = Object.values(dynamicProperties || {}).filter(
    profile => profile.id === 'ltd_expressCheckoutActivated'
  );

  const isExpressCheckoutActive = expressCheckoutPropertyFromCurrentProfile[0]?.value;

  const handleFillCheckoutData = useCallback(
    async (paymentSel, cartDetails = {}, isUpdateCart = false, type) => {
      const utagData = fillCheckoutData(
        utagPayload,
        cartDetails,
        defaultAddress,
        currentProfile,
        priceInfo,
        isLoggedIn,
        paymentSel,
        isExpressCheckoutActive,
        pageType,
        currentOrder,
        shippingConfig
      );

      const email = currentProfile?.email || shippingAddressEmail;
      if (email) {
        utagData.email_hashed = await sha256Encode(email);
      }

      try {
        const paymentData = currentOrder?.dynamicProperties?.find(item => item.id === 'ltd_paymentData');
        const parsedPaymentData = JSON.parse(paymentData?.value) || '{}';
        const payerNumber = parsedPaymentData?.customProperties?.payerNumber;
        if (!utagData.payer_number && payerNumber) {
          utagData.payer_number = payerNumber;
        }

        const previousPageName = getPreviousPageName(window, utagPayload.page_name);
        if (previousPageName) {
          utagData.previous_page_name = previousPageName;
        }
      }
      catch (err) {
        console.error(err);
      }

      if (typeof window !== 'undefined') {
        if (window?.dataLayer) {
          if (isUpdateCart) {
            const events = {
              increase: 'cartPlusButton',
              decrease: 'cartMinusButton'
            };

            window.dataLayer.push({...utagData, event: events[type]});
          } else {
            window.dataLayer.push({...utagData, event: 'pageView'});
          }
        }
      }

      setAlreadySent(true);
      setChangeLogStatus(false);
      setPrevLogStatus(isLoggedIn);
    },
    [
      currentOrder,
      currentProfile,
      defaultAddress,
      isExpressCheckoutActive,
      isLoggedIn,
      pageType,
      priceInfo,
      shippingConfig,
      utagPayload
    ]
  );

  useEffect(() => {
    if (isLoggedIn !== prevLogStatus) {
      setChangeLogStatus(true);
    }

    let paymentSel = '';
    if (!isEmptyObject(orderContext)) {
      paymentSel = getPaymentSelection(orderContext);
    }
    let timeoutId = '';
    if (validateDatalayerPayload(utagPayload) && (!alreadySent || changeLogStatus)) {
      const cartDetails = getCartDetails(state, commerceItems);
      if (!isEmptyObject(cartDetails)) {
        timeoutId = setTimeout(() => {
          if (Array.from(document.getElementsByName('CheckoutPayments')).length > 0) {
            const selectedPaymentMethod = Array.from(document.getElementsByName('CheckoutPayments')).find(
              item => item.checked
            );
            if (selectedPaymentMethod && !paymentSel) {
              paymentSel = selectedPaymentMethod.value;
            }
          }
          handleFillCheckoutData(paymentSel, cartDetails);
        }, 1500);
      }
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    alreadySent,
    currentProfile,
    utagPayload,
    isLoggedIn,
    prevLogStatus,
    changeLogStatus,
    state,
    commerceItems,
    priceInfo,
    orderContext,
    defaultAddress,
    handleFillCheckoutData
  ]);

  useEffect(() => {
    if(window?.sessionStorage?.getItem("gtm_purchase_sent") !== null){
      window?.sessionStorage?.removeItem("gtm_purchase_sent");
    }
  }, []);

  const getEventType = action => {
    const {type, endpointId} = action;

    if (type === 'updateCartItemComplete') {
      const {
        originalAction: {
          payload: {type: typeReason}
        }
      } = action;

      return typeReason;
    }

    return endpointId;
  };

  useEffect(() => {
    return subscribeDispatch(action => {
      const {type} = action;

      if (type === 'updateCartItemComplete') {
        let paymentSel = '';
        if (!isEmptyObject(orderContext)) {
          paymentSel = getPaymentSelection(orderContext);
        }
        const cartDetails = getCartDetails(state, commerceItems);
        if (!isEmptyObject(cartDetails)) {
          if (Array.from(document.getElementsByName('CheckoutPayments')).length) {
            const selectedPaymentMethod = Array.from(document.getElementsByName('CheckoutPayments')).find(
              item => item.checked
            );
            if (selectedPaymentMethod && !paymentSel) {
              paymentSel = selectedPaymentMethod.value;
            }
          }
          handleFillCheckoutData(paymentSel, cartDetails, true, getEventType(action));
        }
      }
    });
  }, [handleFillCheckoutData, subscribeDispatch]);

  return (
    <div id="LTDDataTaggingCheckout"></div>
  );
};

export default connect(getDatalayerPayload)(LTDDataTaggingCheckout);
