export const MAX_LIST_ITEMS_SIZE = 100;

export const chunk = array => {
    const chunkSize = 20;
    const chunkedArray = [];

    if (array?.length) {
        for (let i = 0; i < array.length; i += chunkSize) {
            chunkedArray.push(array.slice(i, i + chunkSize));
        }
    }

    return chunkedArray;
};