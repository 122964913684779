import {
  getCategories,
  getCurrentProfile,
  getSku,
  getSite,
  getDefaultPriceListGroup
} from '@oracle-cx-commerce/commerce-utils/selector';
import {sanitizeDataToDataLayer} from '@oracle-cx-commerce/ltd-store/src/plugins/utils/analytics';
import {getDatalayerPayload} from '../utag';
import {validateDatalayerPayload, b64EncodeUnicode, getGeolocation, getMobileAndTablet, sha256Encode} from '../utag/utils';

const E_CUSTOM_PAGE = 't';
const PAGE_TYPE = 'recs_quick-view';

export const handleTealiumProductRecommendation = async (store, {product}, carouselName) => {
  const state = store.getState();

  const {utagPayload = {}, isLoggedIn = false, shippingAddressEmail = ''} = getDatalayerPayload(state);

  const allCategories = getCategories(state);

  const site = getSite(state);
  const {currency: {currencyCode = 'USD'} = {}} = getDefaultPriceListGroup(state);

  const {id = ''} = product;

  const {childSKUs = [], parentCategoryIdPath = '', displayName = ''} = product;

  const {customer_type: customerType = '', page_name: name = '', page_breadcrumb: breadcrumb = ''} = utagPayload;

  const pageName = `${PAGE_TYPE}:${name}`;
  const pageNameDetailed = `${pageName}:${customerType}`;
  const pageBreadcrumb = `${displayName}:${PAGE_TYPE}:${breadcrumb}`;

  const payloadInfoPage = {
    page_type: PAGE_TYPE,
    page_category: PAGE_TYPE,
    page_channel: PAGE_TYPE,
    page_name: pageName,
    page_breadcrumb: pageBreadcrumb,
    page_name_detailed: pageNameDetailed
  };

  const payloadSpecificEvent = {
    e_product_view: E_CUSTOM_PAGE,
    e_quick_view: E_CUSTOM_PAGE
  };

  const {geoCity, geoState, country} = getGeolocation(window);
  const {isMobile, isTablet} = getMobileAndTablet(window);
  const currentProfile = getCurrentProfile(state);

  const payloadLocation = {
    geo_city: geoCity,
    geo_state: geoState,
    is_mobile: isMobile ? '1' : '0',
    is_tablet: isTablet ? '1' : '0',
    customer_country: country,
    encrypted_profile_id: b64EncodeUnicode(currentProfile.id)
  };

  const email = currentProfile?.email || shippingAddressEmail;

  if (email) {
    payloadLocation.email_token = b64EncodeUnicode(email);
    payloadLocation.email_hashed = await sha256Encode(email);
  }

  if (currentProfile?.id) {
    payloadLocation.encrypted_profile_id = b64EncodeUnicode(currentProfile.id);
  }

  const payload = {
    ...utagPayload,
    ...payloadInfoPage,
    ...payloadSpecificEvent,
    ...payloadLocation
  };

  const payloadSpecificProduct = {
    product_id: id,
    product_name: [],
    product_category: [],
    product_category_id: [],
    product_child_sku: [],
    product_child_skuid: [],
    sku_code: [],
    sku_upc: [],
    sku_article_id: [],
    sku_attribute: [],
    sku_availability: [],
    product_parent_sku: [],
    product_price: []
  };

  const categories = [];
  const categoriesId = [];
  const subCategories = [];
  const subCategoryIds = [];
  const subSubCategories = [];
  const subSubCategoryIds = [];
  const productsDiscount = [];

  let discount = 0;

  payloadSpecificProduct.product_name.push(displayName);

  if (childSKUs.length) {
    childSKUs.forEach(skuId => {
      const {
        ltd_skuId,
        ltd_skuCode,
        ltd_upc,
        ltd_articleNumber,
        ltd_ltdStockDescription,
        x_skuConcat,
        x_itemCode,
        listPrice,
        salePrice
      } = getSku(state, {skuId});

      if (x_skuConcat === 'dummy' || ltd_ltdStockDescription === 'Sold Out') {
        return;
      }

      if (listPrice && salePrice) {
        discount = (listPrice - salePrice).toFixed(2);
      }

      const skuPrice = listPrice ? listPrice : 0;

      const price = listPrice && salePrice ? salePrice : skuPrice;

      payloadSpecificProduct.product_price.push(String(price));

      payloadSpecificProduct.product_parent_sku.push(x_itemCode ? x_itemCode : '');
      payloadSpecificProduct.product_child_sku.push(x_itemCode ? x_itemCode : '');
      payloadSpecificProduct.product_child_skuid.push(ltd_skuId ? ltd_skuId : '');
      payloadSpecificProduct.sku_code.push(ltd_skuCode ? ltd_skuCode : '');
      payloadSpecificProduct.sku_upc.push(ltd_upc ? ltd_upc : '');
      payloadSpecificProduct.sku_article_id.push(ltd_articleNumber ? String(ltd_articleNumber) : '');
      payloadSpecificProduct.sku_attribute.push(x_skuConcat ? x_skuConcat : '');
      payloadSpecificProduct.sku_availability.push(ltd_ltdStockDescription ? ltd_ltdStockDescription : '');
    });
  }

  productsDiscount.push(String(discount));

  const parentCategoryIds = parentCategoryIdPath.split('>');
  const categoryId = parentCategoryIds[1] || null;
  const subCategoryId = parentCategoryIds[2] || null;
  const subSubCategoryId = parentCategoryIds[3] || null;

  if (categoryId) {
    const {displayName = ''} = allCategories[categoryId] || {};

    categories.push(displayName);
    categoriesId.push(categoryId);
  }

  if (subCategoryId) {
    const {displayName = ''} = allCategories[subCategoryId];

    subCategories.push(displayName);
    subCategoryIds.push(subCategoryId);
  }

  if (subSubCategoryId) {
    const {displayName = ''} = allCategories[subSubCategoryId];

    subSubCategories.push(displayName);
    subSubCategoryIds.push(subCategoryId);
  }

  if (categoriesId.length) {
    payloadSpecificProduct.product_category = categories;
    payloadSpecificProduct.product_category_id = categoriesId;
  }

  if (subCategoryIds.length) {
    payloadSpecificProduct.product_subcategory_id = subCategoryIds;
    payloadSpecificProduct.product_subcategory = subCategories;
  }

  if (subSubCategoryIds.length) {
    payloadSpecificProduct.product_sub_subcategory_id = subSubCategoryIds;
    payloadSpecificProduct.product_sub_subcategory = subSubCategories;
  }

  payloadSpecificProduct.product_discount = productsDiscount;

  const utagData = {
    ...payload,
    ...payloadSpecificProduct
  };

  const skuDetailsSortedByListPrice = product?.childSKUs
    ?.filter(skuId => !skuId.toLowerCase()?.includes('dummy'))
    .map(skuId => getSku(state, {skuId}))
    .sort((skuA, skuB) => {
      return skuA?.listPrice - skuB?.listPrice;
    });

  const {salePrice, listPrice} = skuDetailsSortedByListPrice[0];

  const skuDiscount = salePrice && listPrice - salePrice;
  const skuDiscounts = salePrice && skuDiscount;

  const pdpGTMEccomerceDataSelect = {
    event: 'select_item',
    ecommerce: {
      item_list_id: name,
      item_list_name: 'Product Carousel',
      currency: currencyCode,
      value: parseFloat(salePrice ? salePrice : listPrice).toFixed(2),
      items: [{
        item_id: product.repositoryId || '',
        item_name: product?.displayName || '',
        affiliation: site?.name || '',
        currency: currencyCode,
        discount: Math.abs(parseFloat(skuDiscount ? skuDiscounts.toFixed(2) : 0.0)),
        item_brand: site?.name || '',
        item_category: categories[0] || '',
        item_category2: subCategories[0] || '',
        item_category3: subSubCategories[0] || '',
        item_category4: categoriesId[0] || '',
        item_category5: '',
        item_list_id: name,
        item_list_name: 'Product Carousel',
        location_id: 'Product Carousel',
        item_carousel_title: carouselName,
        item_carousel_component: true,
        price: parseFloat(salePrice ? salePrice : listPrice).toFixed(2),
        quantity: 1,
      }]
    }
  };

  if (typeof window !== 'undefined') {
    if (window?.dataLayer) {
      const sanitizedEventSelect = sanitizeDataToDataLayer(pdpGTMEccomerceDataSelect);
      window.dataLayer.push({ecommerce: null});
      window.dataLayer.push({...sanitizedEventSelect});
    }

    if (validateDatalayerPayload(utagData)) {
      if (window?.dataLayer) {
        window.dataLayer.push({...utagData, event: 'pageView'});
      }
    }
  }
};
