
export const splideItemSelection = (setCurrentImageIndex, ltd_productVideo,
                                    ltd_skuVideo, handleOnclickVideoProduct, handleOnclickVideoSKU, handleOnClick) => {
  return (event, slide) => {
    let imageIndex = null;
    if(slide){
      imageIndex = slide?.slide?.getAttribute("image-index");
    }
    else if(event.key === 'Enter') {
      imageIndex = event?.target?.offsetParent?.getAttribute("image-index");      
    }
    if(imageIndex) {
      try{
        if (ltd_productVideo && parseInt(imageIndex) === 1) {
          handleOnclickVideoProduct && handleOnclickVideoProduct(parseInt(imageIndex));
        }
        else if (ltd_productVideo && ltd_skuVideo && parseInt(imageIndex) === 2) {
          handleOnclickVideoSKU && handleOnclickVideoSKU(parseInt(imageIndex));
        }
        else if (!ltd_productVideo && ltd_skuVideo && parseInt(imageIndex) === 1) {
          handleOnclickVideoSKU && handleOnclickVideoSKU(parseInt(imageIndex));
        }
        else {
          handleOnClick && handleOnClick(parseInt(imageIndex));
        }
      }
      catch (err){}
      setCurrentImageIndex(parseInt(imageIndex));
    }
  }
}

export const splideItemSelectionClassUpdate = (currentIndex, splideRef, selectClassName) => {
  if(typeof currentIndex === "number"){
    splideRef?.current?.splide?.Components?.Slides?.forEach(item => {
      const slideImageIndex = item?.slide?.getAttribute("image-index");
      if(slideImageIndex) {
        if(currentIndex !== parseInt(slideImageIndex)) {
          item?.slide?.classList?.remove(selectClassName)
        }
        else {
          item?.slide?.classList?.add(selectClassName);
        }
      }
    });
  }
}
