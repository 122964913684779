import React, { useCallback, useEffect, useState, forwardRef } from "react";

import { useBoolean } from "../../index";

import { phone, pinCode, date } from "./masks";

const MaterialInput = forwardRef(
  (
    {
      children,
      validation,
      label,
      id,
      type,
      defaultValue = null,
      className,
      mask,
      showPassword,
      ...props
    },
    ref
  ) => {
    const [filled, setFilled] = useState("");

    const [passwordVisible, setPasswordVisible] = useBoolean();

    const handleFocus = (e) => {
      e.target.classList.add("filled");
    };

    const handleBlur = (e) => {
      if (!e.target.value) {
        e.target.classList.remove("filled");
      }
    };

    useEffect(() => {
      if (defaultValue) {
        setFilled("filled");
      }
    }, [defaultValue]);

    const handleKeyDown = useCallback(
      (e) => {
        if (mask === "phone") {
          phone(e);
        }
        if (mask === "pinCode") {
          pinCode(e);
        }
        if (mask === "date") {
          date(e);
        }
      },
      [mask]
    );

    const handleEnter = (e) => {
      e.key === 'Enter' && setPasswordVisible.toggle()
    }

    return (
      <div
        className={`ltd-ui-material-input ${className ? className : ''}`}
        hidden={type === "hidden" ? true : false}
      >
        <input
          onBlur={handleBlur}
          onFocus={handleFocus}
          onInput={(e) => {
            handleFocus(e);
            handleKeyDown(e);
          }}
          id={`input-${id}`}
          aria-label={label}
          type={
            showPassword ? (passwordVisible ? "text" : "password") : `${type}`
          }
          ref={ref}
          defaultValue={defaultValue}
          className={`${filled}`}
          {...props}
        />
        {validation}
        <label
          className="form-label"
          hidden={type === "hidden" ? true : false}
          htmlFor={`input-${id}`}
        >
          {label}
        </label>
        {showPassword ? (
          <a
            role="button"
            onClick={setPasswordVisible.toggle}
            onKeyDown={handleEnter}
            tabIndex="0"
            className="hide-button"
          >
            {passwordVisible ? "Hide" : "Show"}
          </a>
        ) : (
          ""
        )}
      </div>
    );
  }
);

export { MaterialInput };
