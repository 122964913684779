import React from 'react';

const Anchor = ({
  children,
  ...props
}) => {
  return (
    <a {...props}>
      {children}
    </a>
  );
};

export { Anchor };