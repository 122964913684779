import React from 'react';

const AddToCartForm = ({
  handleAddToCart,
  productId,
  skuId,
  qty,
  isDisableButton,
  buttonText,
  containerClassName,
  buttonClassName
}) => {
  return (
    <div className={containerClassName}>
      <form onSubmit={handleAddToCart}>
        <input type="hidden" name="productId" value={productId ? productId : ''} />
        <input type="hidden" name="catRefId" value={skuId ? skuId : ''} />
        <input type="hidden" name="quantity" value={qty} />
        <button className={buttonClassName} data-testid="Add-To-Cart-Button" type="submit" disabled={isDisableButton}>
          {buttonText}
        </button>
      </form>
    </div>
  );
};

export default AddToCartForm;
