import React, {useCallback, useContext, useMemo} from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';
import {StoreContext} from '@oracle-cx-commerce/react-ui/contexts';
import {useForm} from 'react-hook-form';
import {displayToast} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/common/functions/display-toast';
import {Box, Flex, Text, Button, MaterialInput} from '../../../../ltd-ui';
import {getSite} from '@oracle-cx-commerce/commerce-utils/selector';
import {
  emailVerifyStatus
} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/common/functions/email-verify-status-check';
import {validateFields} from './validators';

/**
 * The custom component example.
 *
 * @param {string} props.config* Configurations properties for Design studio
 * @param {string} props.label* Resource strings from imported locales
 */

import css from './styles.css';

const NewsletterSignUp = props => {
  const {action, getState} = useContext(StoreContext);

  const site = getSite(getState());
  const {
    defaultErrorMessage = '',
    configTitleColor,
    configTextColor,
    configBgColor,
    widgetTitle,
    initText,
    boldText,
    endText,
    fieldLabel,
    fieldButton
  } = props;

  const {
    handleSubmit,
    register,
    formState: {errors},
    setValue
  } = useForm({
    mode: 'onSubmit'
  });

  const onOk = useCallback(
    message => {
      displayToast(message, 'success');
      setValue('email', '');
    },
    [setValue]
  );

  const onNotOk = useCallback(message => {
    displayToast(message, 'error');
  }, []);

  const onSubmit = async data => {
    const payload = {
      event: 'email_sign_up',
      email: data.email,
      siteId: site.id || ''
    };

    const subscribed = await action('postListrakPhoneNumber', {
      event: 'email_verify',
      email: data.email,
      siteId: site.id || ''
    })
      .then(({delta: {listrakRepository}}) => {
        const {message, status} = listrakRepository;

        const emailStatus = emailVerifyStatus(message, status);
        emailStatus && window?.dataLayer?.push({event: 'e_sa_email_existing', email});
        return emailStatus;
      })
      .catch(() => {return false});

    action('postListrakPhoneNumber', payload)
      .then(({delta: {listrakRepository}}) => {
        const {message, status} = listrakRepository;

        if (['200', '201'].includes(status)) {
          if(!subscribed){
            window?.dataLayer?.push({event: 'e_sa_email_success', email: data.email});
          }
          onOk(message);
        } else {
          onNotOk(message ? message : defaultErrorMessage);
        }
      })
      .catch(() => {});
  };

  const validators = useMemo(() => {
    return validateFields(props);
  }, [props]);

  const handleFocus = e => {
    e.target.classList.add('filled');
  };

  const handleBlur = e => {
    if (!e.target.value) {
      e.target.classList.remove('filled');
    }
  };

  return (
    <Styled id="NewsletterSignUp" css={css}>
      <Box className="ltd-ui-box" style={{backgroundColor: configBgColor}}>
        <div className="container">
          <Flex className="ltd-ui-flex NewsletterSignUp__Flex">
            <Box className="ltd-ui-box NewsletterSignUp__Wrapper">
              <Box className="ltd-ui-box NewsletterSignUp__Widget">
                <Text as="h2" className="ttSerif3" style={{color: configTitleColor}}>
                  {widgetTitle}
                </Text>
              </Box>
            </Box>

            <Box className="ltd-ui-box NewsletterSignUp__Wrapper">
              <Box className="ltd-ui-box NewsletterSignUp__Content">
                <Text className="NewsletterSignUp__Text" style={{color: configTextColor}}>
                  {initText}
                  <b>{boldText}</b>
                  {endText}
                </Text>

                <Flex className="ltd-ui-flex form-group NewsletterSignUp__FormWrapper">
                  <form className="LTDNewsLetterSignup_form" onSubmit={handleSubmit(onSubmit)}>
                    <MaterialInput
                      label={fieldLabel}
                      id="email"
                      className={`LTDNewsLetterSignup_input ${errors.email && 'LTDNewsLetterSignup_input--error'}`}
                      maxLength="50"
                      {...register('email', validators['email'])}
                      validation={
                        <span className="validationMessage">{errors.email && `${errors.email.message}`}</span>
                      }
                      type="text"
                    />

                    <Button type="submit" className="ltd-ui-button primary NewsletterSignUp__Button">
                      {fieldButton}
                    </Button>
                  </form>
                </Flex>
                <span className="LTDNewsLetterSignup_input--error">{errors.email && `${errors.email.message}`}</span>
              </Box>
            </Box>
          </Flex>
        </div>
      </Box>
    </Styled>
  );
};

export default NewsletterSignUp;
