import React from 'react';

const Icon = ({
  className,
  isExpanded,
  ...props
}) => {
  return (
    <span className={`ltd-ui-icon ${isExpanded ? 'expanded' : ''} ${className ? className : ''}`} {...props}></span>
  );
};

export { Icon };