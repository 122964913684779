import {
    getCurrentProfile,
    getSite,
    getDefaultPriceListGroup
  } from '@oracle-cx-commerce/commerce-utils/selector';
  import {sanitizeDataToDataLayer} from '@oracle-cx-commerce/ltd-store/src/plugins/utils/analytics';
  import {getDatalayerPayload} from '../utag';
  import {validateDatalayerPayload, b64EncodeUnicode, getGeolocation, getMobileAndTablet, sha256Encode} from '../utag/utils';
  import {MAX_LIST_ITEMS_SIZE, chunk} from '../../utils/functions/data-tagging';
  
  const PAGE_TYPE = 'recs';
  
  export const handleTealiumViewItemList = async (store, {viewItemlist}, carouselName) => {
    const state = store.getState();
  
    const {utagPayload = {}, isLoggedIn = false, shippingAddressEmail = ''} = getDatalayerPayload(state);
    const site = getSite(state);
    const {currency: {currencyCode = 'USD'} = {}} = getDefaultPriceListGroup(state);
  
    const {customer_type = '', page_name = '', page_breadcrumb = ''} = utagPayload;
  
    const pageNameDetailed = `${page_name}:${customer_type}`;
  
    const payloadInfoPage = {
      page_type: PAGE_TYPE,
      page_category: PAGE_TYPE,
      page_channel: PAGE_TYPE,
      page_name: page_name,
      page_breadcrumb: page_breadcrumb,
      page_name_detailed: pageNameDetailed
    };
  
    const {geoCity, geoState, country} = getGeolocation(window);
    const {isMobile, isTablet} = getMobileAndTablet(window);
    const currentProfile = getCurrentProfile(state);
  
    const payloadLocation = {
      geo_city: geoCity,
      geo_state: geoState,
      is_mobile: isMobile ? '1' : '0',
      is_tablet: isTablet ? '1' : '0',
      customer_country: country
    };

    const email = currentProfile?.email || shippingAddressEmail;

    if (email) {
      payloadLocation.email_token = b64EncodeUnicode(email);
      payloadLocation.email_hashed = await sha256Encode(email);
    }

    if (currentProfile?.id) {
      payloadLocation.encrypted_profile_id = b64EncodeUnicode(currentProfile.id);
    }
  
    const payload = {
      ...utagPayload,
      ...payloadInfoPage,
      ...payloadLocation
    };

    const utagData = {
      ...payload
    };

    //Request payload is too large, because of that we fire multiple events with no more than 20 products each
    const items = viewItemlist?.length ? viewItemlist?.slice(0, MAX_LIST_ITEMS_SIZE)?.map((item, index) => {
      const {salePrice, listPrice} = item.skuDetails;
      const {displayName, repositoryId} = item.product;

      const skuDiscount = salePrice && parseFloat(listPrice) - parseFloat(salePrice);
      const skuDiscounts = parseFloat(salePrice) && skuDiscount;

      return {
        item_id: repositoryId,
        item_name: displayName,
        affiliation: site?.name || '',
        currency: currencyCode,
        discount: Math.abs(parseFloat(skuDiscount ? skuDiscounts.toFixed(2) : 0.0)),
        index,
        item_brand: site?.name || '',
        item_list_id: page_name,
        item_list_name: 'Product Carousel',
        location_id: page_name,
        item_carousel_title: carouselName,
        item_carousel_component: true,
        price: parseFloat(salePrice ? salePrice : listPrice).toFixed(2),
        quantity: 1
      };
    }) : [];
  
    if (typeof window !== 'undefined') {
      if (window?.dataLayer) {
        window.dataLayer.push({ecommerce: null});

        const chunkedArray = chunk(items);

        chunkedArray.forEach(chunkItem => {
          if (chunkItem?.length) {
            const pdpGTMEccomerceData = {
              event: 'view_item_list',
              ecommerce: {
                item_list_id: page_name,
                item_list_name: 'Product Carousel',
                items: chunkItem
              }
            };

            const sanitizedEvent = sanitizeDataToDataLayer(pdpGTMEccomerceData);
            window.dataLayer.push({...sanitizedEvent});
          }
        });
      }
    }
  };
  