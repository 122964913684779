import React, { useEffect, useRef, useState, forwardRef } from "react";

import { useBoolean, Icon } from "../../index";

const Select = forwardRef(
  (
    {
      children,
      validation,
      label,
      id,
      type = "text",
      defaultValue = null,
      className,
      data,
      triggerOption,
      ...props
    },
    ref
  ) => {
    const [show, setShow] = useBoolean();
    const selectBoxRef = useRef();
    const selectBoxOptionsRef = useRef();
    const [currentOption, setCurrentOption] = useState(defaultValue);

    const handleSelected = () => {
      setShow.toggle();
    };

    const handleOption = (value) => {
      setCurrentOption(value);
      setShow.off();
    };

    useEffect(() => {
      const checkIfClickedOutside = (e) => {
        if (
          show &&
          selectBoxRef.current &&
          !selectBoxRef.current.contains(e.target)
        ) {
          setShow.off();
        }
      };

      document.addEventListener("mousedown", checkIfClickedOutside);

      return () => {
        document.removeEventListener("mousedown", checkIfClickedOutside);
      };
    }, [show]);

    return (
      <div
        ref={selectBoxRef}
        className={`ltd-ui-select ${className ? className : ''}`}
        hidden={type === "hidden" ? true : false}
      >
        <input
          id={`input-${id}`}
          onClick={handleSelected}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === 'ArrowDown') {
              handleSelected()
              if(selectBoxOptionsRef && selectBoxOptionsRef.current) {
                selectBoxOptionsRef.current.focus()
              }
            } 

          }}
          aria-label={!label ? "label" : label} 
          type={type}
          ref={ref}
          autoComplete="off"
          aria-autocomplete="list"
          onChange={() => {}}
          value={currentOption || ''}
          {...props}
        />

        {show ? (
          <Icon
            onClick={() => setShow.toggle()}
            className="icon-arrow-small-up form-input-attached"
          />
        ) : (
          <Icon
            onClick={() => setShow.toggle()}
            className="icon-arrow-small-down form-input-attached"
          />
        )}

        <div className="select-box">
          <ul tabIndex={show ? 0 : -1} className={`options-container ${show ? "active" : ""}`} ref={selectBoxOptionsRef}>
            {data.map((value, key) => {
              return (
                <li
                className={`option ${show ? "show" : ""}`}
                  tabIndex={0}
                  onClick={() => {
                    handleOption(value);
                    triggerOption(value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleOption(value);
                      triggerOption(value);
                    }
                  }}
                  key={key}
                >
                  <span>{value}</span>
                </li>
              );
            })}
          </ul>
        </div>
        {label && (
          <label
            className="form-label"
            hidden={type === "hidden" ? true : false}
            htmlFor={`input-${id}`}
          >
            {label}
          </label>
        )}
        {validation}
      </div>
    );
  }
);

export { Select };
