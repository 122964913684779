import React from 'react';
import Link from '@oracle-cx-commerce/react-components/link';
import {connect} from '@oracle-cx-commerce/react-components/provider';
import {getSiteSettings} from '@oracle-cx-commerce/commerce-utils/selector';

const GenericLink = ({children, enableSPA, ...props}) => {
  let {href = ''} = props;
  href = href.replace(/^\//, '');

  return enableSPA ? (
    <Link {...props} href={href}>
      {children}
    </Link>
  ) : (
    <a {...props}>{children}</a>
  );
};

const getComponentData = state => {
  const {SPAConfig: {enableSPA = false} = {}} = getSiteSettings(state);

  return {enableSPA};
};

export default connect(getComponentData)(GenericLink);
