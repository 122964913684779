import {getSurchargeValue} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/utils/shipping';
import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';

export const getTotalOrderValue = (currentOrder = {}, shippingConfig = {}) => {
  const {enableCarrierSurcharge = false, carrierSurchargeThreshold, carrierSurchargeApplicableDates} = shippingConfig;
  const {priceInfo, shippingGroups = {}} = currentOrder;
  const {shippingAddress = {}} = (!isEmptyObject(shippingGroups) && Object.values(shippingGroups)[0]) || {};

  let cartTotalValue = '0';

  if (!isEmptyObject(priceInfo)) {
    cartTotalValue = priceInfo.total;

    if (!shippingAddress.state || !shippingAddress.postalCode) {
      cartTotalValue -= priceInfo.tax;
    }

    const surchargeValue = enableCarrierSurcharge
      ? getSurchargeValue(carrierSurchargeApplicableDates, carrierSurchargeThreshold, priceInfo.subTotal)
      : '';

    if (surchargeValue) {
      cartTotalValue = cartTotalValue + Number(surchargeValue);
    }
  }

  return String(cartTotalValue);
};
