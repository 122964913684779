
export const requiredSkuFields = [
    "ltd_defaultPinCodeSku",
    "ltd_articleNumber",
    "ltd_upc",
    "ltd_skuCode",
    "ltd_ltdStockDescription",
    "x_skuConcat",
    "ltd_pickerOneValue",
    "ltd_pickerTwoValue"
];