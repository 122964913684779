import React from "react";

const Center = ({
  children,
  ...props
}) => {
  return (
    <div {...props}>
      {children}
    </div>
  );
};

export { Center };
