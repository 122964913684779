/* eslint-disable react/no-danger */
import React, {useState, useContext, useEffect, useCallback, useRef} from 'react';
import {ProductSelectionContext, StoreContext, ContainerContext} from '@oracle-cx-commerce/react-ui/contexts';
import Styled from '@oracle-cx-commerce/react-components/styled';
import {Checkbox, Anchor, Icon, Box} from '../../../../../../ltd-ui';
import {getSku} from '@oracle-cx-commerce/commerce-utils/selector';
import InputTypes from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/product/ltd-product-personalization/components/input-fields';
import css from './styles.css';

/**
 * The custom component example.
 *
 * @param {string} props.locales* Resource strings from imported locales
 */

const LTDProductPersonalization = props => {
  // Snippets
  const {title, checkboxText, howToPersonalize, textProfanityError} = props;

  // Contexts
  const {setProductPersonalization, setAddToCartInProgress, productSelection} = useContext(ProductSelectionContext);
  const store = useContext(StoreContext);
  const {action} = store;
  const {
    ltd_personalizationData: ltd_personalizationDataContext,
    setPersonalizationData: setPersonalizationDataContext,
    setImagePersonalizationQV
  } = useContext(ContainerContext);
  const {ltd_personalized} = getSku(store.getState(), {skuId: productSelection.skuId});

  const {subscribeDispatch} = useContext(StoreContext);
  const formRef = useRef(null);

  const getProductPersonalization = useCallback(
    skuId => {
      const fullSku = getSku(store.getState(), {skuId});
      const productPersonalizationPayload = fullSku.ltd_ProductPersonalization;

      return productPersonalizationPayload;
    },
    [store]
  );

  //This state for the collapsible is actually inverted, it is going to begin closed
  const [isCollapsed, setIsCollapsed] = useState('');
  const [isProfanity, setIsProfanity] = useState(false);
  const [stringPayload, setStringPayload] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [hasBeenClickedOnce, setHasBeenClickedOnce] = useState(false);
  const [personalizationPayload, setPersonalizationPayload] = useState(false);
  const [ltd_personalizationData, setPersonalizationData] = useState(ltd_personalizationDataContext);

  useEffect(() => {
    const newLTDPersonalizationData = ltd_personalizationData;
    const fullPayload = getProductPersonalization(productSelection.skuId);
    const {personalizationLine = {}} = fullPayload ? JSON.parse(fullPayload) : {};
    newLTDPersonalizationData['structure'] = personalizationLine;
    setPersonalizationData(newLTDPersonalizationData);
    setStringPayload(fullPayload);
  }, [getProductPersonalization, ltd_personalizationData, productSelection, setPersonalizationData]);

  // Transforms the string payload into the object that is going to be used below
  // Also creates the array that goes to the "add-to-cart" button
  useEffect(() => {
    if (stringPayload) {
      const payloadObject = JSON.parse(stringPayload);
      setPersonalizationPayload(payloadObject);
      const newPersonalizationValue = new Array(payloadObject.personalizationLine.length).fill('');
      setPersonalizationData(prevState => {
        return {
          ...prevState,
          values: newPersonalizationValue
        };
      });
    }
  }, [ltd_personalized, setPersonalizationData, stringPayload]);

  // Deactivates "add to cart" button only if it has not been clicked once
  useEffect(() => {
    if (personalizationPayload && !hasBeenClickedOnce && ltd_personalized === 'Yes') {
      setAddToCartInProgress(true);
      setProductPersonalization(true);
    }
  }, [hasBeenClickedOnce, ltd_personalized, personalizationPayload, setAddToCartInProgress, setProductPersonalization]);

  // Toggles the checkbox and sets a flag so it wont deactivate by itself
  const handleClick = () => {
    setHasBeenClickedOnce(true);

    setPersonalizationDataContext({...ltd_personalizationData, isProfanity, isChecked: !isChecked});
    setIsChecked(prevCheck => !prevCheck);
  };

  const validateDate = value => {
    const splitValue = value.split(' ');

    if (splitValue.length !== 3) {
      return false;
    }

    const mounth = splitValue[0];
    if (!isNaN(mounth)) {
      return false;
    }

    const day = splitValue[1];
    if (!day?.includes(',')) {
      return false;
    }
    const nday = day.substring(0, 2);
    if (nday.length !== 2 || isNaN(nday)) {
      return false;
    }
    const ndayInt = parseInt(nday, 10);
    if (ndayInt > 31) {
      return false;
    }

    const year = splitValue[2];
    if (year.length !== 4 || isNaN(year)) {
      return false;
    }

    return true;
  };

  const validatePersonalization = useCallback(
    async personalizationArray => {
      setIsProfanity(false);

      if (!personalizationPayload) return false;

      let invalidInput = personalizationPayload.personalizationLine.find((input, index) => {
        if (input.lineRequired) {
          if (!personalizationArray[index]) return true;
        }

        return false;
      });

      let index = 0;
      for (const input of personalizationPayload.personalizationLine) {
        const span = document.querySelector(`.validationMessage-${input.lineNumber}`);
        const element = document.querySelector(`input[name="${input.lineHeader}"]`);

        if (personalizationArray[index]) {
          const res = await action('getProfanity', personalizationArray[index]).catch(() => {});

          if (res?.ok && res?.delta?.CISessionRepository?.found !== '0') {
            element?.classList?.add('error');
            if (span) span.innerHTML = textProfanityError;

            setIsProfanity(true);
          } else {
            element?.classList?.remove('error');
            if (span) span.innerHTML = '';
          }
        } else {
          element?.classList?.remove('error');
          if (span) span.innerHTML = '';
        }

        if (input.lineRequired) {
          if (input.dateFormatId === 'Month DD, YYYY') {
            if (!validateDate(personalizationArray[index])) {
              element?.classList?.add('error');
              invalidInput = true;
            } else {
              element?.classList?.remove('error');
            }
          }
        }

        index += 1;
      }

      if (!isChecked) return false;

      return !invalidInput;
    },
    [personalizationPayload, isChecked, action, textProfanityError]
  );

  useEffect(() => {
    const validate = async () => {
      const isValid = await validatePersonalization(ltd_personalizationData?.values);
      setAddToCartInProgress(!isValid);
    };

    if (personalizationPayload && ltd_personalized === 'Yes') {
      validate();
    }
  }, [
    isChecked,
    ltd_personalizationData,
    setAddToCartInProgress,
    validatePersonalization,
    personalizationPayload,
    ltd_personalized
  ]);

  const handleBlur = async (value, index) => {
    const {values: personalizationArray = []} = ltd_personalizationData;

    if (personalizationArray[index] !== value) {
      personalizationArray[index] = value;
      setPersonalizationData({...ltd_personalizationData, values: personalizationArray});
    }

    setPersonalizationDataContext({...ltd_personalizationData, isProfanity, isChecked});
  };

  const handleKeyDown = useCallback(e => {
    e.key === 'Enter' && e.target.click();
  }, []);

  const handleReset = useCallback(() => {
    setPersonalizationDataContext({});
    setIsProfanity(false);
    setIsChecked(false);

    if (formRef?.current) {
      formRef.current?.reset();
    }
  }, [setPersonalizationDataContext]);

  useEffect(() => {
    if (personalizationPayload && productSelection.skuId) {
      handleReset();
    }
  }, [handleReset, personalizationPayload, productSelection.skuId]);

  useEffect(() => {
    return subscribeDispatch(action => {
      const {type} = action;

      if (type === 'addItemsToCartComplete') {
        handleReset();
      }
    });
  }, [subscribeDispatch, handleReset]);

  return (
    <Styled id="LTDProductPersonalization" css={css}>
      {personalizationPayload && ltd_personalized === 'Yes' && (
        <div className="LTDProductPersonalization__Wrapper">
          <div className="ModalButton">
            <Box className="ltd-ui-box">
              <Anchor
                onKeyDown={e => handleKeyDown(e)}
                onClick={() => {
                  setImagePersonalizationQV(true);
                }}
                tabIndex={0}
                className="ltd-ui-anchor LTDProductPersonalization__Anchor"
              >
                <Icon className="icon-question LTDProductPersonalization__Icon"></Icon>
                {howToPersonalize}
              </Anchor>
            </Box>
          </div>
          <div className="LTDProductPersonalization">
            <div className="LTDProductPersonalization__DropdownContainer">
              <div className="LTDProductPersonalization__DropdownHeader">
                <div
                  className="LTDProductPersonalization__DropdownText"
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  aria-hidden="true"
                >
                  <Anchor className="ltd-ui-anchor LTDProductPersonalization__DropdownAnchor">
                    {title}
                  </Anchor>
                </div>
                <div
                  className="LTDProductPersonalization__DropdownIcon"
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  aria-hidden="true"
                >
                  <Icon className={`icon-less ${isCollapsed ? '' : 'open'} LTDProductPersonalization__DropdownIconIcon`}></Icon>
                </div>
              </div>
              <div className={`collapse-content ${isCollapsed ? 'collapsed' : ''}`} aria-expanded={!isCollapsed}>
                {/* Content to be shown in the Collapsible*/}
                <form ref={formRef}>
                  <div>
                    {personalizationPayload.personalizationReturnStatement && (
                      <div
                        dangerouslySetInnerHTML={{__html: personalizationPayload.personalizationReturnStatement}}
                        aria-hidden="true"
                      />
                    )}
                    {personalizationPayload.personalizationLine && (
                      <div className="InputTypes__Wrapper">
                        <InputTypes
                          input={personalizationPayload.personalizationLine}
                          values={ltd_personalizationData.values}
                          onBlur={handleBlur}
                        />
                      </div>
                    )}
                    {personalizationPayload.personalizationStatement && (
                      <div dangerouslySetInnerHTML={{__html: personalizationPayload.personalizationStatement}} />
                    )}
                  </div>
                  <div className="LTDProductPersonalization__Checkbox">
                    <Checkbox className="LTDProductPersonalization__CheckboxCustom" onClick={handleClick}>
                      {checkboxText}
                    </Checkbox>
                  </div>
                </form>
                {/* End of content to be shown in the Collapsible*/}
              </div>
            </div>
          </div>
        </div>
      )}
    </Styled>
  );
};

export default LTDProductPersonalization;
