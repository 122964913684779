import Img from '@oracle-cx-commerce/react-components/img';
import Link from '@oracle-cx-commerce/react-components/link';
import PropTypes from 'prop-types';
import React from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';
import {noop} from '@oracle-cx-commerce/utils/generic';
import css from './styles.css';

const Image = props => {
  // props
  const {
    media = {},
    mediaLink = '',
    mediaLinkBehavior = '',
    mediaScreenText = '',
    mediaScreenTextPosition = '',
    mediaTitle = '',
    mediaAlt = '',
    errorSrc = null,
    onErrorCallback = noop,
    xsmall,
    small,
    medium,
    large
  } = props;

  const mediaSrc = media.src;

  let textStyle = {
    order: 2
  };
  let imgLinkCSS = 'Image_Link ';
  let imgCSS = 'Image_Img ';

  switch (mediaScreenTextPosition) {
    case 'below':
      textStyle = {
        order: 2
      };
      imgLinkCSS += 'Image_ColumnDirection';
      break;

    case 'above':
      textStyle = {
        order: -1
      };
      imgLinkCSS += 'Image_ColumnDirection';
      break;

    case 'right':
      textStyle = {
        order: 2
      };
      imgLinkCSS += 'Image_RowDirection';
      break;

    case 'left':
      textStyle = {
        order: -1
      };
      imgLinkCSS += 'Image_RowDirection';
      break;

    // skip default
  }

  if (mediaScreenText) {
    imgCSS += 'Image_WithCaption';
  }

  const renderImage = () => {
    return (
      <>
        <div className={`Image_ImgContainer ${imgCSS}`}>
          <Img
            errorSrc={errorSrc}
            src={mediaSrc}
            title={mediaTitle}
            alt={mediaAlt}
            onErrorCallback={onErrorCallback}
            xsmall={xsmall}
            small={small}
            medium={medium}
            large={large}
          />
        </div>
        {mediaScreenText ? (
          <figcaption style={textStyle} className="Image_FigCaption">
            {mediaScreenText}
          </figcaption>
        ) : null}
      </>
    );
  };

  const ImageLink = () => {
    let isLink = false;
    let linkProps = {
      className: imgLinkCSS
    };

    if (mediaLink) {
      linkProps = {...linkProps, href: mediaLink};
      isLink = true;
    }

    if (mediaLinkBehavior === '_self') {
      return isLink ? (
        <Link title="Image Link" tabIndex={0} role="button" {...linkProps}>
          {renderImage()}
        </Link>
      ) : (
        <span tabIndex={-1} {...linkProps}>
          {renderImage()}
        </span>
      );
    }
    linkProps = {...linkProps, target: mediaLinkBehavior};

    return isLink ? (
      <a title="Image Link" tabIndex={0} role="button" {...linkProps}>
        {renderImage()}
      </a>
    ) : (
      <span tabIndex={-1} {...linkProps}>
        {renderImage()}
      </span>
    );
  };

  return (
    <Styled id="Image" css={css}>
      <figure className="Image">
        <ImageLink />
      </figure>
    </Styled>
  );
};

Image.propTypes = {
  media: PropTypes.shape({src: PropTypes.string}).isRequired,
  mediaLink: PropTypes.string,
  mediaLinkBehavior: PropTypes.string,
  mediaScreenText: PropTypes.string,
  mediaScreenTextPosition: PropTypes.string,
  mediaTitle: PropTypes.string,
  mediaAlt: PropTypes.string.isRequired,
  errorSrc: PropTypes.string
};

Image.defaultProps = {
  mediaLink: '',
  mediaLinkBehavior: '',
  mediaScreenText: '',
  mediaScreenTextPosition: '',
  mediaTitle: '',
  errorSrc: null
};

export default Image;
