import React, {useEffect, useState, useCallback, useContext, Fragment} from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';
import {StoreContext} from '@oracle-cx-commerce/react-ui/contexts';
import css from './style.css';

const ProductBadge = props => {
  const {type = ''} = props;

  const {getState} = useContext(StoreContext);
  const state = getState();

  const [badge, setBadge] = useState(null);

  const getStringWithoutSpecialChar = string => {
    const formattedString = string.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');

    return formattedString;
  };

  const getBadgeProps = useCallback(type => {
    const typeWithoutSpecialChar = getStringWithoutSpecialChar(type);
    let stringToClass = typeWithoutSpecialChar.replace(' ', '-');
    stringToClass = stringToClass.toLowerCase();

    return {text: type, class: stringToClass, typeWithoutSpecialChar};
  }, []);

  const getTextWithStyle = text => {
    const lowerCaseText = text.toLowerCase();
    if (lowerCaseText === 'wow! pricing') {
      return (
        <>
          <b>WOW!</b> PRICING
        </>
      );
    }

    return text;
  };

  useEffect(() => {
    if (type) {
      setBadge(getBadgeProps(type));
    }
  }, [getBadgeProps, type, state]);

  return badge ? (
    <Styled id="ProductBadge" css={css}>
      {badge && (
        <div className={`ProductBadge ProductBadge--default ProductBadge--${badge?.class}`}>
          <p className="ProductBadge__text">{getTextWithStyle(badge?.text)}</p>
        </div>
      )}
    </Styled>
  ) : <Fragment/>;
};

export default ProductBadge;
