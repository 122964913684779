/* eslint-disable react/no-danger */
import React, {useContext, useState, useEffect} from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';

import {ProductContext, ContainerContext} from '@oracle-cx-commerce/react-ui/contexts';

import {useSelector, connect} from '@oracle-cx-commerce/react-components/provider';
import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';

import {getPage} from '@oracle-cx-commerce/commerce-utils/selector';

import {getComponentData} from './selector';
import {
  checkFilterProperties,
  checkProductExclusion,
  verifyCategory,
  verifyDateTime,
  handleParentCategories
} from './utils';

import css from './styles.css';

const findSkuDummy = product => {
  return product?.childSKUs?.find(sku => sku.toLowerCase().includes('dummy'));
};

const PromoBadgeRules = props => {
  const {categoryId, alternativeMessage, products, skus, promoEligibilityBadge, productId, isQV} = props;

  const {record = {}} = useContext(ProductContext);
  const {contextId} = useSelector(getPage);

  let {selections: {skuId = null} = {}} = useContext(ContainerContext) || {};

  const {
    ASOTVCheck = false,
    saleCheck = false,
    personalizedCheck = false,
    onlineOnlyCheck = false,
    newArrivalCheck = false,
    exclusiveCheck = false,
    fullMessageText = '',
    includedExcludedCategories = '',
    productExclusions = '',
    includeCategories = false
  } = promoEligibilityBadge;

  const categories = includedExcludedCategories?.split(',');
  const excludedProducts = productExclusions?.split(',');

  const currentProduct = products?.[record?.attributes?.['product.repositoryId']?.[0] ?? productId ?? contextId] || {};

  if (!skuId && isEmptyObject(record)) {
    skuId = findSkuDummy(currentProduct);
  }

  const currentSku = skus?.[skuId ?? record?.attributes?.['sku.repositoryId']?.[0]] || {};

  const {
    repositoryId = '',
    ltd_isOnlineOnly,
    ltd_newArrival,
    parentCategories = [],
    parentCategoryIdPath
  } = currentProduct;

  const parentCategoriesIds = handleParentCategories(
    parentCategories,
    parentCategoryIdPath,
    categoryId,
    includeCategories
  );
  const [currentParentCategories, setCurrentParentCategories] = useState(parentCategoriesIds);

  const categoriesIds = currentParentCategories?.length > 0 ? currentParentCategories : parentCategoriesIds;

  useEffect(() => {
    if (!currentParentCategories?.length && parentCategoriesIds?.length) {
      setCurrentParentCategories(parentCategoriesIds);
    }
  }, [parentCategoriesIds, currentParentCategories]);

  const filterProps = {
    ASOTVCheck,
    saleCheck,
    personalizedCheck,
    onlineOnlyCheck,
    newArrivalCheck,
    exclusiveCheck
  };
  const skuProps = {...currentSku, ltd_isOnlineOnly, ltd_newArrival};

  const validateRules = () => {
    const isOKCategory = verifyCategory(categoriesIds, categories, categoryId, includeCategories);
    const isOKFilterProps = checkFilterProperties(skuProps, filterProps);
    const isToNotExclude = checkProductExclusion(excludedProducts, repositoryId);

    if (isOKCategory && isOKFilterProps && isToNotExclude) {
      return true;
    }

    return false;
  };

  const displayMessage = validateRules();

  if (!displayMessage) {
    return <></>;
  }

  return (
    <Styled id="LTDPromoBadge" css={css}>
      <div className={`LTDPromoBadge ${isQV ? 'mb-2' : ''}`}>
        <span
          className="LTDPromoBadge__span"
          dangerouslySetInnerHTML={{__html: fullMessageText || alternativeMessage}}
        ></span>
      </div>
    </Styled>
  );
};

function LTDPromoBadge(props) {
  const {
    promoEligibilityBadge: {enablePromoBadge = false, startDateTime, endDateTime}
  } = props;

  const isOKDateTime = verifyDateTime(startDateTime, endDateTime);

  if (!enablePromoBadge || !isOKDateTime) {
    return <></>;
  }

  return <PromoBadgeRules {...props} />;
}

export default connect(getComponentData)(LTDPromoBadge);
