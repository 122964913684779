import React, {useContext} from 'react';
import Link from '@oracle-cx-commerce/react-components/link';
import Styled from '@oracle-cx-commerce/react-components/styled';
import {noop} from '@oracle-cx-commerce/utils/generic';
import {PAGE_CUSTOMER_SERVICES_LINK} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/commerce-utils/constants/page-links';
import {MenuMobileContext} from '@oracle-cx-commerce/react-widgets/common/menu-mobile/context';

import {Icon, Center, Text} from '../../../../../ltd-ui';

import css from './styles.css';

function LTDHeaderHelpButton(props) {
  const {helpButtonText} = props;

  const {setMenuOpen = noop} = useContext(MenuMobileContext) || {};

  const handleLeftNavClose = () => {
    setMenuOpen(false);
  };

  return (
    <Styled id="LTDHeaderHelpButton" css={css}>
      <Link href={PAGE_CUSTOMER_SERVICES_LINK} onClick={handleLeftNavClose}>
        <Center className="ltd-ui-center LTDHeaderHelpButton">
          <Icon className="icon-question LTDHeaderHelpButton__Icon"></Icon>
          <Text className="LTDHeaderHelpButton__Text">
            {helpButtonText}
          </Text>
        </Center>
      </Link>
    </Styled>
  );
}

export default LTDHeaderHelpButton;
