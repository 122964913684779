export const getSkuNameByImage = (image, skus = [], imageProperty) => {
  if (skus.length > 1) {
    const result = skus.find(sku => sku[imageProperty]?.includes(image));

    return result ? ` - ${result.displayName}` : '';
  }

  return '';
};

export const getAltTitle = (skuDetails = {}, product = {}) => {
  const {x_skuConcat, displayName: skuDisplayName = ''} = skuDetails;
  const {ltd_isSingleSku, displayName: productDisplayName = ''} = product;

  const isDummy = x_skuConcat === 'dummy';
  const isSingleSKU = ltd_isSingleSku === 'Yes';

  return !isDummy && !isSingleSKU ? `${productDisplayName} - ${skuDisplayName}` : productDisplayName;
};
