import React from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';
import {NAVBAR_PREFIX} from '@oracle-cx-commerce/ltd-store/src/plugins/constants/analytics';
import {normalizeText} from '@oracle-cx-commerce/ltd-store/src/plugins/utils/analytics';
import Region from '@oracle-cx-commerce/react-components/region';

import css from './styles.css';

const NavigationMoreContainer = props => {
  const {regions = []} = props;
  const {text} = props;

  return (
    <Styled id="NavigationMoreContainer" css={css}>
      <>
        <div className="NavigationMoreContainer">
          <span
            role="button"
            tabIndex="0"
            className="NavigationMoreContainer__NavLink"
            id={`${NAVBAR_PREFIX}${normalizeText(text)}`}
          >
            {text}
          </span>
          <div className="NavigationMoreContainer__Dropdown">
            {regions.map(regionId => (
              <Region key={regionId} regionId={regionId} />
            ))}
          </div>
        </div>
        <div className="NavigationMoreContainer__Backdrop"></div>
      </>
    </Styled>
  );
};

export default NavigationMoreContainer;
