import {getPage} from '@oracle-cx-commerce/commerce-utils/selector';
import {getCurrentSearchCategory} from '@oracle-cx-commerce/ltd-store/src/plugins/selectors';
import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';

export const getSkuPropById = (skuProps, id) => {
  if (!skuProps) return '';

  return skuProps.find(prop => prop.id === id);
};

export const getCurrentCategory = breadcrumbsSource => {
  const {refinementCrumbs = []} = breadcrumbsSource || {};

  return refinementCrumbs[0]?.properties['dimval.prop.category.repositoryId'];
};

const fetchCollection = async (id, action) => {
  const {
    delta: {
      catalogRepository: {categories}
    }
  } = await action('getCollection', {categoryId: id, maxLevel: 1}).catch(() => {});

  return categories;
};

export const getCollectionProperty = async (source, action, property) => {
  let result;

  try {
    const id = getCurrentCategory(source);
    const categories = await fetchCollection(id, action);

    const category = Object.values(categories)[0];
    result = category[property];
  } catch (err) {
    return '';
  }

  return result;
};

export const getCollection = async (source, action) => {
  let result;

  try {
    const id = getCurrentCategory(source);

    if (!id) {
      throw new Error('ID of current category not found');
    }

    const categories = await fetchCollection(id, action);

    const category = Object.values(categories)[0];

    result = category;
  } catch (err) {
    return null;
  }

  return result;
};

export const getCurrentCategoryId = state => {
  const {pageId = '', pageType = ''} = getPage(state);

  let {contextId: categoryId} = getPage(state);

  if (pageType === 'search') {
    const {breadcrumbs = {}} = getCurrentSearchCategory(state, pageId);
    if (!isEmptyObject(breadcrumbs)) {
      categoryId = getCurrentCategory(breadcrumbs);
    }
  }

  return categoryId;
};
