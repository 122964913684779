import {
    getSku,
    getSite,
    getDefaultPriceListGroup,
    getCategories
} from '@oracle-cx-commerce/commerce-utils/selector';

import {sanitizeDataToDataLayer} from '@oracle-cx-commerce/ltd-store/src/plugins/utils/analytics';

export const getSubcategories = (state, product) => {
    const categoriesPath = product?.parentCategoryIdPath.split('>');
    const catId = categoriesPath[1] || '';
    const categories = [];
    const subCatId = categoriesPath[2] || '';
    const subSubCat = categoriesPath[3] || '';
    const subsId = [];
    const subSubCatId = [];
    const subNames = [];
    const subSubNames = [];
    const productCatIds = [];
    const allCat = getCategories(state);
    const catName = catId && allCat[catId] ? allCat[catId]?.displayName : '';
    const subName = subCatId && allCat[subCatId] ? allCat[subCatId]?.displayName : '';
    const subSubName = subSubCat && allCat[subSubCat] ? allCat[subSubCat]?.displayName : '';
  
    product.childSKUs.forEach(skuId => {
      if (skuId.toLowerCase()?.includes('dummy')) {
        return;
      }
      subsId.push(subCatId);
      subSubCatId.push(subSubCat);
      subNames.push(subName);
      subSubNames.push(subSubName);
      categories.push(catName);
      productCatIds.push(catId);
    });
  
    return {catId, categories, productCatIds, subsId, subSubCatId, subNames, subSubNames};
};

export const handleTealiumViewItemSku = async (store, product, selectedSku) => {
    const state = store.getState();
    const site = getSite(state);

    const {catId, categories, subNames, subSubNames} = getSubcategories(state, product);
    
    const {currency: {currencyCode = 'USD'} = {}} = getDefaultPriceListGroup(state);

    const {
        salePrice,
        listPrice,
        ltd_articleNumber,
        ltd_ltdStockDescription,
        ltd_defaultPinCodeSku,
        ltd_skuCode,
        quantity,
        ltd_pickerOneValue,
        ltd_pickerTwoValue
    } = selectedSku;

    const skuDiscount = salePrice && listPrice - salePrice;
    const skuDiscounts = salePrice && skuDiscount;

    const selectedPickerValues = product?.childSKUs?.length > 1 ? `${ltd_pickerOneValue ? ltd_pickerOneValue : ''} ${
        ltd_pickerTwoValue ? ltd_pickerTwoValue : ''
    }`.trim() : '';
    const productDisplayNameWithVariant = product?.childSKUs?.length > 1 ? product?.displayName.includes(selectedPickerValues)
        ? product?.displayName
        : `${product?.displayName} ${selectedPickerValues}`.trim() : product.displayName;

    const pdpGTMEccomerceData = {
        event: 'view_item',
        ecommerce: {
            currency: currencyCode,
            value: parseFloat(salePrice ? salePrice : listPrice).toFixed(2),
            items: [{
                item_id: product?.repositoryId || '',
                item_name: productDisplayNameWithVariant || '',
                affiliation: site?.name || '',
                discount: Math.abs(parseFloat(skuDiscount ? skuDiscounts.toFixed(2) : 0.0)),
                item_brand: site?.name || '',
                item_category: categories[0] || '',
                item_category2: subNames[0] || '',
                item_category3: subSubNames[0] || '',
                item_variant: ltd_articleNumber || '',
                price: parseFloat(salePrice ? salePrice : listPrice).toFixed(2),
                quantity: 1,
                item_list_id: catId,
                item_list_name: categories[0],
                item_stock_message: ltd_ltdStockDescription,
                item_stock_amount: quantity,
                item_sku_code: ltd_skuCode,
                item_child_sku: ltd_defaultPinCodeSku
            }]
        }
    };

    if (typeof window !== 'undefined') {
        if (window?.dataLayer) {
            const sanitizedEvent = sanitizeDataToDataLayer(pdpGTMEccomerceData);
            window.dataLayer.push({ecommerce: null});
            window.dataLayer.push({...sanitizedEvent});
        }
    }
};

export const handleTealiumViewItemProduct = async (store, product) => {
    const state = store.getState();
    const site = getSite(state);

    const {catId, categories, subNames, subSubNames} = getSubcategories(state, product);
    
    const {currency: {currencyCode = 'USD'} = {}} = getDefaultPriceListGroup(state);

    const skuDetailsSortedByListPrice = product?.childSKUs
        ?.filter(skuId => !skuId.toLowerCase()?.includes('dummy'))
        .map(skuId => getSku(state, {skuId}))
        .sort((skuA, skuB) => {
            return skuA?.listPrice - skuB?.listPrice;
        });

    const {salePrice, listPrice} = skuDetailsSortedByListPrice[0];

    const skuDiscount = salePrice && listPrice - salePrice;
    const skuDiscounts = salePrice && skuDiscount;

    const pdpGTMEccomerceData = {
        event: 'view_item',
        ecommerce: {
            currency: currencyCode,
            value: parseFloat(salePrice ? salePrice : listPrice).toFixed(2),
            items: [{
                item_id: product?.repositoryId || '',
                item_name: product?.displayName || '',
                affiliation: site?.name || '',
                discount: Math.abs(parseFloat(skuDiscount ? skuDiscounts.toFixed(2) : 0.0)),
                item_brand: site?.name || '',
                item_category: categories[0] || '',
                item_category2: subNames[0] || '',
                item_category3: subSubNames[0] || '',
                price: parseFloat(salePrice ? salePrice : listPrice).toFixed(2),
                quantity: 1,
                item_list_id: catId,
                item_list_name: categories[0],
            }]
        }
    };

    if (typeof window !== 'undefined') {
        if (window?.dataLayer) {
            const sanitizedEvent = sanitizeDataToDataLayer(pdpGTMEccomerceData);
            window.dataLayer.push({ecommerce: null});
            window.dataLayer.push({...sanitizedEvent});
        }
    }
};