import {getPage, getWidgets, getProducts, getSkus, getSiteSettings} from '@oracle-cx-commerce/commerce-utils/selector';

import {getCurrentSearchCategory} from '@oracle-cx-commerce/ltd-store/src/plugins/selectors';
import {getCurrentCategory} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/common/helpers';
import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';

export const getComponentData = state => {
  const currentPage = getPage(state);
  const widgets = Object.values(getWidgets(state));

  const BannerVerticalRotationWidget = widgets?.find(({componentId}) => componentId === '_BannerVerticalRotation');
  let alternativeMessage = '';

  if (!isEmptyObject(BannerVerticalRotationWidget)) {
    const {configBannerPromotionMessage1 = ''} = BannerVerticalRotationWidget;

    alternativeMessage = configBannerPromotionMessage1;
  }

  let {contextId: categoryId} = currentPage;
  const {pageType, pageId} = currentPage;

  if (pageType === 'search') {
    const {breadcrumbs = {}} = getCurrentSearchCategory(state, pageId);

    if (!isEmptyObject(breadcrumbs)) {
      categoryId = getCurrentCategory(breadcrumbs);
    }
  }

  const {promoEligibilityBadge = {}} = getSiteSettings(state);

  return {
    products: getProducts(state),
    skus: getSkus(state),
    categoryId,
    alternativeMessage,
    promoEligibilityBadge
  };
};
