import {
  isAuthenticated,
  getCurrentPageId,
  getCurrentSiteId,
  getCurrentProfile,
  getCurrentOrder,
  getPage,
  getVisitorId,
  getCurrentSearchResults
} from '@oracle-cx-commerce/commerce-utils/selector';
import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';
import {isPinCodeSearch} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/commerce-utils/constants';
import {
  RISK_CATEGORY_NET30_NET90,
  RISK_CATEGORY_NET90,
  RISK_CATEGORY_NET30,
  RISK_CATEGORY_CREDIT_CARD_AND_PAYPAL
} from '../../commerce-utils/constants';

const AB_TEST_GROUP = 'site_evolve';
const E_CUSTOM_PAGE = 't';
const GUEST = 'Guest';
const NEW_PERSONAL = 'New personal';
const OPEN_ACCOUNT = 'open account';
const RETURNING_PERSONAL = 'Returning personal';

export const getPageTitle = page => {
  switch (page.pageType) {
    case 'home':
      if (page.siteId === 'LTD') {
        return 'ltd-home';
      }
      if (page.siteId === 'LS') {
        return 'ls-home';
      }
      break;
    case 'cart':
      return 'shopping-cart';
    case 'search':
      return 'Catalog-search-results';
    default:
      return page.title;
  }
};

export const getDatalayerPayload = state => {
  const currentProfile = getCurrentProfile(state);
  const siteId = getCurrentSiteId(state);
  const pageId = getCurrentPageId(state);
  const currentOrder = getCurrentOrder(state);
  const isLoggedIn = isAuthenticated(state) && !isEmptyObject(currentProfile);
  const page = getPage(state, {pageId});
  const visitorId = getVisitorId(state);

  const userAuthenticated = isAuthenticated(state);

  let isNet90 = 'false';
  let payerNumberExists = false;
  let payerNumber = '';
  let riskCategory = RISK_CATEGORY_CREDIT_CARD_AND_PAYPAL;
  let customerType = '';
  let cartCount = 0;
  let shippingAddressEmail = '';

  if (currentOrder && currentOrder.numberOfItems) {
    cartCount = currentOrder.numberOfItems;
    shippingAddressEmail = Object.values(currentOrder?.shippingGroups)[0]?.shippingAddress?.email;
  }

  if (currentProfile && currentProfile.dynamicProperties) {
    currentProfile.dynamicProperties.forEach(property => {
      if (property.id === 'ltd_riskCategory' && property.value !== null) {
        riskCategory = property.value;
        if (property.value === RISK_CATEGORY_NET90 || property.value === RISK_CATEGORY_NET30_NET90) {
          isNet90 = 'true';

          return isNet90;
        }

        return riskCategory;
      }

      if (property.id === 'ltd_payerNumber' && property.value !== null) {
        payerNumberExists = true;
        payerNumber = property.value;

        return payerNumberExists;
      }
    });
  }

  if (!isLoggedIn) {
    customerType = GUEST;
  } else if (
    isLoggedIn &&
    (riskCategory === RISK_CATEGORY_NET30 ||
      riskCategory === RISK_CATEGORY_NET30_NET90 ||
      riskCategory === RISK_CATEGORY_NET90)
  ) {
    customerType = OPEN_ACCOUNT;
  } else if (
    isLoggedIn &&
    !(
      riskCategory === RISK_CATEGORY_NET30 ||
      riskCategory === RISK_CATEGORY_NET30_NET90 ||
      riskCategory === RISK_CATEGORY_NET90
    ) &&
    payerNumberExists
  ) {
    customerType = RETURNING_PERSONAL;
  } else if (isLoggedIn && !payerNumberExists) {
    customerType = NEW_PERSONAL;
  }

  const getPageType = page => {
    switch (page.pageType) {
      case 'search': {
        const {searchEventSummary: {searchTermsRaw} = {}} = getCurrentSearchResults(state);

        if (isPinCodeSearch(searchTermsRaw)) {
          return 'pin-search';
        }

        return 'search-results';
      }
      case 'product':
        return 'product-detail';
      case 'Wish List':
        return 'wish-list';
      case 'profile':
        return 'my-account';
      case 'login':
        return 'my-account-sign-in';
      case 'checkout-login':
        return 'checkout-sign-in';
      case 'checkout-shipping':
        return 'checkout-billing-shipping';
      case 'checkout-order-confirmation':
        return 'checkout-order-confirm';
      case '401':
        return 'my-account-sign-in';
      default:
        return page.pageType;
    }
  };

  const getPageBreadcrumb = (pageType, siteId, page) => {
    switch (pageType) {
      case 'my-account-sign-in':
        if (siteId === 'LTD') {
          return 'LTD Home: Sign-in';
        }
        if (siteId === 'LS') {
          return 'Lakeside Home: Sign-in';
        }
        break;
      case 'my-account':
        if (siteId === 'LTD') {
          return 'LTD Home: My Account';
        }
        if (siteId === 'LS') {
          return 'Lakeside Home: My Account';
        }
        break;
      default:
        return `${pageType}: ${page.title}`;
    }
  };

  const pageType = getPageType(page);
  const pageTitle = getPageTitle(page);
  const pageBreadcrumb = getPageBreadcrumb(pageType, page.siteId, page);

  const specificPayload = {
    page_breadcrumb: pageBreadcrumb,
    page_category: pageType,
    page_channel: pageType,
    page_name: `${pageType}:${pageTitle}`,
    page_name_detailed: `${pageType}:${pageTitle}:${customerType}`,
    page_type: pageType
  };

  const getTwEvent = pageType => {
    if (pageType?.includes('search')) {
      return 'search';
    }

    if (pageType?.includes('product')) {
      return 'productView';
    }

    if (pageType?.includes('checkout-order-confirm')) {
      return 'purchase';
    }

    return 'pageView';
  };

  const genericPayload = {
    ab_test_group: AB_TEST_GROUP,
    cart_value: currentOrder && currentOrder.priceInfo ? currentOrder.priceInfo.amount.toString() : '0',
    customer_id: isLoggedIn ? currentProfile.id : visitorId,
    customer_status: payerNumberExists ? 'existing' : 'new',
    payer_number: payerNumber,
    customer_type: customerType,
    e_custom_page_view: E_CUSTOM_PAGE,
    ...(isLoggedIn && {email_address: currentProfile.email}),
    logged_in_state: isLoggedIn ? 'logged in' : 'anonymous',
    net90: isNet90,
    riskCategory,
    site_name: siteId,
    total_cart_count: cartCount.toString(),
    tw_event: getTwEvent(pageType),
    visitor_id: visitorId
  };

  if (!genericPayload.payer_number) {
    delete genericPayload.payer_number;
  }
  const utagPayload = {
    ...genericPayload,
    ...specificPayload
  };

  return {
    utagPayload,
    currentProfile,
    isLoggedIn,
    userAuthenticated,
    shippingAddressEmail
  };
};
