import React, {useContext, useEffect, useState} from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';
import {StoreContext, ContainerContext} from '@oracle-cx-commerce/react-ui/contexts';
import {useNumberFormatter} from '@oracle-cx-commerce/react-components/utils/hooks';
import {
  getSkuAdditionalShipping,
  getSkuDropShip
} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/utils/shipping/index';
import {LTDNotEligibleExpeditedShipping} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/common/ltd-not-expedited-shipping';
import css from './styles.css';

const OPTIONS = {style: 'currency'};

const ProductShippingInfo = props => {
  const {textAdditionalShipping, textDropShip} = props;
  const store = useContext(StoreContext);
  const {selections = {}} = useContext(ContainerContext);
  const [additionalShipping, setAdditionalShipping] = useState(null);
  const formatCurrency = useNumberFormatter(OPTIONS);
  const {skuId = ''} = selections;

  useEffect(() => {
    const additionalShippingPrice = getSkuAdditionalShipping(store, skuId);
    if (additionalShippingPrice) {
      const formattedPrice = formatCurrency(additionalShippingPrice);
      const formattedAdditionalShippingText = textAdditionalShipping.replace('__PRICE__', formattedPrice);
      setAdditionalShipping(formattedAdditionalShippingText);
    } else {
      setAdditionalShipping(null);
    }
  }, [formatCurrency, selections, skuId, store, textAdditionalShipping]);

  return (
    <Styled id="ProductShippingInfo" css={css}>
      <div className="ProductShippingInfo">
        {additionalShipping && <p className="ProductShippingInfo__TextAdditional">{additionalShipping}</p>}
        {getSkuDropShip(store, skuId) && <p className="ProductShippingInfo__TextDropShip">{textDropShip}</p>}
        <LTDNotEligibleExpeditedShipping skuId={skuId} selections={selections} {...props} />
      </div>
    </Styled>
  );
};

export default ProductShippingInfo;
