import React, {Fragment, useContext} from 'react';
import {ProductContext} from '@oracle-cx-commerce/react-ui/contexts';
import Styled from '@oracle-cx-commerce/react-components/styled';

import {Flex, Icon, Text} from '../../../../../../ltd-ui';

import css from './styles.css';

const LTDProductWarnings = () => {
  const {product} = useContext(ProductContext);
  const {ltd_warnings} = product;

  return ltd_warnings ? (
    <Styled id="LTDProductWarnings" css={css}>
      {ltd_warnings && (
        <div>
          {ltd_warnings
            .split(',')
            .join('')
            .split('Warning:')
            .map(
              warning =>
                warning &&
                !warning?.includes('www.Prop65warning.ca.gov') && (
                  <Flex key={warning} className="ltd-ui-flex align-center">
                    <Icon className="icon-warning LTDProductWarnings__Icon" />
                    <Text className="LTDProductWarnings__Text">
                      {warning}
                    </Text>
                  </Flex>
                )
            )}
        </div>
      )}
    </Styled>
  ) : <Fragment/>;
};

export default LTDProductWarnings;
