import {getSiteSettings, getProductPrice, getSkuPrice, getSku, getSkus} from '@oracle-cx-commerce/commerce-utils/selector';
import {ProductContext, ContainerContext} from '@oracle-cx-commerce/react-ui/contexts';
import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';
import {useSelector} from '@oracle-cx-commerce/react-components/provider';
import {useContext} from 'react';
  
export const useComponentData = () => {
    const siteSettings = useSelector(getSiteSettings);

    const {product = {}} = useContext(ProductContext);
    const {selections: {skuId = null, isAllSkusDisabled = false} = {}} = useContext(ContainerContext) || {};
    const {id: productId = '', childSKUs = []} = product;

    const productPrices = useSelector(getProductPrice, {productId}) || {};

    const {
        list: productListPrice,
        sale: productSalePrice = null
    } = productPrices;

    const {
        listPrice: skuListPrice = null,
        salePrice: skuSalePrice = null
    } = useSelector(getSkuPrice, {skuId}) || {};

    const {ltd_ltdStockStatus: stockStatus = null} = useSelector(getSku, {skuId}) || {};

    const skus = useSelector(getSkus) || {};

    let minPrice = 0;
    let skuStockStatuses = [];

    !isEmptyObject(childSKUs) &&
    childSKUs.forEach(skuId => {
      if (!isEmptyObject(skus[skuId])) {
        const {salePrice, listPrice, ltd_ltdStockStatus = null, ltd_isDummySku = false} = skus[skuId] || {};

        if (!ltd_ltdStockStatus || ltd_ltdStockStatus === '11' || ltd_isDummySku) return;

        if (salePrice) {
            if (salePrice < minPrice || minPrice === 0) {
                minPrice = salePrice;
            }
        }

        if (listPrice && !salePrice) {
            if (listPrice < minPrice || minPrice === 0) {
                minPrice = listPrice;
            }
        }

        skuStockStatuses.push(ltd_ltdStockStatus);
      }
    });

    return {
        siteSettings,
        skuId,
        minPrice,
        skuListPrice,
        skuSalePrice,
        stockStatus,
        skuStockStatuses
    };
};