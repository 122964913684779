import React, {useContext, useState, useCallback} from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';
import {ProductContext, StoreContext, ProductSelectionContext} from '@oracle-cx-commerce/react-ui/contexts';
import RecsContext from '@oracle-cx-commerce/ltd-store/src/plugins/contexts/recs-context';
import {Button} from '../../../../ltd-ui';
import {noop, isEmptyObject} from '@oracle-cx-commerce/utils/generic';
import Link from '@oracle-cx-commerce/react-components/link';
import {handleTealiumAddToCart} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/data-tagging/ltd-add-to-cart';
import {isMobile, getCurrentOrder} from '@oracle-cx-commerce/commerce-utils/selector';
import {useSelector} from '@oracle-cx-commerce/react-components/provider';
import {MiniCartModalDesktop} from './components/mini-cart-modal-desktop/index';
import {MiniCartModalMobile} from './components/mini-cart-modal-mobile/index';

import css from './styles.css';

const CTAButton = props => {
  // contexts
  const store = useContext(StoreContext);
  const {skus} = useContext(ProductSelectionContext);
  const {action} = store;
  const [, setShowLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const mobile = useSelector(isMobile);
  let {route = ''} = useContext(ProductContext);
  const {record = {}} = useContext(ProductContext);
  const {isRecsFromMiniCartModal = false} = useContext(RecsContext) || {};
  const {commerceItems = {}} = useSelector(getCurrentOrder);

  const {
    product,
    componentId,
    recommendation,
    setAlreadyFetchedSkus,
    showAddToCartSkuCarosel = false,
    isSavedForLaterCarousel = false,
    currentSkuId,
    onComplete = noop
  } = props;

  let productId,
    catRefId,
    isDummySku,
    skuPersonalized,
    isOutOfStock,
    payload = null;

  if (showAddToCartSkuCarosel && product) {
    payload = {
      catRefId: currentSkuId,
      productId: product?.repositoryId,
      quantity: 1
    };
  } else if (product) {
    route = product.route;
    if (product.childSKUs.length === 1) {
      productId = product.repositoryId;
      [catRefId] = product.childSKUs;
      isDummySku = skus && skus[catRefId] && skus[catRefId].ltd_isDummySku;
      skuPersonalized = skus && skus[catRefId] && skus[catRefId].ltd_personalized === 'Yes';

      payload = !skuPersonalized && {
        catRefId,
        productId,
        quantity: 1
      };
    }
  } else {
    productId = record.attributes['product.repositoryId'] && record.attributes['product.repositoryId'][0];
    catRefId = record.attributes['sku.repositoryId'] && record.attributes['sku.repositoryId'][0];
    isDummySku =
      (record.attributes['sku.ltd_isDummySku'] && record.attributes['sku.ltd_isDummySku'][0] === '1') ||
      (record.attributes['sku-LTDProduct.ltd_isDummySku'] &&
        record.attributes['sku-LTDProduct.ltd_isDummySku'][0] === '1');

    skuPersonalized =
      (record.attributes['sku.ltd_personalized'] && record.attributes['sku.ltd_personalized'][0] === 'Yes') ||
      (record.attributes['sku-LTDProduct.ltd_personalized'] &&
        record.attributes['sku-LTDProduct.ltd_personalized'][0] === 'Yes');

    isOutOfStock =
      (record.attributes['sku.ltd_ltdStockStatus'] && record.attributes['sku.ltd_ltdStockStatus'][0] === '11') ||
      (record.attributes['sku-LTDProduct.ltd_ltdStockStatus'] &&
        record.attributes['sku-LTDProduct.ltd_ltdStockStatus'][0] === '11');

    if (record.numRecords === 1 && !skuPersonalized) {
      payload = {
        catRefId,
        productId,
        quantity: 1
      };
    }
  }

  const onOk = () => {
    setHasError(false);
  };
  const onNotOk = () => {
    setHasError(true);
  };

  const addToCart = (payload, onOk, onNotOk, onComplete) => {
    setShowLoader && setShowLoader(true);
    setAlreadyFetchedSkus && setAlreadyFetchedSkus(false);
    let locationAdded = '';
    if (componentId === 'ProductRecommendationsCarousel' || recommendation) {
      if (isRecsFromMiniCartModal) {
        locationAdded = 'addedFromProdRecCart';
      } else {
        locationAdded = isSavedForLaterCarousel ? 'addedFromSavedForLaterCarousel' : 'addedFromRecommendation';
      }
    }

    const productsEventData = JSON.parse(JSON.stringify(payload));

    if (!isEmptyObject(commerceItems)) {
      const itemCart = Object.values(commerceItems).find(
        item => item.catRefId === payload.catRefId && payload.productId === item.productId
      );
      if (
        itemCart?.commerceItemId &&
        !itemCart?.giftWithPurchaseCommerceItemMarkers?.length &&
        !payload.ltd_personalizationData
      ) {
        payload.commerceItemId = itemCart.commerceItemId;
        payload.quantity = parseInt(payload.quantity);
      }
    }
    let payloadRequest = {
      items: [{...payload}],
      combineLineItems: "yes"
    };
    action('addItemsToCart', payloadRequest)
      .then(response => {
        if (response.ok === true) {
          handleTealiumAddToCart(store, [{...payload}], [{...productsEventData}], locationAdded);
          onOk(response);
          onComplete(payload);
        } else {
          setShowModal(true);
          onNotOk(response);
        }
        setShowLoader && setShowLoader(false);
      })
      .catch(error => {
        setShowLoader && setShowLoader(false);
        setShowModal(true);
        onNotOk({error});
      });
  };

  const addToCartIfNotDummySku = () => {
    !isDummySku && addToCart(payload, onOk, onNotOk, onComplete);
  };

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const showAddToCart = !isDummySku && !skuPersonalized && !isOutOfStock;
  const textButton = skuPersonalized ? 'Personalize' : 'View Options';
  const validationDisplayButton = () => payload && showAddToCart;

  const ctaBtn =
    payload && showAddToCart ? (
      <Button className="ltd-ui-button CTAButton__btn" onClick={addToCartIfNotDummySku}>
        <span className="CTAButton__btn--text">Add to Cart</span>
      </Button>
    ) : (
      <Button className="ltd-ui-button Button__btn">
        <Link href={route}>
          <span className="Button__btn--text">{textButton}</span>
        </Link>
      </Button>
    );

  const ctaBtnCarosel = validationDisplayButton() && (
    <Button className="ltd-ui-button CTAButton__btn" onClick={addToCartIfNotDummySku}>
      <span className="CTAButton__btn--text">{isSavedForLaterCarousel ? 'Move to Cart' : 'Add to Cart'}</span>
    </Button>
  );
  /**
   * Handler for AddToCartButton
   * Dispatches action when add to cart is clicked
   * Closes quick view on success
   * @param {object} props.product the product details to be displayed in quick view
   * @param  {Object} Object
   */

  return (
    <Styled id="CTAButton" css={css}>
      {hasError && <MiniCartModalMobile closeMiniCart={closeModal} showModal={showModal} />}
      {showAddToCartSkuCarosel ? (
        <div className="CTAButton">{ctaBtnCarosel}</div>
      ) : (
        <div className="CTAButton">{ctaBtn}</div>
      )}
    </Styled>
  );
};

export default CTAButton;
