import {StoreContext} from '@oracle-cx-commerce/react-ui/contexts';
import React, {useContext, useEffect, useState, useCallback} from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';
import css from '@oracle-cx-commerce/react-widgets/product/product-inventory-status/styles.css';
import {getSku} from '@oracle-cx-commerce/commerce-utils/selector';
import {useComponentData} from './selectors';

const ProductInventoryStatus = props => {
  //labels

  const store = useContext(StoreContext);
  const [stockDescription, setStockDescription] = useState(null);

  // selector
  const {skuId, isAllSkusDisabled} = useComponentData();

  const getSkuStockDescription = useCallback(
    skuId => {
      const fullSku = getSku(store.getState(), {skuId});
      const description = fullSku.ltd_ltdStockDescription ? fullSku.ltd_ltdStockDescription : null;

      return description;
    },
    [store]
  );

  useEffect(() => {
    const stockDescription = getSkuStockDescription(skuId);
    setStockDescription(stockDescription);
  }, [skuId, getSkuStockDescription]);

  if (isAllSkusDisabled) {
    return null;
  }

  return (
    <Styled id="ProductInventoryStatus" css={css}>
      {stockDescription ? (
        <div className="ProductInventoryStatus">
          <span className={`ProductInventoryStatus__Text`}>{stockDescription}</span>
        </div>
      ) : (
        ''
      )}
    </Styled>
  );
};

export default ProductInventoryStatus;
