import React, {useState} from 'react';

const Tooltip = ({
  children,
  content,
  position,
  type,
  className,
  ...props
}) => {
  
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const HOVER = 'hover'
  const CLICK =  'click'

  const handleOnHover = () => {
    if(!type || type === HOVER){
      setIsTooltipVisible(true)
    }
  }
  
  const handleOnLeave = () => {
    setIsTooltipVisible(false)
  }

  const onClick = () => {
    if(type === CLICK){
      setIsTooltipVisible(true)
    }
  }

  return (
    <div
      onMouseOver = {() => {handleOnHover()}}
      onMouseLeave = {() => {handleOnLeave()}}
      onClick = {() => {onClick()}}
      tabIndex={0}
      onFocus={() => {handleOnHover()}}
      onBlur={() => {handleOnLeave()}} 
      role="tooltip"
      className="ltd-ui-tooltip"
      {...props}
    >
      {children}   
      {
        isTooltipVisible && (
          <div className={`tip-container ${className ? className : ''} ${position}`}>
            {content}
          </div>
        )
      }
    </div>
  );
};

export { Tooltip };