import React, {useContext, useEffect, useState, useCallback} from 'react';
import {ProductContext} from '@oracle-cx-commerce/react-ui/contexts';
import {connect} from '@oracle-cx-commerce/react-components/provider';
import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';
import Styled from '@oracle-cx-commerce/react-components/styled';
import Img from '@oracle-cx-commerce/react-components/img';

import css from './styles.css';
import {getComponentData} from './selectors';

const EXCLUSIVE_IMAGE = 'exclusive';

const LTDProductBrands = props => {
  const {imageName = 'ASOTV', skus} = props;

  let {childSKUs} = useContext(ProductContext);

  if (props.productInfo) {
    childSKUs = props?.productInfo?.childSKUs || [];
  }

  const [brands, setBrands] = useState([]);
  const [invalidImageCounter, setInvalidImageCounter] = useState(0);

  const removeFaultyImage = imgIndex => {
    setInvalidImageCounter(prevState => ++prevState);

    const imageUrls = [...brands];
    imageUrls.splice(imgIndex, 1);

    setBrands(imageUrls);
  };

  const handleGetBrandLogos = useCallback(async () => {
    const brandSet = new Set();

    childSKUs.forEach(skuId => {
      if (!isEmptyObject(skus[skuId])) {
        const sku = skus[skuId];

        if (sku.ltd_isAsSeenOnTV === 'Yes') {
          brandSet.add(imageName.toLowerCase());
        }

        if (sku.ltd_brand_ids) {
          const selectedBrands = sku.ltd_brand_ids.toLowerCase().split(',');

          selectedBrands.map(id => brandSet.add(id.replace(/[^A-Z0-9]+/gi, '')));
        }
      }
    });

    setBrands([...brandSet]);
  }, [childSKUs, imageName, skus]);

  useEffect(() => {
    if (!childSKUs || !Array.isArray(childSKUs) || !childSKUs.length || isEmptyObject(skus)) return;

    handleGetBrandLogos();
  }, [handleGetBrandLogos, childSKUs, skus]);

  return (
    <Styled id="LTDProductBrands" css={css}>
      <div
        className={brands.length === invalidImageCounter ? 'LTDProductBrand__NoHeight' : 'LTDProductBrands__Container'}
      >
        {brands.map(
          (brand, index) =>
            brand !== EXCLUSIVE_IMAGE && (
              <Img
                key={brand}
                src={`/file/general/${encodeURI(brand)}.jpg`}
                alt="Brand Logo"
                onErrorCallback={() => {
                  removeFaultyImage(index);
                }}
              />
            )
        )}
      </div>
    </Styled>
  );
};

export default connect(getComponentData)(LTDProductBrands);
