import React from "react";

const Flex = props => {
  return (
    <div {...props}>
      {props.children}
    </div>
  );
};

export { Flex };
