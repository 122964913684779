import React from 'react';
import {Center, Icon} from '../../../../ltd-ui';

export const CloseToastButton = ({closeToast}) => (
  <Center className="ltd-ui-center">
    <Icon className="icon-close1 close-toast" onClick={closeToast} />
  </Center>
);

export const setToastTypeColors = toastType => {
  switch (toastType) {
    case 'success':
      return 'var(--success-100)';
    case 'warn':
      return 'var(--attention-200)';
    case 'error':
      return 'var(--error-200)';
    case 'info':
      return 'var(--primary-400)';
    default:
      return '';
  }
};

export const getAppropriatelyIcon = toastType => {
  switch (toastType) {
    case 'success':
      return 'icon-check';
    case 'warn':
      return 'icon-warning';
    case 'error':
      return 'icon-invalid';
      case 'info':
        return 'icon-info';
    default:
      return '';
  }
};
