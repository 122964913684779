import React, {useRef, useEffect} from "react";
import { Icon } from "../Icon";
import { Spinner } from "../../index";

export const Modal = ({
  title,
  footer,
  children,
  active,
  hideModal,
  removeIconClose,
  isLoading = false,
  id,
  role,
  tabindex,
  'aria-labelledby': ariaLabelledby,
  className,
  ...props
}) => {
  const handleEnter = (e) => {
    e.key === 'Enter' && hideModal();
  }

  const buttonRef = useRef(null)

  useEffect(()=>{
    if(buttonRef && buttonRef.current) {
      buttonRef.current.focus()
    }
  })

  const withLabelledBy= ariaLabelledby ? {'aria-labelledby': ariaLabelledby} : {} ;

  return (
    <>
      {active && (
        <div className="ltd-ui-modal">
          {isLoading ? (
            <Spinner className="modal-spinner" />
          ) : (
            <>
              <div
                className={`modal-container ${className ? className : ''}`}
                role={role}
                id={id ? id : ''}
                tabIndex={tabindex ? -1 : 0}
                {...withLabelledBy} 
              >
                <div className="modal-header">
                  <span className="modal-title">{title}</span>
                  {!removeIconClose && (
                    <div className="modal-close" role={'switch'} ref={buttonRef} onClick={() => hideModal()} onKeyDown={handleEnter} tabIndex={0} aria-label="Close">
                      <Icon className="icon-close" />
                    </div>
                  )}
                </div>
                <div className="modal-body">{children}</div>
                <div className="modal-footer">{footer}</div>
              </div>
            </>
          )}
          <div
            className="modal-overlay"
            onClick={() => hideModal()} 
            onKeyDown={handleEnter} 
            onBlur={() => hideModal()} 
            tabIndex={0} 
            aria-label="Close"
            role={'switch'} 
          ></div>
        </div>
      )}
    </>
  );
};