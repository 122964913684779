const filter = `OR(AND(NOT(sku.ltd_ltdStockStatus:11)),OR(AND(sku.x_skuConcat:dummy,NOT(sku.ltd_ltdStockStatus:11))))`;

const filterBook = `OR(AND(sku.availabilityStatus:INSTOCK),OR(AND(sku.x_skuConcat:dummy,NOT(sku.ltd_ltdStockStatus:11))))`;

export const IS_BOOK_PAGE_X_SHOW_SOLD_OUT = `Nr=AND(sku.ltd_showInBookSku:Yes)`;
export const IS_NOT_BOOK_PAGE_NOT_LTD_IS_PROMO_CATEGORY = `Nr=AND(sku.ltd_showInCategorySku:Yes,${filter})`;
export const IS_BOOK_PAGE_NOT_X_SHOW_SOLD_OUT = `Nr=AND(sku.ltd_showInBookSku:Yes,${filterBook})`;
export const NOT_CATEGORY_IS_PAGE_SEARCH = `Nr=AND(sku.ltd_showInSearchSku:Yes,${filter})`;
export const TYPEAHEAD_FILTER = `AND(sku.ltd_showInSearchSku:Yes,${filter})`;

const GENERIC_MASK_REGEX = /^[0-9]{6}/;
const LS_MASK_REGEX = /^[a-zA-Z0-9]{9}$/;
const LTD_MASK_1_REGEX = /^[a-zA-Z0-9]{6}-[a-zA-Z0-9]{4}$/;
const LTD_MASK_2_REGEX = /^[a-zA-Z0-9]{6}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{3}$/;

export const isPinCodeSearch = term =>
  LTD_MASK_1_REGEX.test(term) ||
  LTD_MASK_2_REGEX.test(term) ||
  LS_MASK_REGEX.test(term) ||
  GENERIC_MASK_REGEX.test(term);

export const formatTypeAheadValue = (term, enableWildcardForSearch, searchTermMaxLengthForWildcard) => {
  if (enableWildcardForSearch && term && term.length <= searchTermMaxLengthForWildcard) {
    return term = `${term}*`;
  }

  if (
    LTD_MASK_1_REGEX.test(term) ||
    LTD_MASK_2_REGEX.test(term) ||
    LS_MASK_REGEX.test(term) ||
    GENERIC_MASK_REGEX.test(term)
  ) {
    term = `${term.replace(/-/g, '')}*`;
  }

  return term;
};

export const getFilterBySearchTerm = (term = '', isTypeahead) => {
  let newFilter = isTypeahead ? TYPEAHEAD_FILTER : NOT_CATEGORY_IS_PAGE_SEARCH;

  if (
    LTD_MASK_1_REGEX.test(term) ||
    LTD_MASK_2_REGEX.test(term) ||
    LS_MASK_REGEX.test(term) ||
    GENERIC_MASK_REGEX.test(term)
  ) {
    if (isTypeahead) {
      newFilter = `AND(OR(product.ltd_showInSearch:Yes,sku.ltd_showInSearchSku:Yes),${filter})`;
    } else {
      newFilter = NOT_CATEGORY_IS_PAGE_SEARCH;
    }
  }

  return newFilter;
};
