export const getImageDisplay = (image = '') => {
  if (image?.split('_sc').length === 1 && image?.split('_pm').length === 1) {
    return image;
  }

  return '';
};

export const getImageDisplayPM = (image = '') => {
  return image?.split('_sc').length === 1;
};
