import React, {useContext} from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';
import {StoreContext} from '@oracle-cx-commerce/react-ui/contexts';
import {getCurrentSiteId} from '@oracle-cx-commerce/commerce-utils/selector';
import {noop} from '@oracle-cx-commerce/utils/generic';
import {MenuMobileContext} from '@oracle-cx-commerce/react-widgets/common/menu-mobile/context';

import {Icon, Center, Text} from '../../../../../ltd-ui';

import css from './styles.css';

function LTDHeaderEmailButton(props) {
  const {emailButtonText} = props;

  const {getState} = useContext(StoreContext);
  const {setMenuOpen = noop} = useContext(MenuMobileContext) || {};

  const siteId = getCurrentSiteId(getState());

  const handleModalVisibility = () => {
    setMenuOpen(false);
    if (siteId === 'LTD') {
      window?._ltk?.Popup?.openManualByName('LTD-Trigger-Popup');
    } else {
      window?._ltk?.Popup?.openManualByName('LS-Trigger-Popup');
    }
  };

  return (
    <Styled id="LTDHeaderEmailButton" css={css}>
      <Center className="ltd-ui-center LTDHeaderEmailButton" onClick={handleModalVisibility}>
        <Icon className="icon-email LTDHeaderEmailButton__Icon"></Icon>
        <Text className="LTDHeaderEmailButton__Text">
          {emailButtonText}
        </Text>
      </Center>
    </Styled>
  );
}

export default LTDHeaderEmailButton;
