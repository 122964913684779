import React, {Fragment, useContext} from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';
import {StoreContext} from '@oracle-cx-commerce/react-ui/contexts';
import {validateExpitedShipping} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/utils/shipping/index';
import {getSiteSettings} from '@oracle-cx-commerce/commerce-utils/selector';
import {noop, isEmptyObject} from '@oracle-cx-commerce/utils/generic';
import {Icon} from '../../../../ltd-ui';
import css from './styles.css';

export const LTDNotEligibleExpeditedShipping = props => {
  const {textNotEligibleForExpeditedShipping, selections = {}, isRecommendation = false} = props;
  let {skuId = ''} = props;

  const store = useContext(StoreContext);
  const {getState = noop} = store;
  const {shippingConfig = {}} = getSiteSettings(getState());
  const {enableExpeditedShipping = false} = shippingConfig;

  if (isRecommendation && !isEmptyObject(selections)) {
    skuId = selections.skuId;
  }

  const isNotEligibleForExpitedShipping = enableExpeditedShipping ? validateExpitedShipping(store, skuId) : null;

  return isNotEligibleForExpitedShipping ? (
    <Styled id="LTDNotEligibleExpeditedShipping" css={css}>
      {isNotEligibleForExpitedShipping && (
        <p className="TextNotEligibleForExpeditedShipping">
          <Icon className="icon-info LTDNotEligibleExpeditedShipping__Icon" />
          {textNotEligibleForExpeditedShipping}
        </p>
      )}
    </Styled>
  ) : <Fragment/>;
};
