import React, { useContext } from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';
import { Text, Box, Flex, Icon } from '../../../../ltd-ui';
import Img from '@oracle-cx-commerce/react-components/img';

/*
 * Uncomment the following line to get the parameter substitution
 * function, e.g. t(someParameterizedResourceString, "someValue").
 */

/**
 * The custom component example.
 *
 * @param {string} props.config* Configurations properties for Design studio
 * @param {string} props.label* Resource strings from imported locales
 */



import css from './styles.css';

const FooterCallUs = props => {

  const {
    phoneText,
    callUs,
    callUsText,
  } = props;

  return (
    <Styled id="FooterCallUs" css={css}>
      <Box className="ltd-ui-box">
        <Flex className="ltd-ui-flex FooterCallUs__Flex">
          <Box className="ltd-ui-box FooterCallUs">

            <Flex className="ltd-ui-flex FooterCallUs__Inner">
              
              <Icon className="icon-phone visible-xs FooterCallUs__Icon FooterCallUs__Icon--mobile"></Icon>

              <Text className="FooterCallUs__Phone pp1">
                <span>{callUs}</span>
                <span>
                  <Icon className="icon-phone hide-xs FooterCallUs__Icon"></Icon> 
                  {phoneText}
                </span>
              </Text>
            </Flex>

            <Text className="FooterCallUs__Text pp2">
              {callUsText}
            </Text>

          </Box>
        </Flex>
      </Box>
    </Styled>
  );
};

export default FooterCallUs;
