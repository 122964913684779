import React, {useContext, useEffect, useState, useCallback} from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';
import {ContainerContext, ProductContext, ProductSelectionContext} from '@oracle-cx-commerce/react-ui/contexts';
import {useSelector} from '@oracle-cx-commerce/react-components/provider';
import {getSku} from '@oracle-cx-commerce/commerce-utils/selector';
import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';
import css from './styles.css';

const ProductPinCode = () => {
  const {
    selections: {skuId}
  } = useContext(ContainerContext);

  const {productSelection} = useContext(ProductSelectionContext);

  const {ltd_bookPage: ltdBookPageProduct = null, route} = useContext(ProductContext);
  const {ltd_bookPage: ltdBookPageSku = null, ltd_defaultPinCodeSku = null} = useSelector(getSku, {skuId});

  const [pinCode, setPinCode] = useState(null);

  const handlePinCode = useCallback(() => {
    setPinCode(null);
    const ltdBookPageProductArray = JSON.parse(ltdBookPageProduct);
    const ltdBookPageSkuArray = JSON.parse(ltdBookPageSku);

    if (ltdBookPageProductArray.length && ltdBookPageSkuArray.length) {
      let bookPage = null;

      ltdBookPageProductArray.find(bookPageProduct => {
        const {bookId} = bookPageProduct;

        bookPage = ltdBookPageSkuArray.find(item => bookId === item.bookId);

        return !isEmptyObject(bookPage);
      });

      if (!isEmptyObject(bookPage)) {
        setPinCode(bookPage.pinCode);

        const {state} = history;

        let obj = {isBook: true, pin: bookPage.pinCode};
        if (state) {
          obj = {...state, ...obj};
        }

        history.replaceState(obj, route);
      } else {
        const {state} = history;

        let obj = {isBook: false, pin: ltd_defaultPinCodeSku};
        if (state) {
          obj = {...state, ...obj};
        }
        history.replaceState(obj, route);
        setPinCode(ltd_defaultPinCodeSku);
      }
    } else {
      const {state} = history;

      let obj = {isBook: false, pin: ltd_defaultPinCodeSku};
      if (state) {
        obj = {...state, ...obj};
      }
      history.replaceState(obj, route);

      setPinCode(ltd_defaultPinCodeSku);
    }
  }, [ltdBookPageProduct, ltdBookPageSku, ltd_defaultPinCodeSku, route]);

  useEffect(() => {
    if (history) {
      setPinCode(null);
      const {state} = history || {};
      const {previousPage = ''} = state || {};

      if (previousPage === 'isBook' && ltdBookPageProduct && ltdBookPageSku) {
        handlePinCode();
      } else if (ltd_defaultPinCodeSku) {
        const {state} = history;

        let obj = {isBook: false, pin: ltd_defaultPinCodeSku};
        if (state) {
          obj = {...state, ...obj};
        }
        history.replaceState(obj, route);
        setPinCode(ltd_defaultPinCodeSku);
      } else {
        setPinCode("");
      }
    }
  }, [handlePinCode, ltdBookPageProduct, ltdBookPageSku, ltd_defaultPinCodeSku, route]);

  return (
    <Styled id="ProductPinCode" css={css}>
      {productSelection.pinCodeDefault !== null && (
        <div className="ProductPinCode">
          <p className="ProductPinCode__Text">item#: {productSelection.pinCodeDefault}</p>
        </div>
      )}

      {pinCode && productSelection.pinCodeDefault === null && (
        <div className="ProductPinCode">
          <p className="ProductPinCode__Text">item#: {pinCode}</p>
        </div>
      )}
    </Styled>
  );
};

export default ProductPinCode;
