import {
  getSku,
  getProduct,
  getCategories,
  getCurrentPageId,
  getPage,
  getCurrentOrder,
  getCategory,
  getCurrentSearchResults,
  getSiteSettings,
  getSite,
  getDefaultPriceListGroup
} from '@oracle-cx-commerce/commerce-utils/selector';
import {getSearchResultsFetcherData} from '@oracle-cx-commerce/fetchers/search/selectors';
import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';
import {isPinCodeSearch} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/commerce-utils/constants';
import {getTotalOrderValue} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/utils/functions/get-total-order-value';
import {sanitizeDataToDataLayer} from '@oracle-cx-commerce/ltd-store/src/plugins/utils/analytics';
import {requiredSkuFields} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/data-tagging/events/common/constants';
import {validateDatalayerPayload} from '../utag/utils';

const ROOT_CATEGORY_OCC = 'Commerce Cloud Root Category';

const RECOMMENDATION_LOCATION = 'addedFromRecommendation',
  QUICK_VIEW_LOCATION = 'addedFromQv',
  FREQUENTLY_BOUGHT_LOCATION = 'addedFromFrequentlyBought',
  WISHLIST_LOCATION = 'addedFromWishlist',
  CATALOG_QUICK_ORDER_LOCATION = 'addedFromQO',
  RECOMMENDATION_QV_LOCATION = 'addedFromProdRecQV',
  RECOMMENDATION_CART_LOCATION = 'addedFromProdRecCart',
  DIGITAL_CATALOG = 'addedFromDigitalCatalogQV',
  SAVED_FOR_LATER = 'addedFromSavedForLaterCarousel';

const actionListProduct = async (action, productIds, productsInCart, skusInCart) => {
  let products = {},
    skus = {};
  const response = await action('listProducts', {productIds}).catch(() => {});
  products = response.delta.catalogRepository.products;
  skus = response.delta.catalogRepository.skus;

  productsInCart.push(Object.values(products)[0]);
  skusInCart.push(Object.values(skus)[0]);
};

const getProductDetails = async (action, {commerceItems}, getState) => {
  const productIds = [],
    skusInCart = [],
    productsInCart = [];

  for (const commerceId in commerceItems) {
    const {catRefId, productId} = commerceItems[commerceId];
    const productDetails = getProduct(getState(), {productId});
    const skuDetails = getSku(getState(), {skuId: catRefId});

    const hasMissingFields =
      !isEmptyObject(skuDetails) && requiredSkuFields.filter(item => skuDetails[item] === undefined).length > 0;

    if (isEmptyObject(productDetails)) {
      productIds.push(productId);
    } else if (isEmptyObject(skuDetails) || hasMissingFields) {
      productIds.push(productId);
    } else {
      productsInCart.push(productDetails);
      skusInCart.push(skuDetails);
    }
  }

  if (productIds.length > 0) {
    await actionListProduct(action, productIds, productsInCart, skusInCart).catch(() => {});
  }

  return {
    productsInCart,
    skusInCart
  };
};

const getCartLocation = (pageType, bookId, locationAdded, prodCat) => {
  let cartLocation;
  let findingMethod = 'browse';

  if (locationAdded === RECOMMENDATION_LOCATION) {
    if (pageType === 'product') {
      cartLocation = 'prod rec pdp';
      findingMethod = 'pdp';
    } else {
      cartLocation = 'prod rec';
    }
  } else if (locationAdded === SAVED_FOR_LATER) {
    cartLocation = 'saved for later carousel';
  } else if (locationAdded === RECOMMENDATION_CART_LOCATION) {
    cartLocation = 'prod rec';
  } else if (locationAdded === QUICK_VIEW_LOCATION) {
    cartLocation = 'qv';
  } else if (locationAdded === FREQUENTLY_BOUGHT_LOCATION) {
    cartLocation = 'pdp collection';
  } else if (locationAdded === WISHLIST_LOCATION) {
    cartLocation = 'wishlist';
  } else if (locationAdded === CATALOG_QUICK_ORDER_LOCATION) {
    cartLocation = 'cqo';
  } else if (locationAdded === RECOMMENDATION_QV_LOCATION) {
    cartLocation = 'prod rec qv';
  } else if (locationAdded === DIGITAL_CATALOG) {
    cartLocation = 'dcatalog qv';
  } else if (bookId) {
    cartLocation = bookId;
  } else if (pageType === 'category') {
    cartLocation = prodCat;
  } else if (pageType === 'product') {
    cartLocation = 'pdp';
  } else if (pageType === 'search') {
    cartLocation = 'search';
  }

  return {cartLocation, findingMethod};
};

export const getProductBreadcrumb = (page, allCat, state) => {
  if (page) {
    switch (page.pageType) {
      case 'home':
        return 'Home';
      case 'category': {
        if (
          page.pageId?.toLowerCase()?.includes('shop-catalogs') ||
          page.pageId?.toLowerCase()?.includes('shopcatalogs')
        ) {
          return `Catalog:${page.title}`;
        }
        const categoryInfo = allCat[page.contextId];

        const subCategories =
          categoryInfo?.categoryPaths &&
          categoryInfo?.categoryPaths.length &&
          categoryInfo?.categoryPaths[0].split('/');

        const filteredCategories = subCategories
          ?.filter(cat => cat !== '' && !cat?.includes(ROOT_CATEGORY_OCC))
          .join(':');

        if (filteredCategories) {
          return filteredCategories;
        }

        return page.title;
      }
      case 'search': {
        let {searchEventSummary: {searchTermsRaw} = {}} = getCurrentSearchResults(state);

        if (typeof window !== 'undefined') {
          const url = new URL(window.location.href);
          searchTermsRaw = url.searchParams.get('Ntt');
        }

        if (isPinCodeSearch(searchTermsRaw)) {
          return 'PIN Search';
        }

        return 'Search Results';
      }
      case 'product': {
        const productId = page.contextId;
        const product = getProduct(state, productId);
        const categoriesPath = product?.parentCategoryIdPath.split('>');
        const catId = categoriesPath[1] || '';
        const subCatId = categoriesPath[2] || '';
        const subSubCatId = categoriesPath[3] || '';

        const catInfo = catId && allCat[catId] ? allCat[catId]?.displayName : '';
        const subCatInfo = subCatId && allCat[subCatId] ? allCat[subCatId]?.displayName : '';
        const subSubCatInfo = subSubCatId && allCat[subSubCatId] ? allCat[subSubCatId]?.displayName : '';

        let categoryInfo = catInfo;
        if (subCatInfo) {
          categoryInfo += `:${subCatInfo}`;
        }
        if (subSubCatInfo) {
          categoryInfo += `:${subSubCatInfo}`;
        }

        if (sessionStorage.prevPage) {
          categoryInfo = sessionStorage.prevPage;
        }

        return categoryInfo;
      }
      case 'profile':
        return page.pageI?.includes('wish-list') ? 'Wishlist' : page.title;
      case 'article':
        return page.pageId?.includes('quick-order') ? 'Catalog Quick Order' : page.title;
      default:
        return page.title;
    }
  }

  return '';
};

const getBookId = async (store, pageId) => {
  const {contextId} = getSearchResultsFetcherData(store.getState());
  let category = getCategory(store.getState(), {categoryId: contextId});
  const {id} = category;
  let categoryId = null,
    isBookPage = false;
  if (!id && !pageId?.includes('Ntt=')) {
    const [url, dimensions] = pageId.split('/_/N-');
    const route = dimensions ? `${url}/_/N-${dimensions}` : url;
    const payload = {url: route};
    const response = await store.endpoint('getSearchCurrentCategoryPage', payload);
    if (response.ok) {
      const {searchCurrentCategoryPageRepository = {}} = response.delta;
      const {pages = {}} = searchCurrentCategoryPageRepository;
      const {searchEventSummary = {}} = pages[pageId];
      const {facetFilters = []} = searchEventSummary;
      if (facetFilters.length) {
        const dimensionCategory = facetFilters.find(item => item.dimensionName === 'product.category');
        if (dimensionCategory && dimensionCategory.spec) {
          const categoryIds = dimensionCategory.spec.split('.');
          categoryId = categoryIds[categoryIds.length - 1];
          category = getCategory(store.getState(), {categoryId});
          if (
            category?.fixedParentCategories?.find(parentCategory => parentCategory?.displayName === 'Shop Catalogs')
          ) {
            isBookPage = true;
          }
        }
      }
    }
  } else if (category?.fixedParentCategories?.find(parentCategory => parentCategory?.displayName === 'Shop Catalogs')) {
    isBookPage = true;
  }

  if (isBookPage && category) {
    return category.id;
  }

  return null;
};

const getPropertyValue = (hasActualCategoryPath, propertyValues = [], propertyValueIndex, alternativeData = []) => {
  if (hasActualCategoryPath && propertyValues.length) {
    return propertyValues?.[propertyValueIndex];
  }

  return alternativeData;
};

export const handleTealiumAddToCart = async (store, itemsAddedToCart, eventOnlyItemsAddedToCartData, locationAdded) => {
  const {action, getState} = store;
  const state = getState();
  const currentOrder = getCurrentOrder(state);
  const {pageType} = getPage(getState());
  const pageId = getCurrentPageId(getState());
  const page = getPage(getState(), {pageId});
  const {commerceItems = {}, numberOfItems = 0} = currentOrder;
  const {productsInCart, skusInCart = []} = await getProductDetails(action, {commerceItems}, getState);
  const cartData = Object.values(commerceItems);
  const allCat = getCategories(getState());
  const cartValue = currentOrder?.priceInfo?.amount;
  let method = '',
    location = '';
  const {shippingConfig = {}} = getSiteSettings(state);
  const site = getSite(state);
  const {currency: {currencyCode = 'USD'} = {}} = getDefaultPriceListGroup(state);

  const productParentSku = [],
    productChildSku = [],
    productChildSkuId = [],
    productCategory = [],
    productCategoryId = [],
    productSubcategory = [],
    productSubcategoryId = [],
    productSubSubCategory = [],
    productSubSubCategoryId = [],
    productPrice = [],
    productIds = [],
    productBrand = [],
    productQuantity = [],
    productBreadcrumb = [],
    skuArticleIds = [],
    skuUpc = [],
    skuCode = [],
    skuAvailability = [],
    skuAttribute = [];

  const bookId = await getBookId(store, pageId);
  itemsAddedToCart.forEach((item, index) => {
    const originalData = eventOnlyItemsAddedToCartData.find(
      product => product.catRefId === item.catRefId || product.catRefId === item.skuId
    );

    const addedProductId = item.productId;
    const addedSkuId = item.catRefId || item.skuId;
    const {quantity} = item;
    const addedProduct = getProduct(getState(), {productId: addedProductId});
    const {parentCategoryIdPath = ''} = addedProduct;
    const addedProductCategories = parentCategoryIdPath.split('>');
    const historyState = typeof history !== 'undefined' && history.state;
    const {actualCategoryPath} = historyState || {};
    const hasActualCategoryPath = actualCategoryPath !== undefined;

    const breadcrumb = getProductBreadcrumb(page, allCat, state);
    const addedSku = skusInCart?.find(sku => sku.repositoryId === addedSkuId);

    const {salePrice, listPrice} = addedSku;
    const productInformation = cartData.length
      ? cartData.find(prod => prod.productId === addedProduct.repositoryId)
      : [];
    const {productId = ''} = productInformation || {};

    const categoryPathNames =
      hasActualCategoryPath &&
      actualCategoryPath?.categoryPaths &&
      Array.isArray(actualCategoryPath?.categoryPaths) &&
      actualCategoryPath?.categoryPaths[0]?.split('/');

    const categoryPathIds =
      hasActualCategoryPath &&
      actualCategoryPath?.categoryIdPaths &&
      Array.isArray(actualCategoryPath?.categoryIdPaths) &&
      actualCategoryPath?.categoryIdPaths[0]?.split('>');

    const filteredCategoryNames = hasActualCategoryPath && categoryPathNames?.filter(cat => cat !== '' && cat !== 'C');
    const [, ...filteredCategoryIds] =
      hasActualCategoryPath && categoryPathIds && Array.isArray(categoryPathIds) && categoryPathIds.length > 2
        ? categoryPathIds
        : []; // Removes Home category Id

    const prodCat = getPropertyValue(hasActualCategoryPath, filteredCategoryIds, 0, addedProductCategories?.[1]);
    const prodCatName = getPropertyValue(hasActualCategoryPath, filteredCategoryNames, 1, allCat[prodCat]?.displayName);

    const subCatId = getPropertyValue(hasActualCategoryPath, filteredCategoryIds, 1, addedProductCategories?.[2]);
    const subName = getPropertyValue(hasActualCategoryPath, filteredCategoryNames, 2, allCat[subCatId]?.displayName);

    const subSubCat = getPropertyValue(hasActualCategoryPath, filteredCategoryIds, 2, addedProductCategories?.[3]);
    const subSubName = getPropertyValue(
      hasActualCategoryPath,
      filteredCategoryNames,
      3,
      allCat[subSubCat]?.displayName
    );

    const {cartLocation, findingMethod} = getCartLocation(pageType, bookId, locationAdded, prodCat);
    location = cartLocation;
    method = findingMethod;

    productCategory.push(prodCatName || '');
    productCategoryId.push(prodCat || '');
    productSubcategory.push(subName || '');
    productSubcategoryId.push(subCatId || '');
    productSubSubCategory.push(subSubName || '');
    productSubSubCategoryId.push(subSubCat || '');
    productPrice.push(salePrice ? salePrice.toString() : listPrice?.toString());
    productIds.push(productId || '');
    productBrand.push(addedProduct.brand || '');
    productQuantity.push(quantity.toString() || '');
    productBreadcrumb.push(breadcrumb || '');
    productParentSku.push(addedSku?.ltd_defaultPinCodeSku || '');
    productChildSku.push(addedSku?.ltd_defaultPinCodeSku || '');
    productChildSkuId.push(addedSku?.repositoryId || '');
    skuArticleIds.push(addedSku?.ltd_articleNumber?.toString() || '');
    skuUpc.push(addedSku?.ltd_upc || '');
    skuCode.push(addedSku?.ltd_skuCode || '');
    skuAvailability.push(addedSku?.ltd_ltdStockDescription || '');
    skuAttribute.push(addedSku?.x_skuConcat || '');

    try {
      const discount = addedSku.listPrice - addedSku.salePrice;
      const discounts = addedSku.salePrice && discount;

      const multiCategories = addedProduct?.parentCategoryIdPath.split('>');
      const subCatId = multiCategories[2] || '';
      const subSubCat = multiCategories[3] || '';
      const cartProdCatId = multiCategories[1] || '';
      const cartProdCatName = cartProdCatId ? allCat[cartProdCatId]?.displayName : '';
      const subCatName = subCatId ? allCat[subCatId]?.displayName : '';
      const subSubCatName = subSubCat ? allCat[subSubCat]?.displayName : '';

      const cartLocationName = allCat[cartLocation]?.displayName || '';
      const selectedPickerValues = `${addedSku?.ltd_pickerOneValue ? addedSku?.ltd_pickerOneValue : ''} ${
        addedSku?.ltd_pickerTwoValue ? addedSku?.ltd_pickerTwoValue : ''
      }`.trim();
      const productDisplayNameWithVariant = addedProduct?.displayName.includes(selectedPickerValues)
        ? addedProduct?.displayName
        : `${addedProduct?.displayName} ${selectedPickerValues}`.trim();

      const addToCartGTMEccomerceData = {
        event: 'add_to_cart',
        ecommerce: {
          currency: currencyCode,
          value: parseFloat(salePrice ? salePrice.toFixed(2) : listPrice?.toFixed(2)) * parseInt(originalData.quantity),
          items: [
            {
              item_id: addedSku?.ltd_articleNumber || '',
              item_name: productDisplayNameWithVariant || '',
              affiliation: site?.name || '',
              discount: Math.abs(parseFloat(discounts ? discounts.toFixed(2) : 0.0)),
              index,
              item_brand: addedProduct.brand || '',
              item_category: cartProdCatName || '',
              item_category2: subCatName || '',
              item_category3: subSubCatName || '',
              item_variant: addedSku?.x_skuConcat || '',
              price: parseFloat(listPrice.toFixed(2)),
              quantity: parseInt(originalData.quantity),
              item_list_id: cartLocationName ? cartLocation : cartProdCatId,
              item_list_name: cartLocationName || cartProdCatName
            }
          ]
        }
      };

      const sanitizedEvent = sanitizeDataToDataLayer(addToCartGTMEccomerceData);

      if (window?.dataLayer) {
        window.dataLayer.push({ecommerce: null});
        window.dataLayer.push({...sanitizedEvent});
      }
    } catch (err) {}
  });

  const utagData = {
    product_parent_sku: productParentSku,
    product_child_sku: productChildSku,
    product_child_skuid: productChildSkuId,
    product_category: productCategory,
    product_category_id: productCategoryId,
    product_subcategory: productSubcategory,
    product_subcategory_id: productSubcategoryId,
    product_sub_subcategory: productSubSubCategory,
    product_sub_subcategory_id: productSubSubCategoryId,
    product_price: productPrice,
    product_qty: productQuantity,
    product_id: productIds,
    product_brand: productBrand,
    product_breadcrumb: productBreadcrumb,
    sku_article_id: skuArticleIds,
    sku_upc: skuUpc,
    sku_code: skuCode,
    sku_availability: skuAvailability,
    sku_attribute: skuAttribute,
    finding_method: method,
    total_cart_count: numberOfItems.toString(),
    cart_value: cartValue?.toString() || '',
    cart_product_id: [],
    cart_product_child_sku: [],
    cart_product_child_skuid: [],
    cart_product_parent_sku: [],
    cart_product_qty: [],
    cart_product_price: [],
    cart_product_discount: [],
    cart_product_name: [],
    cart_product_category: [],
    cart_product_subcategory: [],
    cart_product_sub_subcategory: [],
    cart_product_subcategory_id: [],
    cart_product_sub_subcategory_id: [],
    cart_product_category_id: [],
    cart_sku_article_id: [],
    cart_sku_upc: [],
    cart_total: getTotalOrderValue(currentOrder, shippingConfig),
    cart_add_location: location,
    e_add_to_cart: 't',
    tw_event: 'cart_add'
  };

  if (locationAdded === CATALOG_QUICK_ORDER_LOCATION) {
    utagData.e_catalog_quick_order = 't';
  }

  for (let i = 0; i < productsInCart.length; i++) {
    const currentSku = skusInCart[i];
    if (currentSku.repositoryId.toLowerCase()?.includes('dummy')) {
      return;
    }

    const currentProduct = productsInCart[i];
    const {productId, displayName, quantity} = cartData.find(item => item.catRefId === currentSku.repositoryId);
    const multiCategories = currentProduct?.parentCategoryIdPath.split('>');
    const subCatId = multiCategories[2] || '';
    const subSubCat = multiCategories[3] || '';
    const cartProdCatId = multiCategories[1] || '';
    const cartProdCatName = cartProdCatId ? allCat[cartProdCatId]?.displayName : '';
    const subName = subCatId ? allCat[subCatId]?.displayName : '';
    const subSubName = subSubCat ? allCat[subSubCat]?.displayName : '';
    const discount = currentSku.listPrice - currentSku.salePrice;
    const discounts = currentSku.salePrice && discount;

    const {listPrice, salePrice} = currentSku;

    utagData.cart_product_id.push(productId || '');
    utagData.cart_product_child_sku.push(currentSku.ltd_defaultPinCodeSku || '');
    utagData.cart_product_child_skuid.push(currentSku.repositoryId || '');
    utagData.cart_product_parent_sku.push(currentSku.ltd_defaultPinCodeSku || '');
    utagData.cart_product_qty.push(quantity.toString() || '');
    utagData.cart_product_price.push(salePrice ? salePrice.toString() : listPrice.toString() || '');
    utagData.cart_product_discount.push(discounts ? discounts.toString() : '0');
    utagData.cart_product_name.push(displayName || '');
    utagData.cart_product_category.push(cartProdCatName || '');
    utagData.cart_product_subcategory.push(subName || '');
    utagData.cart_product_sub_subcategory.push(subSubName || '');
    utagData.cart_product_subcategory_id.push(subCatId || '');
    utagData.cart_product_sub_subcategory_id.push(subSubCat || '');
    utagData.cart_product_category_id.push(cartProdCatId || '');
    utagData.cart_sku_article_id.push(currentSku.ltd_articleNumber || '');
    utagData.cart_sku_upc.push(currentSku.ltd_upc || '');
  }

  if (validateDatalayerPayload(utagData) && typeof window !== 'undefined') {
    if (window?.dataLayer) {
      window.dataLayer.push({...utagData, event: 'addToCart'});
    }
  }
};
