export const incorrectPaymentMessageLTD =
  "We're unable to process the payment entered. Please verify your information, select another payment method or you can use chat or call us at 847-295-6058.";
export const incorrectPaymentMessageLS =
  "We're unable to process the payment entered. Please verify your information, select another payment method or you can use chat or call us at 847-444-3150.";
export const orderValidationErrorMessageLTD =
  "We're unable to process the payment entered. Please verify your information, select another payment method or you can use chat or call us at 847-295-6058.";
export const orderValidationErrorMessageLS =
  "We're unable to process the payment entered. Please verify your information, select another payment method or you can use chat or call us at 847-444-3150.";
export const textProfanity = 'Text cannot contain profanity';
export const textOutOfStock = 'Out of Stock';
export const textRequiredField = '*Required field';
export const textSelectAnItem = 'Select an Item';
export const textCheckConfirmation = '*Check Confirmation';
export const textAddToCart = 'Add to Cart';
