import React from 'react';

import { Icon } from '../Icon'
import { Flex } from '../Layout/Flex'

const Star = ({ value }) => {
  return (
    <Flex className="ltd-ui-flex">
      <div className="ltd-ui-star">
        <div className="rating-upper" style={{width: value}}>
          <Icon className="icon-star-full" />
          <Icon className="icon-star-full" />
          <Icon className="icon-star-full" />
          <Icon className="icon-star-full" />
          <Icon className="icon-star-full" />
        </div>
        <div className="rating-lower">
          <Icon className="icon-star-full" />
          <Icon className="icon-star-full" />
          <Icon className="icon-star-full" />
          <Icon className="icon-star-full" />
          <Icon className="icon-star-full" />
        </div>
      </div>
    </Flex>
  );
};

export { Star };