import React from 'react';

const Spinner = ({
  className,
  ...props
}) => {
  return (
    <div className={`ltd-ui-spinner ${className ? className : ''}`}></div>
  );
};

export { Spinner };