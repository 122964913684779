import React, {useState, useContext} from 'react';
import Alert from '@oracle-cx-commerce/react-components/alert';
import Styled from '@oracle-cx-commerce/react-components/styled';
import {StoreContext} from '@oracle-cx-commerce/react-ui/contexts';
import {Text, Flex, Input, Box, Button} from '../../../../ltd-ui';
import Form from '@oracle-cx-commerce/react-components/form';
import InputMask from 'react-input-mask';
import LTDPageLoader from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/page-loader';
import {getSite} from '@oracle-cx-commerce/commerce-utils/selector';
import {triggerEvent} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/data-tagging/events/common';
import {normalizeText} from '@oracle-cx-commerce/ltd-store/src/plugins/utils/analytics';
import {FOOTER_PREFIX} from '@oracle-cx-commerce/ltd-store/src/plugins/constants/analytics';

import {
  emailVerifyStatus
} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/common/functions/email-verify-status-check';

import css from './styles.css';

const FooterSignUp = props => {
  const {
    id,
    widgetTitle,
    fieldPlaceholder1,
    fieldButtonText1,
    fieldText1,
    fieldPlaceholder2,
    fieldButtonText2,
    fieldText2,
    emailSubmitSuccess,
    emailValidationText,
    phoneSubmitSuccess,
    phoneValidationText,
    phoneAlreadyExists
  } = props;

  const [alertMessage, setAlertMessage] = useState('');
  const [showPageLoader, setShowPageLoader] = useState(false);
  const store = useContext(StoreContext);
  const {action, getState} = store;

  const site = getSite(getState());

  // Email Submit Settings
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const validateEmail = () => {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = regexp.test(email);

    if (result) {
      setEmailError('');
    } else {
      setEmailError(emailValidationText);
    }

    return result;
  };

  const handleSubmitEmail = async e => {
    e.preventDefault(); // stops default reloading behaviour

    if (validateEmail() === true) {
      setShowPageLoader(true);
      const subscribed = await action('postListrakPhoneNumber', {
        event: 'email_verify',
        email,
        siteId: site.id || ''
      })
        .then(({delta: {listrakRepository}}) => {
          const {message, status} = listrakRepository;

          const emailStatus = emailVerifyStatus(message, status);
          emailStatus && window?.dataLayer?.push({event: 'e_footer_email_existing', email});
          return emailStatus;
        })
        .catch(() => {return false});

      try {
        // handle success
        setEmail('');
        const payloadEmail = email;
        const payload = {
          event: 'email_sign_up',
          email: payloadEmail,
          siteId: site.id || ''
        };

        setShowPageLoader(true);
        await action('postListrakPhoneNumber', payload).then(({delta: {listrakRepository}}) => {
          const {status} = listrakRepository;
  
          if (['200', '201'].includes(status)) {
            if(!subscribed){
              window?.dataLayer?.push({event: 'e_footer_email_success', email});
            }
            setAlertMessage(emailSubmitSuccess);      

            setTimeout(() => {
              setAlertMessage('');
            }, 5000);
          }
        }).catch(() => {});        
      } catch (e) {
        // handle error
      } finally {
        setShowPageLoader(false);
      }
    }
  };

  // SMS Submit Settings
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const validatePhone = () => {
    const newPhone = phone.replace(/[^a-zA-Z0-9 ]/g, '');
    const result = newPhone.length;

    if (result == '12') {
      setPhoneError('');
    } else {
      setPhoneError(phoneValidationText);
    }

    return result == '12';
  };

  const handleSubmitPhone = async e => {
    e.preventDefault();
    const sendPayload = async () => {
      const payload = {
        event: 'sms_subscribe',
        phoneNumber: phone,
        siteId: site.id || ''
      };

      const payloadSmsVerify = {
        event: 'sms_verify',
        phoneNumber: phone,
        siteId: site.id || ''
      };

      const triggerEventPayloads = {
        'Not Subscribed': {
          e_footer_sms_success: 't'
        },
        Unsubscribed: {
          e_footer_sms_existing: 't'
        },
        Subscribed: {
          e_footer_sms_existing: 't'
        }
      };

      const alreadyRecordStatusAndMessages = {
        Unsubscribed: {
          alert: phoneAlreadyExists
        },
        Subscribed: {
          alert: phoneAlreadyExists
        }
      };

      setShowPageLoader(true);

      const smsVerify = await action('postListrakPhoneNumber', payloadSmsVerify).catch(() => {});
      let smsVerifyMessage = '';

      let alreadyRegister;
      if (smsVerify && smsVerify.ok && smsVerify.delta && smsVerify.delta.listrakRepository) {
        smsVerifyMessage = smsVerify.delta.listrakRepository.message;
        triggerEvent(triggerEventPayloads[smsVerifyMessage]);
        alreadyRegister = alreadyRecordStatusAndMessages[smsVerifyMessage];
        if (alreadyRegister) {
          setAlertMessage(alreadyRegister.alert);
          setTimeout(() => {
            setAlertMessage('');
          }, 5000);
          setShowPageLoader(false);

          return;
        }
      }

      action('postListrakPhoneNumber', payload)
        .then(function (response) {
          setPhone('');
          if (response.ok) {
            setAlertMessage(phoneSubmitSuccess);
          }
          setTimeout(() => {
            setAlertMessage('');
          }, 5000);
        })
        .finally(() => setShowPageLoader(false));
    };

    if (validatePhone() === true) {
      sendPayload();
    }
  };

  const handleBlur = e => {
    if (!e.target.value) {
      e.target.classList.remove('filled');
    }
  };

  return (
    <Styled id="FooterSignUp" css={css}>
      <LTDPageLoader show={showPageLoader} />
      <div className={`FooterSignUp__Alert ${alertMessage ? 'active' : ''}`} onClick={() => setAlertMessage('')}>
        <Alert id="FooterSignUp__Alert" type="success" message={alertMessage} />
      </div>

      <Box className="ltd-ui-box FooterSignUp__Inner">
        <Text className="FooterSignUp__Title primaryFont pp1">
          {widgetTitle}
        </Text>

        <Form onSubmit={handleSubmitEmail}>
          <Flex className={`ltd-ui-flex form-group FooterSignUp__Flex ${emailError ? 'error' : ''}`}>
            <Input
              onBlur={handleBlur}
              className={`ltd-ui-input form-control ${email ? 'filled' : ''} FooterSignUp__Input`}
              type="text"
              name={`user_${id}`}
              value={email}
              onChange={e => setEmail(e.target.value)}
              id={`${FOOTER_PREFIX}${normalizeText('email-sign-up-input')}`}
            />
            <Text as="label" className="form-label" htmlFor={`${FOOTER_PREFIX}${normalizeText('email-sign-up-input')}`}>
              {fieldPlaceholder1}
            </Text>
            <Button
              id={`${FOOTER_PREFIX}${normalizeText('email-sign-up-button')}`}
              className="ltd-ui-button primary FooterSignUp__Button"
            >
              {fieldButtonText1}
            </Button>
          </Flex>

          {emailError && (
            <Text className="pp3 FooterSignUp__Error">
              {emailError}
            </Text>
          )}

          <Text className="pp3 FooterSignUp__FieldText">
            {fieldText1}
          </Text>
        </Form>

        <Form onSubmit={handleSubmitPhone}>
          <Flex className={`ltd-ui-flex form-group FooterSignUp__Flex ${phoneError ? 'error' : ''}`}>
            <InputMask
              onBlur={handleBlur}
              className={`form-control ${phone ? 'filled' : ''}`}
              w="60%"
              mb="0px"
              mask="(999) 999 9999"
              name={`phone_${id}`}
              value={phone}
              onChange={e => setPhone(e.target.value)}
              id={`${FOOTER_PREFIX}${normalizeText('sms-sign-up-input')}`}
            />
            <label className="form-label" htmlFor={`${FOOTER_PREFIX}${normalizeText('sms-sign-up-input')}`}>
              {fieldPlaceholder2}
            </label>
            <Button
              id={`${FOOTER_PREFIX}${normalizeText('sms-sign-up-button')}`}
              className="ltd-ui-button primary FooterSignUp__Button"
            >
              {fieldButtonText2}
            </Button>
          </Flex>

          {phoneError && (
            <Text className="pp3 FooterSignUp__Error">
              {phoneError}
            </Text>
          )}

          <Text className="pp3 FooterSignUp__FieldText">
            {fieldText2}
          </Text>
        </Form>
      </Box>
    </Styled>
  );
};

export default FooterSignUp;
