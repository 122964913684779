import React, {useCallback, useState, useEffect, useRef} from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';
import Popover from '@oracle-cx-commerce/react-components/popover';
import {triggerEvent} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/data-tagging/events/common';
import {normalizeText} from '@oracle-cx-commerce/ltd-store/src/plugins/utils/analytics';
import {HEADER_PREFIX} from '@oracle-cx-commerce/ltd-store/src/plugins/constants/analytics';
import {PAGE_FREE_CATALOG_LINK} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/commerce-utils/constants/page-links';

/*
 * Uncomment the following line to get the parameter substitution
 * function, e.g. t(someParameterizedResourceString, "someValue").
 */

import css from './styles.css';

const initialModalView = {
  show: false
};

const DropdownLinksCustom = props => {
  const [modalView, setModalView] = useState(initialModalView);

  const {dropdownTitle, dropdownTitleIcon, displayAndURL = '[]', linkBehavior = '_self'} = props;

  const links = JSON.parse(displayAndURL.replace(/'/g, '"'));

  const CreateLink = props => {
    const {href, children, id} = props;
    let linkProps;

    if (href) {
      if (href === PAGE_FREE_CATALOG_LINK) {
        linkProps = {...linkProps, onClick: () => triggerEvent({e_lead_form_init: 't'})};
      }

      linkProps = {...linkProps, href, title: children};
    }

    if (linkBehavior === '_self') {
      // return <Link {...linkProps}>{children}</Link>;
    }
    linkProps = {...linkProps, target: linkBehavior};

    return (
      <a {...linkProps} id={`${HEADER_PREFIX}${id}`}>
        {children}
      </a>
    );
  };

  const dropdownLinksCustom = useRef();

  const handleClick = useCallback(
    e => {
      if (modalView.show && !dropdownLinksCustom?.current.contains(e.target)) {
        setModalView({
          show: false
        });
      }
    },
    [modalView.show]
  );

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const onUserProfileClick = useCallback(() => {
    setModalView({
      show: modalView.show ? false : true
    });
  }, [modalView.show]);

  return (
    <Styled id="DropdownLinksCustom" css={css}>
      <div
        ref={dropdownLinksCustom}
        onKeyPress={onUserProfileClick}
        onClick={onUserProfileClick}
        className="dropdownLinksCustom__Container"
        role="button"
        tabIndex="0"
        id={`${HEADER_PREFIX}${normalizeText(dropdownTitle)}`}
      >
        <div className="dropdownLinksCustom__Button">
          {dropdownTitleIcon && <span className={`dropdownLinksCustom__Icon ${dropdownTitleIcon}`}></span>}

          <span className="dropdownLinksCustom__Title">{dropdownTitle}</span>
          <span className={`dropdownLinksCustom__Arrow icon-arrow-small-${modalView.show ? 'up' : 'down'}`}></span>
        </div>

        <div className="dropdownLinksCustom__Popover">
          <Popover show={modalView.show}>
            {links.map(item => (
              <div key={item['0']}>
                <CreateLink href={item['1']} id={normalizeText(props[item['0']] || item['0'])}>
                  {props[item['0']] || item['0']}
                </CreateLink>
              </div>
            ))}
          </Popover>
        </div>
      </div>
    </Styled>
  );
};

export default DropdownLinksCustom;
