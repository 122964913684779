import React, {useContext, useCallback, useState, useEffect} from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';
import {ProductContext, StoreContext} from '@oracle-cx-commerce/react-ui/contexts';
import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';
import {useSelector} from '@oracle-cx-commerce/react-components/provider';
import {getSkus} from '@oracle-cx-commerce/commerce-utils/selector';

import {Center} from '../../../../../../ltd-ui';
import Link from '../../../../../substitution/react-components/link';

import css from './styles.css';

const LTDProductCollectionLink = props => {
  const {product = {}, route = ''} = useContext(ProductContext);
  const store = useContext(StoreContext);
  const {action} = store;

  const allSkus = useSelector(getSkus);

  const [itemsToDisplay, setItemsToDisplay] = useState([]);
  const [onlyOneItemInsideTheCarousel, setOnlyOneItemInsideTheCarousel] = useState(true);

  const handleGetSkus = useCallback(
    async (validSkus, ids) => {
      let response = {};
      if (ids) {
        response = await action('listSkus', {skuIds: ids}).catch(() => {});
      }

      const {catalogRepository = {}} = response.delta || {};
      const {skus = {}} = catalogRepository;
      const newSelectedProducts = [];
      const newItemsToDisplay = [];

      const filteredSkus = {...validSkus, ...skus};

      !isEmptyObject(filteredSkus) &&
        Object.keys(filteredSkus).forEach(skuId => {
          const sku = filteredSkus[skuId];
          const {
            ltd_isDummySku = false,
            ltd_ltdStockStatus = '',
            repositoryId = '',
            parentProducts = [],
            salePrice = null,
            listPrice = 0,
            active
          } = sku;

          const product = !isEmptyObject(parentProducts) ? parentProducts[0] : {};

          if (!ltd_isDummySku && ltd_ltdStockStatus !== '11' && !isEmptyObject(product) && product?.active && active) {
            const newItem = {
              skuId,
              repositoryId,
              productId: product.id,
              price: salePrice ? salePrice : listPrice
            };
            newSelectedProducts.push(newItem);
            const unfilteredItemsToDisplay = {sku, product};
            const filteredSku = Object.values(unfilteredItemsToDisplay).filter(arr => arr.ltd_personalized === 'No');
            const filteredObjSku = filteredSku[0];
            const filteredItemsToDisplay = {filteredObjSku, product};
            newItemsToDisplay.push(filteredItemsToDisplay);
          }
        });

      if (!isEmptyObject(newItemsToDisplay) && !isEmptyObject(newSelectedProducts)) {
        setItemsToDisplay(newItemsToDisplay.reverse());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [action]
  );

  const cleanData = () => {
    setItemsToDisplay([]);
  };

  const getPdpSkus = useCallback(
    pdpProduct => {
      const {childSKUs = []} = pdpProduct;

      const validSkus = [];
      childSKUs.map(skuId => {
        validSkus[skuId] = {...allSkus[skuId], skuId, parentProducts: [pdpProduct]};
      });

      return validSkus;
    },
    [allSkus]
  );

  useEffect(() => {
    if (itemsToDisplay.length > 1) {
      setOnlyOneItemInsideTheCarousel(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsToDisplay]);

  useEffect(() => {
    const {ltd_frequentlyBoughtTogether = null, ltd_isCollection = 'No', active = ''} = product;
    let validSkus = [];
    let ids = [];
    if (!isEmptyObject(product)) {
      if (ltd_frequentlyBoughtTogether) {
        ids = ltd_frequentlyBoughtTogether.trim().split(/\s*,\s*/);
      }
      if (ltd_isCollection === 'Yes') {
        validSkus = getPdpSkus(product);
      }
    }

    if (ids && validSkus && (ltd_frequentlyBoughtTogether || ltd_isCollection === 'Yes') && active) {
      handleGetSkus(validSkus, ids);
    } else if (ltd_isCollection === 'No' || !ltd_frequentlyBoughtTogether) {
      cleanData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const {ltd_isCollection = 'No'} = product;
  const {linkText = 'View the Collection Below'} = props;

  return (
    <Styled id="LTDProductCollectionLink" css={css}>
      <Center className="ltd-ui-center">
        {ltd_isCollection === 'Yes' && !onlyOneItemInsideTheCarousel && (
          <Link href={`/${route}#buycollection`}>
            <button type="button" className="LTDProductCollectionLink">
              {linkText}
            </button>
          </Link>
        )}
      </Center>
    </Styled>
  );
};

export default LTDProductCollectionLink;
