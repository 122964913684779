import {getGlobalContext, getSkus} from '@oracle-cx-commerce/commerce-utils/selector';
import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';
import {useEffect} from 'react';
import {getCollectionsMenu} from '@oracle-cx-commerce/ltd-store/src/plugins/selectors';
import {fetchMenuCategories, fetchSkus} from './fetcher';

export const useMenuCategoriesFetcher = (store, widgetConfig) => {
  const {numberOfChildCategoriesToDisplay = 3, hideCategoryIfOutOfStock = false} = widgetConfig;

  return useEffect(() => {
    const {categories} = getGlobalContext(store.getState());
    const collectionMenu = getCollectionsMenu(store.getState());

    if (isEmptyObject(categories) || isEmptyObject(collectionMenu)) {
      fetchMenuCategories(store, {numberOfChildCategoriesToDisplay, hideCategoryIfOutOfStock});
    }
  }, [store, numberOfChildCategoriesToDisplay, hideCategoryIfOutOfStock]);
};

export const useGetSkusFetcher = (store, commerceItems) => {
  return useEffect(() => {
    const skus = getSkus(store.getState());

    if (!isEmptyObject(commerceItems) && isEmptyObject(skus)) {
      const skuIds = [];
      const productIds = [];

      Object.values(commerceItems).forEach(sku => {
        skuIds.push(sku.catRefId);
        productIds.push(sku.productId);
      });

      fetchSkus(store, skuIds, productIds);
    }
  }, [commerceItems, store]);
};
