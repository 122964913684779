import {DateTime} from 'luxon';
import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';

export const verifyDateTime = (start, end) => {
  if (start && end) {
    const currentDate = DateTime.now().setZone('America/Chicago').toMillis();

    const startDate = DateTime.fromISO(start, {zone: 'America/Chicago'}).toMillis();
    const endDate = DateTime.fromISO(end, {zone: 'America/Chicago'}).toMillis();

    return currentDate >= startDate && currentDate <= endDate;
  }

  return false;
};

export const verifyCategory = (parentCategoriesIds, categories = [], categoryId, includeCategories) => {
  const foundCategory = categories?.find(cat => parentCategoriesIds?.includes(cat));

  if (!includeCategories) {
    return !foundCategory;
  }

  return Boolean(foundCategory);
};

const checkFilterProperty = (sku, property) => {
  return sku[property]?.includes('Yes');
};

export const checkProductExclusion = (excludedProducts, id) => {
  const product = excludedProducts?.find(item => item === id);

  return !product;
};

export const checkFilterProperties = (sku, filterProps = {}) => {
  const {ASOTVCheck, saleCheck, personalizedCheck, onlineOnlyCheck, newArrivalCheck, exclusiveCheck} = filterProps;

  const objectChecks = [
    {
      property: 'salePrice',
      checkProperty: Boolean(sku['salePrice']),
      checked: saleCheck
    },
    {
      property: 'ltd_isOnlineOnly',
      checkProperty: checkFilterProperty(sku, 'ltd_isOnlineOnly'),
      checked: onlineOnlyCheck
    },
    {
      property: 'ltd_personalized',
      checkProperty: checkFilterProperty(sku, 'ltd_personalized'),
      checked: personalizedCheck
    },
    {
      property: 'ltd_isExclusive',
      checkProperty: checkFilterProperty(sku, 'ltd_isExclusive'),
      checked: exclusiveCheck
    },
    {
      property: 'ltd_isAsSeenOnTV',
      checkProperty: checkFilterProperty(sku, 'ltd_isAsSeenOnTV'),
      checked: ASOTVCheck
    },
    {
      property: 'ltd_newArrival',
      checkProperty: checkFilterProperty(sku, 'ltd_newArrival'),
      checked: newArrivalCheck
    }
  ];

  const typesOfProduct = objectChecks.filter(({checkProperty}) => checkProperty);

  let isValidToShow = true;
  if (typesOfProduct.length > 0) {
    const isSomePropertyUnchecked = typesOfProduct.some(({checked}) => !checked);
    if (isSomePropertyUnchecked) isValidToShow = false;
  }

  return isValidToShow;
};

export const deepSearchByKey = (object, originalKey, matches = []) => {
  if (object != null) {
    if (Array.isArray(object)) {
      for (const arrayItem of object) {
        deepSearchByKey(arrayItem, originalKey, matches);
      }
    } else if (typeof object == 'object') {
      for (const key of Object.keys(object)) {
        if (key === originalKey) {
          matches.push({[originalKey]: object[originalKey]});
        } else {
          deepSearchByKey(object[key], originalKey, matches);
        }
      }
    }
  }

  return matches;
};

const hasFixedCategories = parentCategories => {
  return parentCategories.some(({fixedParentCategories}) => !isEmptyObject(fixedParentCategories));
};

export const handleParentCategories = (parentCategories, categoryIdPath = '') => {
  if (hasFixedCategories(parentCategories)) {
    const repositoryIds = deepSearchByKey(parentCategories, 'repositoryId');
    const parentCategoriesIds = repositoryIds.map(({repositoryId}) => repositoryId);

    return parentCategoriesIds;
  }

  if (!categoryIdPath && !parentCategories.length) {
    return [];
  }

  const categoriesIdsPathArray = categoryIdPath.split('>');
  const parentCategoriesIds = parentCategories.map(({repositoryId}) => repositoryId);

  return Array.from(new Set([...categoriesIdsPathArray, ...parentCategoriesIds]));
};
