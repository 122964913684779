import {
    VERIFY_STATUS_SUBSCRIBED,
    VERIFY_UNSUBSCRIBED_STATUS,
    NOT_SUBSCRIBED,
    UNSUBSCRIBED,
    NO_RECORD,
    UNABLE_TO_LOCATE,
    SUBSCRIBED,
} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/commerce-utils/constants';

export const emailVerifyStatus = (message, status) => {
    if ([VERIFY_UNSUBSCRIBED_STATUS].includes(status)) {
        if([NOT_SUBSCRIBED, UNSUBSCRIBED,NO_RECORD, UNABLE_TO_LOCATE].includes(message?.toLowerCase())){
            return false;
        }
    } else if (status === VERIFY_STATUS_SUBSCRIBED) {
        if([SUBSCRIBED].includes(message?.toLowerCase())){
            return true;
        }        
    }
    return false;
}

export const emailSignUpStatusResponse = (status) => {
    if ([VERIFY_UNSUBSCRIBED_STATUS].includes(status)) {
        return false;
    } else if (status === VERIFY_STATUS_SUBSCRIBED) {
        return true;
    }
    return false;
}

export const emailSignUpMessageResponse = (message) => {
    if([NOT_SUBSCRIBED, UNSUBSCRIBED,NO_RECORD, UNABLE_TO_LOCATE].includes(message?.toLowerCase())){
        return false;
    }
    else if([SUBSCRIBED].includes(message?.toLowerCase())){
        return true;
    }
    return false;
}