import React, {useEffect, useState} from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';
import {useNavigator} from '@oracle-cx-commerce/react-components/link';
import {Star, Text} from '../../../../../../ltd-ui';
import {noop} from '@oracle-cx-commerce/utils/generic';

import css from './styles.css';

const ProductReviewStars = props => {
  const {
    product: {
      ltd_averageRating: productAverageRating,
      ltd_ratingRange: productRatingRange,
      ltd_reviewCount: productReviewCount,
      ltd_showRR: productShowRR,
      route = ''
    },
    onClose = noop
  } = props;

  const [starsPercentage, setStarsPercentage] = useState('0%');
  const [reviewCount, setReviewCount] = useState('');

  const getPercentage = (partialValue, totalValue) => {
    return (100 * partialValue) / totalValue;
  };

  useEffect(() => {
    if (productAverageRating && productRatingRange) {
      const percentage = getPercentage(productAverageRating, productRatingRange);
      setStarsPercentage(`${percentage.toString()}%`);
    }
    if (productReviewCount && productReviewCount > 0) {
      setReviewCount(`(${parseInt(productReviewCount)})`);
    }
  }, [productAverageRating, productRatingRange, productReviewCount]);

  const goToPage = useNavigator();

  return (
    <Styled id="ReviewStars" css={css}>
      {productShowRR === 'Yes' && (
        <div className="ReviewStars__container">
          <button
            type="button"
            title="Review Stars"
            role={'link'}
            onClick={() => {
              onClose();
              goToPage(`${route}#readReviews`);
            }}
            className="ReviewStars__link"
          >
            <Star value={starsPercentage} />
          </button>
          <div
            className={`ReviewStars__count ${
              reviewCount ? 'ReviewStars__count--visible' : 'ReviewStars__count--hidden'
            }`}
          >
            <Text className="ReviewStars__Text primary-font pp3">
              {reviewCount}
            </Text>
          </div>
        </div>
      )}
    </Styled>
  );
};

export default ProductReviewStars;
