import {
  deleteAppliedPaymentsByTypes,
  deleteAppliedPaymentsByIds,
  isPaymentDetailsComplete
} from '@oracle-cx-commerce/react-components/utils/payment';
import {isEmptyObject, noop} from '@oracle-cx-commerce/utils/generic';
import {loadShippingMethodsAndSetDefaultForSG} from '../../../utils/address';
import {
  PAYMENT_STATE_INITIAL,
  PAYMENT_TYPE_PAY_IN_STORE,
  PAYMENT_TYPE_STORECREDIT,
  PAYMENT_TYPE_GIFTCARD,
  PAYMENT_TYPE_LOYALTYPOINTS,
  PAGE_CHECKOUT_REVIEW_ORDER_LINK
} from '../../../commerce-utils/constants';

const expressCheckoutReviewOrder = (
  currentOrder,
  contactInfos,
  defaultShippingAddressId,
  shippingAddresses,
  goToPage = noop,
  closeMiniCart = noop,
  action,
  store
) => {
  const {shippingGroups = {}, paymentGroups = {}} = currentOrder;
  const shippingGroup = Object.values(shippingGroups || {})[0] || {};

  const {shippingGroupId} = shippingGroup;

  const payments = Object.values(paymentGroups || {})[0] || {};

  const compatiblePaymentTypes = [PAYMENT_TYPE_GIFTCARD, PAYMENT_TYPE_LOYALTYPOINTS, PAYMENT_TYPE_STORECREDIT];

  const {paymentGroupId, ...paymentDetails} = payments;

  closeMiniCart();

  const applyPayments = paymentsToApply => {
    const paymentsToApplyWithoutSavingCard = paymentsToApply.map(paymentToApply => {
      if (paymentToApply.type === 'card') {
        const paymentToApplyWithoutSavingCard = {...paymentToApply, saveCard: false};

        return paymentToApplyWithoutSavingCard;
      }

      return paymentToApply;
    });

    if (paymentsToApply.length > 0) {
      action('applyPayments', {items: paymentsToApplyWithoutSavingCard})
        .then(response => {
          if (response.ok) {
            const order = currentOrder;
            // If entered payment details is complete, navigate to the review order page
            if (isPaymentDetailsComplete(order)) {
              goToPage(PAGE_CHECKOUT_REVIEW_ORDER_LINK);
            }
          }
        })
        .catch(() => {});
    } else {
      goToPage(PAGE_CHECKOUT_REVIEW_ORDER_LINK);
    }
  };

  /**
   * This method removes applied payment groups from order which are not applicable
   * @param payments {Array} The payments(from payment context) to be processed
   */
  const removeNotApplicablePaymentGroups = async payments => {
    let isError = false;
    if (payments.some(payment => payment.type === PAYMENT_TYPE_PAY_IN_STORE)) {
      //delete all payments as we are about to add in store payment and there is already non in store payment applied
      if (Object.values(paymentGroups).some(pGroup => pGroup.paymentMethod !== PAYMENT_TYPE_PAY_IN_STORE)) {
        const response = await deleteAppliedPaymentsByTypes(store);
        if (!response.ok) {
          isError = true;
        }
      }
    } else {
      //get payment group ids to be deleted
      const paymentGroupsToRemoved = Object.values(paymentGroups)
        .filter(
          pGroup =>
            pGroup.paymentState === PAYMENT_STATE_INITIAL &&
            !compatiblePaymentTypes?.includes(pGroup.paymentMethod) &&
            !payments.some(payment => payment.paymentGroupId === pGroup.paymentGroupId)
        )
        .map(pGroup => pGroup.paymentGroupId);

      if (paymentGroupsToRemoved.length) {
        try {
          const response = await deleteAppliedPaymentsByIds(action, paymentGroupsToRemoved);
          if (!response.ok) {
            isError = true;
          }
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
    }

    return isError;
  };

  /**
   * Processes the payments in the context
   * Updates the payment group if the payment in the context has an paymentGroupId
   * or calls the apply payments to apply the payment in the context.
   * @param payments Array The payments to be processed
   */
  const processPayments = async payments => {
    const paymentsToApply = [];
    let isError = false;
    isError = await removeNotApplicablePaymentGroups(payments);
    if (isError) {
      return;
    }
    for (const payment of payments) {
      const {paymentGroupId, ...paymentDetails} = payment;
      const existingPaymentGroup = paymentGroups[paymentGroupId];
      if (paymentGroupId && existingPaymentGroup) {
        // Remove existing applied credit card payment group and reapply if
        // a different saved card has been selected, or
        // selection has been changed from saved card to a newly entered card(not saved) or
        // selection has been changed from newly entered card(not saved) to a saved card
        if (
          (payment.savedCardId &&
            existingPaymentGroup.savedCardId &&
            payment.savedCardId !== existingPaymentGroup.savedCardId) ||
          (!existingPaymentGroup.savedCardId && payment.savedCardId) ||
          (existingPaymentGroup.savedCardId && !payment.savedCardId)
        ) {
          const response = await action('deleteAppliedPayment', {paymentGroupId}).catch(() => {});
          if (response.ok) {
            paymentsToApply.push(paymentDetails);
          } else {
            isError = true;
            break;
          }
        } else {
          // If there is a payment group with saved card id , only the cvv can be updated
          // Type and seqNum properties are not required for updating an existing payment group
          const {savedCardId, type, seqNum, ...paymentDetailsToUpdate} = paymentDetails;
          // If there is addressType property in billingAddress it can't be patched.
          if (paymentDetailsToUpdate.billingAddress) {
            const {
              billingAddress: {addressType, ...billingAddressDetails}
            } = paymentDetailsToUpdate;
            paymentDetailsToUpdate.billingAddress = billingAddressDetails;
          }
          if (!isEmptyObject(paymentDetailsToUpdate)) {
            const paymentGroupToUpdate = {paymentGroupId, ...paymentDetailsToUpdate};
            const updateAppliedPaymentResponse = await action('updateAppliedPayment', paymentGroupToUpdate).catch(
              () => {}
            );
            if (!updateAppliedPaymentResponse.ok) {
              isError = true;

              break;
            }
          }
        }
      } else {
        paymentsToApply.push(paymentDetails);
      }
    }

    if (!isError) {
      applyPayments(paymentsToApply);
    }
  };

  const expressCheckoutLink = async () => {
    sessionStorage.setItem('typeOfPayment', 'card');
    const profileSavedCards = await action('listProfileSavedCards').catch(() => {});

    const {delta} = profileSavedCards;

    const cards = Object.values(delta.profileRepository.savedCards || {})[0] || {};

    const {savedCardsMap} = cards;

    const savedCards = Object.values(savedCardsMap || {}) || {};

    let defaultCard;

    for (const savedCard of savedCards) {
      if (savedCard.isDefault) defaultCard = savedCard;
    }

    const payloadApplyPayments = [
      {
        cardCVV: '999',
        customProperties: {nameOnCard: defaultCard.nameOnCard, merchantId: 'FDMSN_LTD'},
        saveCard: false,
        savedCardId: defaultCard.savedCardId,
        seqNum: 0,
        type: 'card'
      }
    ];

    let defaultAddress;
    if (contactInfos) {
      if (defaultShippingAddressId) {
        defaultAddress = contactInfos[defaultShippingAddressId];
      } else if (shippingAddresses && shippingAddresses.length > 0) {
        const firstShippingAddress = shippingAddresses[0];
        defaultAddress = contactInfos[firstShippingAddress];
      }
    }

    await action('updateCartShippingGroup', {
      shippingAddress: defaultAddress,
      type: 'hardgoodShippingGroup',
      shippingGroupId
    })
      .then(response => {
        if (response.ok) {
          loadShippingMethodsAndSetDefaultForSG(store, shippingGroup, () => {});
        }
      })
      .catch(() => {});

    processPayments(payloadApplyPayments);
  };

  expressCheckoutLink();
};

export default expressCheckoutReviewOrder;
