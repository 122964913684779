import React from 'react';
import {Flex, Icon, Text} from '../../../../ltd-ui';
import {toast} from 'react-toastify';
import {setToastTypeColors, getAppropriatelyIcon, CloseToastButton} from './toast-utils';

export const displayToast = (displayedMessage, toastType) => {
  toast[toastType](
    <>
      <Flex className="ltd-ui-flex align-center">
        <Icon
          style={{color: setToastTypeColors(toastType)}}
          className={`${getAppropriatelyIcon(toastType)} display-toast`}
        />
        <Text className="toast">{displayedMessage}</Text>
      </Flex>
    </>,
    {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: CloseToastButton
    }
  );
};
