import {getCollectionsMenu} from '@oracle-cx-commerce/ltd-store/src/plugins/selectors';

export const fetchMenuCategories = async (store, widgetConfig) => {
  const {numberOfChildCategoriesToDisplay = 3, hideCategoryIfOutOfStock = false} = widgetConfig;

  const response = await store.endpoint('getCollectionMenuCustom', {
    categoryId: 'rootCategory',
    filterKey: 'categoryNavDataCustom',
    expandChildren: true,
    maxLevel: numberOfChildCategoriesToDisplay,
    disableActiveProdCheck: hideCategoryIfOutOfStock
  });

  if (response.ok) {
    const categories = getCollectionsMenu(response.delta);

    const allCategoryIds = Object.keys(categories || {}).filter(
      categoryId => !['ltd_catalogs', 'ls_catalogs'].includes(categoryId)
    );

    if (allCategoryIds?.length) {
      await store.endpoint('listCollections', {
        categoryIds: allCategoryIds,
        filterKey: 'categoryListNavDataCustom'
      });
    }
  }
};

export const fetchSkus = async (store, skuIds = [], productIds = []) => {
  await Promise.all([store.endpoint('listSkus', {skuIds}), store.endpoint('listProducts', {productIds})]);
};
