import {getSite, isAuthenticated, getCurrentProfileId} from '@oracle-cx-commerce/commerce-utils/selector';
/**
 * Returns data required by the component by invoking selectors
 *
 * @param  {String} widgetId Widget Id
 * @param  {String} productId Product Id
 * @return {Object} Object
 */
export const getComponentData = state => {
  const site = getSite(state);
  const isUserLoggedIn = isAuthenticated(state) && getCurrentProfileId(state) !== 'anonymous';

  return {
    site,
    isUserLoggedIn
  };
};
