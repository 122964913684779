/* eslint-disable react/no-danger */
import React, {Fragment, useContext} from 'react';
import {ProductContext} from '@oracle-cx-commerce/react-ui/contexts';
import Styled from '@oracle-cx-commerce/react-components/styled';
import css from './styles.css';

const LTDProductLegalLines = () => {
  const {product} = useContext(ProductContext);
  const {ltd_legalLines} = product;

  return ltd_legalLines ? (
    <Styled id="LTDProductLegalLines" css={css}>
      {ltd_legalLines && <div className="LTDProductLegalLines" dangerouslySetInnerHTML={{__html: ltd_legalLines}} />}
    </Styled>
  ) : <Fragment/>;
};
export default LTDProductLegalLines;
