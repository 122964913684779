export const getAvailableOptions = picker => {
  const {options} = picker;

  return options.filter(({disabled}) => !disabled);
};

export const getOptionByName = (options, optionName) => {
  return options.find(({name}) => name === optionName);
};

export const getOptionByValue = (options, optionValue) => {
  return options.find(({value}) => value === optionValue);
};

export const updateVariantOptions = (variantOptions, selectedOption) => {
  const {name, value} = selectedOption;

  Object.entries(variantOptions).forEach(([, variantOption]) => {
    variantOption.selectedName = name;
    variantOption.selectedValue = value;
  });

  return variantOptions;
};

export const createMultiplePickers = (pickers, firstOption = {name: "", value: ""},
    secondOption = {name: "", value: ""}, secondValidCombinations = []) => {

  const {name: firstNameOption, value: firstValueOption} = firstOption;
  const {name: secondNameOption, value: secondValueOption} = secondOption;

  const secondVariantValues = secondValidCombinations.map(({name}) => name.split('#')[1]);
  const secondPickerOptions = pickers[1].options.map(option => {
    if (!secondVariantValues.includes(option.value)) {
      option.disabled = true;
    }

    return option;
  });

  const newPickers = [
    {
      ...pickers[0],
      selectedName: firstNameOption,
      selectedValue: firstValueOption
    },
    {
      ...pickers[1],
      options: secondPickerOptions,
      selectedName: secondNameOption,
      selectedValue: secondValueOption
    }
  ];

  return newPickers;
};
