const handleGTMEvents = payload => {
  const eventNames = Object.keys(payload);

  return eventNames;
};

const pushGTMEvents = events => {
  if (window?.dataLayer) {
    events.forEach(event => {
      window.dataLayer.push({event});
    });
  }
};

export const triggerEvent = properties => {
  const utagData = {
    ...properties
  };

  if (typeof window !== 'undefined') {
    const GTMEvents = handleGTMEvents(utagData);
    pushGTMEvents(GTMEvents);
  }
};
