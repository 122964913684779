import {
  getCurrentPageId,
  getProducts,
  getSkuInventoryItems,
  getSkuPrices,
  getSkus
} from '@oracle-cx-commerce/commerce-utils/selector';

export const getComponentData = state => {
  const products = getProducts(state);
  const skus = getSkus(state);
  const skusPrices = getSkuPrices(state);
  const skuInventoryItems = getSkuInventoryItems(state);
  const pageId = getCurrentPageId(state);

  return {
    products,
    skus,
    skusPrices,
    skuInventoryItems,
    pageId
  };
};
