import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';
import {useContext, useEffect, useState} from 'react';
import {ProductContext, ContainerContext, StoreContext} from '@oracle-cx-commerce/react-ui/contexts';
import {getSkus, getGlobalContext, getSku} from '@oracle-cx-commerce/commerce-utils/selector';
import {getImageDisplayPM} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/utils/functions/validation-img';

const OUT_OF_STOCK = '11';
const NO_IMAGE = '/img/no-image.jpg';
const IMAGE_PERSONALIZE = '_pm';
export const useComponentData = () => {
  const {selections} = useContext(ContainerContext);
  const {getState} = useContext(StoreContext);
  const {isPreview} = getGlobalContext(getState());
  const {variantOptions = {}, skuId = null} = selections;
  const {ltd_skuVideo = null} = getSku(getState(), {skuId});
  const {product} = useContext(ProductContext);
  const {
    listingVariantKeyId = null,
    listingVariantValues = {},
    mediumImageURLs = [],
    primaryImageTitle,
    thumbImageURLs = [],
    variantToSkuLookup = {},
    ltd_productVideo,
    childSKUs = []
  } = product;
  const [activeImages, setActiveImages] = useState([]);
  const [thumbImages, setThumbImages] = useState([]);
  let selectedSkuImage = null;

  const allSkus = getSkus(getState());
  const skus = childSKUs.map(skuId => allSkus[skuId]);

  const filterNoImage = images => {
    const noImage = '/img/no-image.jpg';
    const filteredImages = images.filter(image => image !== noImage);

    return filteredImages.length ? filteredImages : [noImage];
  };

  const getImagePersonalize = images => {
    const result = [];
    images?.forEach(image => {
      if (image?.includes(IMAGE_PERSONALIZE)) {
        result.push(image);
      }
    });

    return result[0];
  };

  const imagePersonalizeMedium = getImagePersonalize(mediumImageURLs);
  const imagePersonalizeThumb = getImagePersonalize(thumbImageURLs);

  useEffect(() => {
    if (!childSKUs?.length) return;
    const skuMediumImages = [];
    const skuThumbImages = [];
    const productOnlyMediumImages = [];
    const productOnlyThumbImages = [];
    const invalidImages = [];

    const today = new Date();
    skus.sort((a, b) => a?.variantValuesOrder?.x_skuConcat - b?.variantValuesOrder?.x_skuConcat);
    skus.forEach(sku => {
      const {mediumImageURLs, thumbImageURLs, ltd_ltdStockStatus, ltd_isDummySku, ltd_startDateSku, ltd_endDateSku} =
        sku;
      if ((!isPreview && ltd_ltdStockStatus === OUT_OF_STOCK) || ltd_isDummySku) return;
      if ((!isPreview && today < new Date(ltd_startDateSku)) || today > new Date(ltd_endDateSku)) {
        invalidImages.push(...mediumImageURLs);
      }
      skuMediumImages.push(...filterNoImage(mediumImageURLs));
      skuThumbImages.push(...filterNoImage(thumbImageURLs));
    });

    mediumImageURLs?.forEach((image, index) => {
      if (image !== NO_IMAGE && !skuMediumImages?.includes(image) && !image?.includes(IMAGE_PERSONALIZE)) {
        productOnlyMediumImages.push(image);
        productOnlyThumbImages.push(thumbImageURLs[index]);
      }
    });
    invalidImages.forEach(invalidImage => {
      const index = skuMediumImages.lastIndexOf(invalidImage);
      if (index > -1) {
        skuMediumImages.splice(index, 1);
        skuThumbImages.splice(index, 1);
      }
    });
    if (imagePersonalizeMedium) {
      setActiveImages([...productOnlyMediumImages, ...skuMediumImages, imagePersonalizeMedium]);
      setThumbImages([...productOnlyThumbImages, ...skuThumbImages, imagePersonalizeThumb]);

      return;
    }
    setActiveImages([...productOnlyMediumImages, ...skuMediumImages]);
    setThumbImages([...productOnlyThumbImages, ...skuThumbImages]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childSKUs]);

  if (
    !isEmptyObject(variantOptions) &&
    listingVariantValues &&
    !isEmptyObject(listingVariantValues) &&
    Object.keys(listingVariantValues).length > 1
  ) {
    const listingVariant = variantOptions[listingVariantKeyId] || {};
    const {selectedValue = null} = listingVariant;
    if (selectedValue !== null && selectedValue !== undefined) {
      const key = `${listingVariantKeyId}=${selectedValue}`;
      if (listingVariantValues[key]) {
        [selectedSkuImage] = filterNoImage(listingVariantValues[key].thumbImageURLs);
      }
    }
  }

  const filterActiveImages = activeImages.filter(url => getImageDisplayPM(url));
  const filterThumbImages = thumbImages.filter(url => getImageDisplayPM(url));

  return {
    primaryImageTitle,
    activeImages: filterActiveImages,
    thumbImages: filterThumbImages,
    listingVariantValues,
    variantToSkuLookup,
    selectedSkuImage,
    ltd_productVideo,
    ltd_skuVideo,
    childSKUsDetails: skus
  };
};
