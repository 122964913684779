import React, {useEffect, useMemo, useState, useRef} from 'react';
import Region from '@oracle-cx-commerce/react-components/region';
import Styled from '@oracle-cx-commerce/react-components/styled';

export const useIsInViewport = ref => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isInViewport, setIsInViewport] = useState(false);

  const observer = useMemo(
    () =>
      typeof IntersectionObserver !== 'undefined'
        ? new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting))
        : null,
    [typeof IntersectionObserver]
  );

  useEffect(() => {
    if (ref?.current && observer) {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [ref, observer]);

  useEffect(() => {
    if (isIntersecting && !isInViewport) setIsInViewport(true);
  }, [isIntersecting, isInViewport]);

  return isInViewport;
};

const LTDLazyLoadingContainer = props => {
  const {regions = [], className = ''} = props;
  const sectionRef = useRef();

  const widgetIsInViewport = useIsInViewport(sectionRef);

  return (
    <Styled id="Container">
      <section ref={sectionRef} className={`Container__Section ${className}`.trim()}>
        {widgetIsInViewport &&
          regions.map((regionId, index) => (
            <Region key={index} regionId={regionId} /> // eslint-disable-line react/no-array-index-key
          ))}
      </section>
    </Styled>
  );
};

export default LTDLazyLoadingContainer;
