export function phone(e) {
  // (999) 999-9999
  e.currentTarget.maxLength = 14;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "")
  .replace(/(\d{3})(\d)/, "($1) $2")
  .replace(/(\d{3})(\d)/, "$1-$2")
  .replace(/(-\d{4})(\d+?)$/, "$1");
  e.currentTarget.value = value;
  return e;
}

export function pinCode(e) {
  // 999999-9999-999
  e.currentTarget.maxLength = 15;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{6})(\d)/g, "$1-$2");
  value = value.replace(/(\d)(\d{3})$/, "$1-$2");
  e.currentTarget.value = value;
  return e;
}

export function date(e) {
  // 99/99/9999
  e.currentTarget.maxLength = 10;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "")
  .replace(/(\d{2})(\d)/, "$1/$2")
  .replace(/(\d{2})(\d)/, "$1/$2")
  .replace(/(\d{4})(\d)/, "$1");
  e.currentTarget.value = value;
  return e;
}