import React, {useEffect, useState} from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';

import InfoIcon from '@oracle-cx-commerce/react-components/icons/info';

import css from './styles.css';

const TickerItem = ({promotionMessage, expirationMessage, infoMessage, color, textColor, linkItem}) => {
  return (
    <>
      {promotionMessage && linkItem && (
        <a href={linkItem}>
          <div className="LTDTicker__item" style={{backgroundColor: color, color: textColor}}>
            <span className="LTDTicker__item--bold" style={{backgroundColor: color, color: textColor}}>
              {promotionMessage}
            </span>
            {expirationMessage && <span> — Expires {expirationMessage}</span>}
            {infoMessage && (
              <div className="LTDTicker__tooltip">
                <span className="LTDTicker__tooltip--onHover">
                  <InfoIcon className="LTDTicker__icon" />
                </span>
                <span className="LTDTicker__tooltip--message">{infoMessage}</span>
              </div>
            )}
          </div>
        </a>
      )}
      {promotionMessage && linkItem == '' && (
        <div className="LTDTicker__item" style={{backgroundColor: color, color: textColor}}>
          <span className="LTDTicker__item--bold" style={{backgroundColor: color, color: textColor}}>
            {promotionMessage}
          </span>
          {expirationMessage && <span> — Expires {expirationMessage}</span>}
          {infoMessage && (
            <div className="LTDTicker__tooltip">
              <span className="LTDTicker__tooltip--onHover">
                <InfoIcon className="LTDTicker__icon" />
              </span>
              <span className="LTDTicker__tooltip--message">{infoMessage}</span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const BannerVerticalRotation = props => {
  const {
    configBannerColor,
    configTextColor,
    configBannerPromotionMessage1,
    configBannerPromotionMessage2,
    configBannerPromotionMessage3,
    configExpirationPromotionMessage1,
    configExpirationPromotionMessage2,
    configExpirationPromotionMessage3,
    configInfoMessage1,
    configInfoMessage2,
    configInfoMessage3,
    configLinkPromotionMessage1 = '',
    configLinkPromotionMessage2 = '',
    configLinkPromotionMessage3 = ''
  } = props;

  const messages = [
    {
      configBannerPromotionMessage: configBannerPromotionMessage1,
      configExpirationPromotionMessage: configExpirationPromotionMessage1,
      configInfoMessage: configInfoMessage1,
      linkPromotion: configLinkPromotionMessage1
    },
    {
      configBannerPromotionMessage: configBannerPromotionMessage2,
      configExpirationPromotionMessage: configExpirationPromotionMessage2,
      configInfoMessage: configInfoMessage2,
      linkPromotion: configLinkPromotionMessage2
    },
    {
      configBannerPromotionMessage: configBannerPromotionMessage3,
      configExpirationPromotionMessage: configExpirationPromotionMessage3,
      configInfoMessage: configInfoMessage3,
      linkPromotion: configLinkPromotionMessage3
    }
  ];

  const [current, setCurrent] = useState(0);
  let total = 0;
  let timeoutHandle;

  if (configInfoMessage1) {
    total++;
    if (configInfoMessage2) {
      total++;
      if (configInfoMessage3) {
        total++;
      }
    }
  }

  const timer = (current, total) => {
    timeoutHandle = setTimeout(() => {
      if (current + 1 === total) {
        setCurrent(0);
      } else {
        setCurrent(current + 1);
      }
    }, 5000);

    return timeoutHandle;
  };

  useEffect(() => {
    const clear = timer(current, total);

    return () => {
      clearTimeout(clear);
    };
  }, [current]);

  return (
    <Styled id="BannerVerticalRotation" css={css}>
      <div className="LTDTicker__container">
        <div className={`LTDTicker-wrap ${configBannerColor}`}>
          <div
            className="LTDTicker"
            onMouseEnter={() => clearTimeout(timeoutHandle)}
            onMouseLeave={() => timer(current, total)}
          >
            {messages[current].configBannerPromotionMessage && messages[current].configInfoMessage && (
              <TickerItem
                linkItem={messages[current].linkPromotion}
                promotionMessage={messages[current].configBannerPromotionMessage}
                expirationMessage={messages[current].configExpirationPromotionMessage}
                infoMessage={messages[current].configInfoMessage}
                color={configBannerColor}
                textColor={configTextColor}
              />
            )}
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default BannerVerticalRotation;
