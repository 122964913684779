import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';
import SearchIcon from '@oracle-cx-commerce/react-components/icons/search';
import {connect} from '@oracle-cx-commerce/react-components/provider';
import {normalizeText} from '@oracle-cx-commerce/ltd-store/src/plugins/utils/analytics';
import {HEADER_PREFIX} from '@oracle-cx-commerce/ltd-store/src/plugins/constants/analytics';
import SearchBoxCommon from './components/search-box-common';
import {getComponentData} from './selectors';
import css from './styles.css';

const SearchButton = props => {
  const {isDisabled} = props;

  return (
    <span className="SearchBoxDesktop__SearchIcon">
      <button
        type="submit"
        disabled={isDisabled}
        title="Search"
        id={`${HEADER_PREFIX}${normalizeText('typeahead-button')}`}
      >
        <SearchIcon />
      </button>
    </span>
  );
};

const LTDSearchBoxDesktop = props => {
  const {
    records,
    searchAdjustments,
    locale,
    numberOfSuggestions = 5,
    persistSearchTerm = true,
    closeLinkAltText,
    actionClear,
    textEnterSearch
  } = props;

  const [alreadySubmit, setAlreadySubmit] = useState(false);

  const searchToggle = useRef(null);
  const searchInput = useRef(null);

  const originalSearch = searchAdjustments && searchAdjustments.originalTerms && searchAdjustments.originalTerms[0]?.replace(/\*/g, '');
  const initialInputValue = persistSearchTerm ? originalSearch || '' : '';

  return (
    <Styled id="LTDSearchBoxDesktop" css={css}>
      <div className="LTDSearchBoxDesktop">
        <SearchBoxCommon
          records={records}
          searchToggle={searchToggle}
          searchInput={searchInput}
          locale={locale}
          numberOfSuggestions={numberOfSuggestions}
          initialInputValue={initialInputValue}
          closeLinkAltText={closeLinkAltText}
          actionClear={actionClear}
          textEnterSearch={textEnterSearch}
          isMobile={false}
          SearchIcon={SearchButton}
          alreadySubmit={alreadySubmit}
          setAlreadySubmit={setAlreadySubmit}
          {...props}
        />
      </div>
    </Styled>
  );
};

LTDSearchBoxDesktop.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchAdjustments: PropTypes.shape({originalTerms: PropTypes.arrayOf(PropTypes.string)}),
  locale: PropTypes.string.isRequired,
  numberOfSuggestions: PropTypes.number,
  persistSearchTerm: PropTypes.bool,
  closeLinkAltText: PropTypes.string.isRequired,
  actionClear: PropTypes.string.isRequired,
  textEnterSearch: PropTypes.string.isRequired
};

LTDSearchBoxDesktop.defaultProps = {
  searchAdjustments: undefined,
  numberOfSuggestions: 5,
  persistSearchTerm: true
};

export default connect(getComponentData)(LTDSearchBoxDesktop);
