export const normalizeText = data => {
  try {
    const decodedData = decodeURI(data);
    let formattedAccents = '';
    if (String.prototype.normalize) {
      formattedAccents = decodedData.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    } else {
      formattedAccents = decodedData
        .replace(/[áâãàÁÂÃÀ]/g, 'a')
        .replace(/[éêèÉÊÈ]/g, 'e')
        .replace(/[íîìÍÎÌ]/g, 'i')
        .replace(/[óôõòÓÔÕÒ]/g, 'o')
        .replace(/[úûùÚÛÙ]/g, 'u')
        .replace(/[ç]/, 'c');
    }

    let formattedSpaces = formattedAccents.replace(/\s/g, '-').toLowerCase();
    formattedSpaces = formattedSpaces.replace('’', '');
    formattedSpaces = formattedSpaces.replace("'", '');
    formattedSpaces = formattedSpaces.replace(/,-/g, ',');
    formattedSpaces = formattedSpaces.replace(/-,/g, ',');
    formattedSpaces = formattedSpaces.replace(/---/g, '-');
    formattedSpaces = formattedSpaces.replace(',', '-');
    formattedSpaces = formattedSpaces.replace(/\//g, '-');

    return formattedSpaces;
  } catch (error) {
    console.error('Error on sanitize data');
  }
};

export const sanitizeDataToDataLayer = data => {
  try {
    const jsonObject = JSON.stringify(data, function (key, value) {
      if (value === undefined || value === '') {
        return null;
      }

      return value;
    });

    let formattedAccents = '';
    if (String.prototype.normalize) {
      formattedAccents = jsonObject.normalize('NFC').replace(/[\u0300-\u036f]/g, '');
    } else {
      formattedAccents = jsonObject
        .replace(/[áâãà]/g, 'a')
        .replace(/ÁÂÃÀ/g, 'A')
        .replace(/[éêè]/g, 'e')
        .replace(/ÉÊÈ/g, 'E')
        .replace(/[íîì]/g, 'i')
        .replace(/ÍÎÌ/g, 'I')
        .replace(/[óôõò]/g, 'o')
        .replace(/ÓÔÕÒ/g, 'O')
        .replace(/[úûù]/g, 'u')
        .replace(/ÚÛÙ/g, 'U')
        .replace(/[ç]/, 'c')
        .replace(/[Ç]/, 'C');
    }

    let formattedSpaces = formattedAccents.replace(/\s{1,20}/g, " ");
    formattedSpaces = formattedSpaces.replace(/,-/g, ',');
    formattedSpaces = formattedSpaces.replace(/-,/g, ',');
    formattedSpaces = formattedSpaces.replace(/---/g, '-');

    const newObject = JSON.parse(formattedSpaces, function (key, value) {
      if (key !== 'event' && key !== 'eventAction' && key !== 'eventLabel') {
        if (value && typeof value.toLowerCase == 'function') {
          if (key === 'category') {
            return value.replace(/>/g, '/');
          }

          return value;
        }
      }

      return value;
    });

    return newObject;
  } catch (error) {
    console.error('Error on sanitize data');
  }
};
