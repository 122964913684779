import React, { useState, useEffect } from 'react';

const StickyContainer = ({ children, topOffset, onlyDesktop = false, className, ...props }) => {

  const [value, setValue] = useState();

  useEffect(() => {
    setValue(document.getElementsByTagName("header")[0].getBoundingClientRect().height);
  }, [value])

  const DEFAULT_TOP_OFFSET = 8

  const total = topOffset ? topOffset + value : value + DEFAULT_TOP_OFFSET 

  return (
    <div
      className={`ltd-ui-sticky-container ${onlyDesktop ? 'only-desktop' : ''} ${className ? className : ''}`}
      style={{top: `${total}px`}}
      {...props}
    >
      {children}
    </div>
  );
};


export { StickyContainer };