/* eslint-disable no-nested-ternary */
import React from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';

import {useNumberFormatter} from '@oracle-cx-commerce/react-components/utils/hooks';
import {useComponentData} from './selectors';
import css from './styles.css';
import {t} from '@oracle-cx-commerce/utils/generic';

const OPTIONS = {style: 'currency'};

const LTDProductPriceRecs = props => {
  const {alertPriceUnavailable, sku, product: {childSKUs = [], repositoryId = ''} = {}, listPriceOnSaleBeforeText, salePriceBeforeText} = props;

  const {
    skuId,
    isAllSkusDisabled,
    skuListPrice,
    skuSalePrice,
    originalPriceRange,
    salePriceRange,
    productListPrice,
    productSalePrice
  } = useComponentData(repositoryId, childSKUs, sku);

  const formatCurrency = useNumberFormatter(OPTIONS);

  /**
   * Formats price and displays alternate text if null price
   * @param  {Number} price Price
   * @param  {String} altPriceMsg Alternate text when price is unavailable
   * @return {String} formattedPrice Formatted price based on locale
   */
  const formatPrice = ({price, ltd_unitOfMeasure}) => {
    return (
      typeof price === 'number' && (
        <>
          {formatCurrency(price)}
          <span className="LTDProductPriceRecs__Price--PriceTag">{ltd_unitOfMeasure}</span>
        </>
      )
    );
  };

  /**
   * List price
   */
  const listPrice = () => {
    return skuId ? skuListPrice : productListPrice;
  };

  /**
   * Sale price
   */
  const salePrice = () => {
    return skuId ? skuSalePrice : productSalePrice;
  };

  if (isAllSkusDisabled) {
    return null;
  }

  const formatPriceWithAltMsg = price => {
    return formatPrice({
      price,
      altPriceMsg: <span className="ProductPriceRecs__Price--Unavailable">{alertPriceUnavailable}</span>
    });
  };

  return (
    <Styled id="ProductPriceRecs" css={css}>
      <div className={'ProductPriceRecs'}>
        {(!skuId || skuId?.includes('Dummy')) &&
          originalPriceRange &&
          originalPriceRange !== '$∞ - -$∞' &&
          !salePriceRange && (
            <div>
              <span className="ProductPriceRecs__Price--Sale">{originalPriceRange}</span>
            </div>
          )}

        {(!skuId || skuId?.includes('Dummy')) && !originalPriceRange && !salePriceRange && (
          <div>
            <span className="ProductPriceRecs__Price--Sale">{formatPriceWithAltMsg(listPrice())}</span>
          </div>
        )}

        {(!skuId || skuId?.includes('Dummy')) &&
          originalPriceRange &&
          originalPriceRange !== '$∞ - -$∞' &&
          salePriceRange &&
          salePriceRange !== '$∞ - -$∞' && (
            <div className="ProductPriceRecs__SaleWrapperPriceRange">
              <span className="ProductPriceRecs__Price--Was">
                {listPriceOnSaleBeforeText && <span className="ltd-price-prefix">{t(listPriceOnSaleBeforeText)}</span>}
                <span>{originalPriceRange}</span>
              </span>
              <span className="ProductPriceRecs__Price--Sale">
                {salePriceBeforeText && <span className="ltd-price-prefix">{t(salePriceBeforeText)}</span>}
                <span>{salePriceRange}</span>
              </span>
            </div>
          )}

        {skuId && !skuId?.includes('Dummy') && salePrice() && (
          <div className="ProductPriceRecs__SaleWrapper">
            <span className="ProductPriceRecs__Price--Was">
              {listPriceOnSaleBeforeText && <span className="ltd-price-prefix">{t(listPriceOnSaleBeforeText)}</span>}
              <span>{formatPriceWithAltMsg(listPrice())}</span>
            </span>
            <span className="ProductPriceRecs__Price--Sale">
              {salePriceBeforeText && <span className="ltd-price-prefix">{t(salePriceBeforeText)}</span>}
              <span>{formatPriceWithAltMsg(salePrice())}</span>
            </span>
          </div>
        )}

        {skuId && !skuId?.includes('Dummy') && !salePrice() && (
          <div>
            <span className="ProductPriceRecs__Price--Sale">{formatPriceWithAltMsg(listPrice())}</span>
          </div>
        )}
      </div>
    </Styled>
  );
};

export default LTDProductPriceRecs;
